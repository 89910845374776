import { Dispatch, SetStateAction } from 'react'
import { VisitLogForm, VisitLogFormData } from '@/components/forms'
import { useMutationWrapper, usePatchVisitLog } from '@/hooks'
import { VisitLog as VisitLogType } from '@/types'
import dayjs from 'dayjs'

export type VisitLogFormContainerProps = {
  data: VisitLogType
  setUpdatedVisitLog: Dispatch<SetStateAction<VisitLogType>>
  onSuccessUpdate: () => void
}

export const VisitLogFormContainer = ({
  data,
  setUpdatedVisitLog,
  onSuccessUpdate,
}: VisitLogFormContainerProps): JSX.Element => {
  const {
    mutateAsync: updateVisitLog,
    isLoading: mutateVisitLogInProcess,
    error,
  } = useMutationWrapper(
    usePatchVisitLog.bind(null, {
      id: data.id,
    })
  )

  const handleVisitLogSubmit = async ({
    start_datetime,
    end_datetime,
    start_temperature,
    end_temperature,
    cloud_cover,
    wind_force,
    precipitation,
    precipitation_duration,
    is_left_earlier,
    reason_of_leaving,
    comment,
  }: VisitLogFormData) => {
    if (data.research_visit_event) {
      const visitLogData = {
        start_datetime: start_datetime && dayjs(start_datetime).utc().format(),
        end_datetime: end_datetime && dayjs(end_datetime).utc().format(),
        start_temperature,
        end_temperature,
        cloud_cover,
        wind_force,
        precipitation,
        precipitation_duration,
        is_left_earlier,
        reason_of_leaving: is_left_earlier ? reason_of_leaving : null,
        comment: !!comment ? comment : null,
        research_visit_event_id: data.research_visit_event.id,
      }

      await updateVisitLog(visitLogData, {
        successMessageKey:
          'dataValidation:notifications.processingStatusChanged',
        onSuccess: (data) => {
          setUpdatedVisitLog(data)
          onSuccessUpdate()
        },
      })
    }
  }

  const defaultValues = {
    start_datetime: data.start_datetime ?? '',
    end_datetime: data.end_datetime ?? '',
    start_temperature: data.start_temperature ?? 0,
    end_temperature: data.end_temperature ?? 0,
    wind_force: data.wind_force ?? 0,
    cloud_cover: data.cloud_cover ?? '',
    precipitation: data.precipitation ?? '',
    precipitation_duration: data.precipitation_duration ?? '',
    is_left_earlier: data.is_left_earlier ?? false,
    reason_of_leaving: data.reason_of_leaving ?? '',
    comment: data.comment ?? '',
  }

  return (
    <VisitLogForm
      defaultValues={defaultValues}
      onSubmit={handleVisitLogSubmit}
      isLoading={mutateVisitLogInProcess}
      apiErrorData={error?.data}
    />
  )
}

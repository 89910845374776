import styled from '@emotion/styled'
import LoginIcon from '@mui/icons-material/Login'
import { useNavigate } from 'react-router-dom'

import { Tooltip, IconButton as MuiIconButton } from '@mui/material'

import { useAuth } from '../../../hooks/useAuth'

const IconButton = styled(MuiIconButton)`
  background: ${({ theme }) => theme.palette.secondary.light};
  border-radius: 5px;
  padding: 10px;

  svg {
    color: ${({ theme }) => theme.palette.green[600]};
    width: 22px;
    height: 16px;
  }
`

function NavbarUserDropdown() {
  const navigate = useNavigate()
  const { signOut } = useAuth()

  const handleSignOut = async () => {
    await signOut()
    navigate('/auth/sign-in')
  }

  return (
    <Tooltip title="Log out">
      <IconButton onClick={handleSignOut} color="inherit" size="large">
        <LoginIcon />
      </IconButton>
    </Tooltip>
  )
}

export default NavbarUserDropdown

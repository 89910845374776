import { useModal } from '@/hooks'
import { Button } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import {
  ResearchAreasImportMap,
  ResearchAreasImportMapProps,
} from '@/components/forms/ResearchAreaForm/ResearchAreasImportMap'
import { useTranslation } from 'react-i18next'

export const ImportResearchAreas = (
  researchAreaGraphicsMapProps: ResearchAreasImportMapProps
) => {
  const { open } = useModal()
  const { t } = useTranslation()

  return (
    <Button
      onClick={() =>
        open(<ResearchAreasImportMap {...researchAreaGraphicsMapProps} />)
      }
      startIcon={<CloudUploadIcon />}
      sx={{ height: '100%' }}
      variant="text"
    >
      {t('projects:areas.importFromShapeFileBtn')}
    </Button>
  )
}

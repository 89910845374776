import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { AutocompleteOption, ControlledAutocomplete } from '@/components/inputs'
import { ControlledFieldProps, ProjectProtocol } from '@/types'

export const getProtocolOptionLabel = ({
  id,
  original_protocols,
}: ProjectProtocol): AutocompleteOption => {
  const species = [
    ...new Set(original_protocols.map((op) => op.specie.name)),
  ].join(', ')

  const types = original_protocols.map((op) => op.research_type.name).join(', ')

  return {
    value: id.toString(),
    title: `Species: [ ${species} ] - Types: [ ${types} ]`,
  }
}

export type ProtocolsAutocompleteProps<T extends FieldValues> = {
  onChange?: (item: AutocompleteOption[]) => void
  protocols?: ProjectProtocol[]
  disabled?: boolean
} & Pick<ControlledFieldProps<T>, 'name' | 'control'>

export const ProtocolsAutocomplete = <T extends FieldValues>({
  name,
  control,
  onChange,
  protocols,
  disabled,
}: ProtocolsAutocompleteProps<T>): JSX.Element => {
  const { t } = useTranslation('forms')

  const [options, setOptions] = useState<AutocompleteOption[]>([])

  useEffect(() => {
    if (protocols) {
      const mapped = protocols.map((protocol) =>
        getProtocolOptionLabel(protocol)
      )

      setOptions(mapped)
    }
  }, [protocols])

  return (
    <ControlledAutocomplete
      name={name}
      control={control}
      options={options}
      multiple={true}
      textFieldProps={{
        placeholder: t('placeholders.pickProtocols'),
        label: t('labels.protocols'),
        fullWidth: true,
      }}
      loading={!protocols}
      onChangeCustom={(value) => {
        onChange && onChange(value as AutocompleteOption[])
      }}
      disabled={disabled}
    />
  )
}

import { QUERY_KEYS } from '@/constants'
import { useApiClient } from '@/hooks'
import { APIErrorResponse, ProjectSpecieBasic } from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export const usePatchProjectSpecieType = (
  projectId: number
): UseMutationResult<
  ProjectSpecieBasic,
  APIErrorResponse,
  ProjectSpecieBasic
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch<ProjectSpecieBasic>(
          `/projects/${projectId}/species/${params.id}/`,
          params
        )
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        const queryKey = QUERY_KEYS.RESEARCH_SPECIES.map((key) =>
          key === ':id' ? projectId.toString() : key
        )

        queryClient.removeQueries(queryKey)
      },
    }
  )
}

import { useState, useEffect } from 'react'
import { getPlacePredictions } from '@/libs/googleMaps'

export type Suggestion = google.maps.places.AutocompletePrediction

export const usePlacesPredictions = (
  text = '',
  debounceTimeout = 400
): { predictions: Suggestion[] | null; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false)
  const [predictions, setPredictions] = useState<Suggestion[] | null>([])

  useEffect(() => {
    const handleDebounce = setTimeout(async () => {
      if (!text) return
      setIsLoading(true)
      const nextPredictions = await getPlacePredictions(text)

      setPredictions(nextPredictions.predictions)
      setIsLoading(false)
    }, debounceTimeout)

    return () => {
      clearTimeout(handleDebounce)
    }
  }, [text, debounceTimeout])

  return { predictions, isLoading }
}

import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { IconButton, Stack, Typography } from '@mui/material'
import { X } from 'react-feather'
import {
  getUseProjectProtocolsKey,
  useDeleteProjectSpecieType,
  useMutationWrapper,
  usePatchProjectSpecieType,
  usePostProjectSpecieType,
  useProtocolsSpecies,
} from '@/hooks'
import { Animal, ProjectSpecie, ResearchType, Specie } from '@/types'
import { useStepperContext } from '@/features/projectExtend/StepperContext'
import {
  AddSpeciesComponent,
  FooterActions,
  ResearchTypesSelectorFormData,
  researchTypesSelectorSchema,
  SpeciesSelectorFormData,
  StepComponentProps,
  useAddNewProjectSpecie,
} from '@/features'
import { ResearchTypesAutocomplete } from '@/components/inputs'
import { theme } from '@/theme'
import { useQueryClient } from '@tanstack/react-query'

export type ProjectSpecieHierarchy = {
  [key: number]: AnimalWithSpecies
}

export type AnimalWithSpecies = Animal & {
  species: {
    [key: number]: SpecieWithResearchTypes
  }
}

export type SpecieWithResearchTypes = Specie & {
  researchTypes: Omit<ProjectSpecie, 'specie'>[]
}

const organiseProjectSpecies = (
  projectSpecies: ProjectSpecie[],
  hiddenSpecies: Set<number>
): ProjectSpecieHierarchy =>
  projectSpecies?.reduce((hierarchy, projectSpecie) => {
    const animal = projectSpecie.specie.animal
    const specie = projectSpecie.specie

    if (projectSpecie.specie.id && hiddenSpecies.has(projectSpecie.specie.id)) {
      return hierarchy
    }

    // Create a new object for the animal if it doesn't exist
    hierarchy[animal.id] = hierarchy[animal.id] || { ...animal, species: {} }

    // Create a new specie object if it doesn't exist
    hierarchy[animal.id]['species'][specie.id] = hierarchy[animal.id][
      'species'
    ][specie.id] || {
      ...projectSpecie.specie,
      researchTypes: [],
    }

    // Add the type to the array
    if (projectSpecie.research_type) {
      hierarchy[animal.id]['species'][specie.id]['researchTypes'].push({
        research_type_id: projectSpecie.research_type.id,
        research_type: projectSpecie.research_type,
        specie_id: specie.id,
        id: projectSpecie.id,
      })
    }

    return hierarchy
  }, {} as ProjectSpecieHierarchy)

type ProjectSpeciesFormContainerProps = StepComponentProps & {
  projectSpeciesData: ProjectSpecie[]
  controls?: JSX.Element
  isPlanningCreated?: boolean
}

export const ProjectSpeciesFormContainer = ({
  projectId,
  projectSpeciesData,
  isPlanningCreated = false,
}: ProjectSpeciesFormContainerProps): JSX.Element => {
  const queryClient = useQueryClient()

  const [isNextStepDisabled, setIsNextStepDisabled] = useState(true)
  const handleAddNewResearchArea = useAddNewProjectSpecie(
    projectId,
    setIsNextStepDisabled
  )
  const { stepBack, stepForward } = useStepperContext()
  const [hiddenSpecies, setHiddenSpecies] = useState<Set<number>>(
    new Set<number>()
  )

  const { data: species, isLoading: areSpeciesLoading } = useProtocolsSpecies()

  const researchTypeSpeciesMap = useMemo(
    () =>
      species?.results.reduce((map: Record<number, ResearchType[]>, obj) => {
        map[obj.id] = obj.research_types || []

        return map
      }, {}),
    [species]
  )

  const hierarchicalProjectSpecies = useMemo(
    () => organiseProjectSpecies(projectSpeciesData, hiddenSpecies),
    [projectSpeciesData, hiddenSpecies]
  )

  const defaultFormValues = useMemo(
    () =>
      projectSpeciesData?.reduce(
        (
          result: ResearchTypesSelectorFormData,
          { id, research_type, specie }
        ) => {
          if (hiddenSpecies.has(specie.id)) return result

          if (!Array.isArray(result.researchTypes[specie.id]))
            result.researchTypes[specie.id] = []

          if (research_type) {
            result.researchTypes[specie.id].push({
              value: research_type.id.toString() ?? '',
              title: research_type.name ?? '',
              id,
              specie_id: specie.id,
            })
          }

          return result
        },
        {
          researchTypes: [],
        }
      ),
    [projectSpeciesData, hiddenSpecies]
  )

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    unregister,
    formState: { isDirty, isValid, errors },
  } = useForm<ResearchTypesSelectorFormData>({
    mode: 'onChange',
    resolver: yupResolver(researchTypesSelectorSchema),
    defaultValues: defaultFormValues,
  })

  useEffect(() => {
    if (defaultFormValues) {
      reset(defaultFormValues)
    }
  }, [defaultFormValues])

  const { mutateAsync: createProjectSpecie } = useMutationWrapper(() =>
    usePostProjectSpecieType(projectId)
  )
  const { mutateAsync: updateProjectSpecie } = useMutationWrapper(() =>
    usePatchProjectSpecieType(projectId)
  )
  const { mutateAsync: deleteProjectSpecie } = useMutationWrapper(() =>
    useDeleteProjectSpecieType(projectId)
  )

  const saveData = async (
    data: ResearchTypesSelectorFormData
  ): Promise<void> => {
    const speciesToDelete = projectSpeciesData?.filter(
      (projectSpecie) =>
        projectSpecie.id &&
        (!data.researchTypes[+projectSpecie.specie.id] ||
          data.researchTypes[+projectSpecie.specie.id].every(
            (obj) => obj.id !== projectSpecie.id
          ))
    )

    const queries: Promise<unknown>[] = []

    speciesToDelete.map(
      (projectSpecie) =>
        projectSpecie.id &&
        queries.push(deleteProjectSpecie({ id: projectSpecie.id }))
    )

    data.researchTypes.map((specie) =>
      specie.map((researchType) => {
        const dataToSave = {
          specie_id: (researchType?.specie_id ?? '').toString(),
          research_type_id: +researchType.value,
          ...(researchType.id ? { id: +researchType.id } : { id: null }),
        }

        if (dataToSave.id === null) {
          queries.push(createProjectSpecie(dataToSave))
        } else {
          queries.push(updateProjectSpecie(dataToSave))
        }
      })
    )

    await Promise.all(queries)

    await queryClient.invalidateQueries(getUseProjectProtocolsKey(projectId))
    stepForward()
  }

  const onSubmitNewSpecies: SubmitHandler<SpeciesSelectorFormData> = (data) => {
    const newProjectSpecies = data.newSpecies.map((item) => {
      setHiddenSpecies((prev) => {
        const newSet = new Set<number>(prev)

        newSet.delete(+item.value)

        return newSet
      })

      return {
        specie_id: item.value.toString(),
        specie: item.specie as Specie,
        research_type: null,
        id: null,
      }
    })

    handleAddNewResearchArea(newProjectSpecies)

    setIsNextStepDisabled(false)
  }

  useEffect(() => {
    setIsNextStepDisabled(false)
  }, [isDirty, hiddenSpecies])

  const researchTypesInForm = watch(`researchTypes`)

  return (
    <Stack sx={{ paddingBottom: 0 }}>
      <form onSubmit={handleSubmit(async (data) => saveData(data))}>
        {!isPlanningCreated && (
          <AddSpeciesComponent
            isLoading={areSpeciesLoading}
            species={species?.results}
            onSubmitNewSpecies={onSubmitNewSpecies}
          />
        )}
        {!isValid &&
          (!Array.isArray(researchTypesInForm) ||
            !researchTypesInForm.length) && (
            <Typography variant="body2" color="error.main" ml={1}>
              {errors.researchTypes?.message}
            </Typography>
          )}
        {Object.entries(hierarchicalProjectSpecies).map(([, animal]) => (
          <div key={animal.id}>
            <Typography variant="h5" mb={2}>
              {animal.name}
            </Typography>
            {Object.entries(animal.species).map(([, specie]) => (
              <Stack
                direction="row"
                alignItems="center"
                mb={4}
                key={`animal_${specie.id}`}
                data-testid="research-type-events-form"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  flex={1}
                  borderRadius={2.5}
                  bgcolor="grey.50"
                  p={3}
                >
                  <Typography width={260} mt={2.5}>
                    {specie.name}
                  </Typography>
                  <ResearchTypesAutocomplete
                    name={`researchTypes.${specie.id}`}
                    control={control}
                    researchTypes={researchTypeSpeciesMap?.[specie.id] || []}
                    selectedValues={specie.researchTypes}
                    specie={specie}
                    setValue={setValue}
                  />
                </Stack>
                <IconButton
                  onClick={() => {
                    setHiddenSpecies(
                      (prev) => new Set<number>([...prev, +specie.id])
                    )
                    unregister(`researchTypes.${specie.id}`)
                  }}
                  data-testid="add-research-specie-button"
                >
                  <X size="16px" color={theme.palette.green['500']} />
                </IconButton>
              </Stack>
            ))}
          </div>
        ))}
        <FooterActions
          mutateInProcess={false}
          isNextStepDisabled={isNextStepDisabled}
          onCancel={() => {
            reset()
            stepBack()
          }}
        />
      </form>
    </Stack>
  )
}

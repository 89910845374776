import { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  MonitoringTableProps,
  MonitoringTableRowDetails,
} from '@/features/monitoring/MonitoringTable'
import { theme } from '@/theme'
import { UncertaintyReason } from '@/types'

export const MonitoringTableBody = ({
  rows,
  filterParams,
}: MonitoringTableProps): JSX.Element => {
  const { t } = useTranslation('monitoring')

  const [openedAreas, setOpenedAreasList] = useState<number[]>([])

  useEffect(() => {
    setOpenedAreasList([])
  }, [rows])

  const toggleAreaDetails = (id: number) => {
    setOpenedAreasList((list) =>
      openedAreas.includes(id)
        ? list.filter((areaId) => areaId !== id)
        : [...list, id]
    )
  }

  return (
    <TableBody>
      {rows?.map(
        ({
          id,
          client_area_number,
          project,
          location_name,
          researchers,
          has_events_with_reasons_of_uncertainty,
        }) => (
          <Fragment key={id}>
            <TableRow
              sx={{
                backgroundColor: openedAreas.includes(id)
                  ? theme.palette.green[100]
                  : theme.palette.background.paper,
                '& > *': {
                  fontWeight: openedAreas.includes(id)
                    ? theme.typography.fontWeightBold
                    : theme.typography.fontWeightRegular,
                },
              }}
            >
              <TableCell>{id}</TableCell>
              <TableCell>{client_area_number}</TableCell>
              <TableCell>{project?.client_name}</TableCell>
              <TableCell>{project?.name}</TableCell>
              <TableCell>{project?.project_number}</TableCell>
              <TableCell>{location_name}</TableCell>
              <TableCell>
                {researchers?.map(
                  ({ first_name, last_name }, i) =>
                    `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                ) ?? '-'}
              </TableCell>
              <TableCell>
                {has_events_with_reasons_of_uncertainty ? (
                  <Typography variant="body2" color="error.main">
                    {t('titles.uncertainRounds')}
                  </Typography>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell sx={{ pl: 0 }}>
                <IconButton size="small" onClick={() => toggleAreaDetails(id)}>
                  {openedAreas.includes(id) ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={9}
                sx={{ p: 0, backgroundColor: theme.palette.background.paper }}
              >
                <Collapse
                  in={openedAreas.includes(id)}
                  timeout="auto"
                  unmountOnExit={true}
                >
                  {openedAreas.includes(id) && (
                    <MonitoringTableRowDetails
                      areaId={id}
                      researchers={filterParams.researchAreaResearchers}
                      uncertaintyReason={
                        filterParams.uncertaintyReason as UncertaintyReason
                      }
                      startDate={filterParams.startDate as UncertaintyReason}
                      endDate={filterParams.endDate as UncertaintyReason}
                    />
                  )}
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        )
      )}
    </TableBody>
  )
}

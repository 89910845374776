import { APIErrorResponse, Plannings } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { QUERY_KEYS } from '@/constants'

type UsePlanningsParams = {
  select?: (plannings: Plannings) => Plannings
  startDate?: string
  endDate?: string
  search?: string
  researcher?: number
  enabled?: boolean
}

export const usePlannings = ({
  select,
  enabled,
  startDate,
  endDate,
  search,
  researcher,
}: UsePlanningsParams): UseQueryResult<Plannings, APIErrorResponse> => {
  const params = {
    ...(startDate && { date_start: startDate }),
    ...(endDate && { date_end: endDate }),
    ...(search && { search }),
    ...(researcher && { researcher }),
  }

  let key = QUERY_KEYS.PLANNING_EVENTS

  if (search || researcher) {
    key = QUERY_KEYS.PLANNING_EVENTS_FILTERED
  }

  return useQueryList({
    key: [...key, params],
    url: '/plannings/',
    params,
    options: {
      enabled,
      select,
      staleTime: Infinity,
    },
  })
}

import dayjs from 'dayjs'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import translation from 'i18next'

export const getWeeksInYear = (year: number) => {
  dayjs.extend(isoWeeksInYear)
  dayjs.extend(isLeapYear)

  return dayjs(`${year}-01-01`).isoWeeksInYear()
}

export const getDateOfISOWeek = (w: number, y: number) => {
  const simple = new Date(y, 0, 1 + (w - 1) * 7)
  const dow = simple.getDay()
  const ISOweekStart = simple

  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())

  return ISOweekStart
}

export const getDateOfDayInWeek = (
  weekNumber: number,
  dayNumber: number
): Date => {
  const weekStartDate = getDateOfISOWeek(weekNumber, dayjs().get('year'))

  weekStartDate.setDate(weekStartDate.getDate() + dayNumber)

  return weekStartDate
}

export const formatMinutesDuration = (duration: number) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  const hoursFormatted =
    hours > 0 ? translation.t('forms:descriptions.hours', { count: hours }) : ''

  const minutesFormatted =
    minutes > 0
      ? translation.t('forms:descriptions.minutes', { count: minutes })
      : ''

  return `${hoursFormatted} ${minutesFormatted}`
}

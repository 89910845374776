import { UseQueryResult } from '@tanstack/react-query'
import { APIErrorResponse, ResearcherAvailability } from '@/types'
import { useQueryList } from '@/hooks/api/useQueryList'

type UseResearcherAvailabilityParams = {
  researcherId: number
}

export const useResearcherAvailability = ({
  researcherId,
}: UseResearcherAvailabilityParams): UseQueryResult<
  ResearcherAvailability[],
  APIErrorResponse
> =>
  useQueryList({
    url: `plannings/researchers/${researcherId}/availability/`,
  })

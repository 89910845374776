import { ReactElement, useState, Context, createContext } from 'react'
import { ReactChildren } from '@/types'

const DEFAULT_MODAL_OPTIONS = {
  isBackdropDisabled: false,
  isEscapeButtonDisabled: false,
}

export type ModalOptions = {
  isBackdropDisabled?: boolean
  isEscapeButtonDisabled?: boolean
}

type CloseReason = 'backdropClick' | 'escapeKeyDown'

export type ModalContextType = {
  isOpen: boolean
  modalContent: ReactElement | null
  open: (content: ReactElement, options?: ModalOptions) => void
  close: (reason?: CloseReason) => void
  options: ModalOptions
}

export const ModalContext: Context<ModalContextType> =
  createContext<ModalContextType>({
    isOpen: false,
    modalContent: null,
    open: () => null,
    close: () => null,
    options: DEFAULT_MODAL_OPTIONS,
  })

export const ModalProvider = ({ children }: ReactChildren): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ReactElement | null>(null)
  const [options, setOptions] = useState<ModalOptions>(DEFAULT_MODAL_OPTIONS)

  const open = (content: ReactElement, options?: ModalOptions) => {
    if (content) {
      setIsOpen(true)
      setModalContent(content)
      options && setOptions(options)
    }
  }

  const close = (reason?: CloseReason) => {
    if (reason === 'backdropClick' && options.isBackdropDisabled) return
    if (reason === 'escapeKeyDown' && options.isEscapeButtonDisabled) return
    setIsOpen(false)
    setModalContent(null)
    setOptions(DEFAULT_MODAL_OPTIONS)
  }

  return (
    <ModalContext.Provider
      value={{ isOpen, close, open, modalContent, options }}
    >
      {children}
    </ModalContext.Provider>
  )
}

import { useState, useMemo, ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchRequestParams } from '@/types'
import { DEFAULT_LIST_LIMIT } from '@/constants'

export const useFilters = <T extends SearchRequestParams>(params: T) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultValues: T = useMemo(
    () =>
      Object.keys(params as T).reduce((acc, key) => {
        const typedKey = key as keyof T

        acc[typedKey] = undefined as T[keyof T]

        const defaultParam = params[key] ?? undefined
        const urlParam = searchParams.get(key) ?? undefined

        if (defaultParam && !urlParam) {
          acc[typedKey] = defaultParam as T[keyof T]
          if (key !== 'limit' && key !== 'offset') {
            searchParams.set(key, `${defaultParam}`)
            setSearchParams(searchParams)
          }

          return acc
        }

        if (!!urlParam) {
          if (urlParam === 'true') {
            acc[typedKey] = true as T[keyof T]
          } else if (urlParam === 'false') {
            acc[typedKey] = false as T[keyof T]
            // } else if (!Number.isNaN(Number(urlParam))) {
            //   acc[typedKey] = Number(urlParam) as T[keyof T]
          } else {
            acc[typedKey] = urlParam as T[keyof T]
          }
        }

        return acc
      }, {} as T),
    [params, searchParams]
  )

  if (!!searchParams.get('page')) {
    defaultValues.offset =
      (Number(searchParams.get('page')) - 1) *
      (params.limit ?? DEFAULT_LIST_LIMIT)
  }

  const [filterParams, setFilterParams] = useState<T>(defaultValues)
  const [page, setPage] = useState(Number(searchParams.get('page')) ?? 1)
  const [pagesCount, setPagesCount] = useState(1)

  const handleFieldValueChange = (value: T[keyof T], field: keyof T) => {
    if (value !== undefined && value !== '') {
      searchParams.set(field as string, `${value}`)
    } else {
      searchParams.delete(field as string)
    }
    searchParams.delete('page')
    setSearchParams(searchParams)

    setPage(1)

    setFilterParams((filterParams) => ({
      ...filterParams,
      [field]: value ?? undefined,
      offset: 0,
    }))
  }

  const initPagesCount = (itemsCount: number) => {
    const count = Math.ceil(Number(itemsCount) / Number(filterParams?.limit))

    setPagesCount(count)
  }

  const onPageChange = (_: ChangeEvent<unknown>, page: number) => {
    setPage(page)

    if (page > 1) {
      searchParams.set('page', `${page}`)
    } else if (searchParams.get('page')) {
      searchParams.delete('page')
    }

    setSearchParams(searchParams)

    setFilterParams((filterParams) => ({
      ...filterParams,
      offset: (page - 1) * (filterParams.limit ?? DEFAULT_LIST_LIMIT),
    }))
  }

  const handleResetFilters = () => {
    setPage(1)
    setSearchParams({})
    setFilterParams({ ...params })
  }

  const openDetail = () => {
    const id = searchParams.get('id')
    const detail = searchParams.get('detail')

    if (id && detail) {
      searchParams.delete('detail')
      setSearchParams(searchParams)

      return Number(id)
    }

    return false
  }

  return {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
    openDetail,
  }
}

import { Box, TextField, InputLabel, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { ControlledFieldProps } from '@/types'
import { getHelpText, hasFieldError } from '@/utils'
import { useTranslation } from 'react-i18next'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { DATE_FORMAT_WITH_TIME } from '@/constants'

export type ControlledDateTimeFieldProps<T extends FieldValues> = {
  textFieldProps?: TextFieldProps
} & ControlledFieldProps<T> &
  Omit<DateTimePickerProps<Dayjs, Dayjs>, 'renderInput' | 'value' | 'onChange'>

export const ControlledDateTimeField = <T extends FieldValues>({
  name,
  control,
  inputLabel,
  inputLabelSx,
  errorMessagePrefix = 'errors:validation',
  apiErrorData,
  apiFieldName,
  isOptional = false,
  isFieldStateErrorShown = true,
  textFieldProps = {},
  ...dateTimePickerProps
}: ControlledDateTimeFieldProps<T>): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{ width: textFieldProps?.fullWidth ? '100%' : 'auto' }}
      data-testid="date-time-field-container"
    >
      {inputLabel && (
        <InputLabel sx={{ mb: 2, ...inputLabelSx }}>
          {inputLabel} {isOptional && t('forms:labels.optional')}
        </InputLabel>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DateTimePicker
            value={value}
            onChange={onChange}
            ampm={false}
            renderInput={(params) => (
              <TextField
                {...params}
                error={
                  !!error || hasFieldError(apiFieldName ?? name, apiErrorData)
                }
                helperText={getHelpText({
                  isFieldStateErrorShown,
                  error,
                  errorMessagePrefix,
                  apiFieldName,
                  name,
                  apiErrorData,
                })}
                {...textFieldProps}
              />
            )}
            inputFormat={DATE_FORMAT_WITH_TIME}
            OpenPickerButtonProps={{
              sx: { color: 'green.500' },
            }}
            PaperProps={{
              sx: {
                boxShadow: 2,
              },
            }}
            {...dateTimePickerProps}
          />
        )}
      />
    </Box>
  )
}

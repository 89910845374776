import { Box, Button, Stack, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

export type EmptyDataProps = {
  titleLocaleKey: string
  buttonLocaleKey?: string
  icon?: IconDefinition
  onButtonClick?: () => void
}

export const EmptyData = ({
  titleLocaleKey,
  buttonLocaleKey,
  icon,
  onButtonClick,
}: EmptyDataProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Stack alignItems="center">
      <Typography pb={8}>{t(titleLocaleKey)}</Typography>

      {buttonLocaleKey && (
        <Button
          fullWidth={false}
          onClick={onButtonClick}
          endIcon={
            icon && (
              <Box sx={{ fontSize: '0.5rem' }}>
                <FontAwesomeIcon icon={icon} size="xs" />
              </Box>
            )
          }
        >
          {t(buttonLocaleKey)}
        </Button>
      )}
    </Stack>
  )
}

import { useEffect, useRef, useState } from 'react'
import { loadModules } from 'esri-loader'
import {
  ResearchArea,
  ResearchAreaGraphic,
  ResearchAreaGraphicType,
} from '@/types'
import { useModal } from '@/hooks'
import { ModalLayout } from '@/components'
import { Box, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AreaAddress } from '@/components/forms'
import {
  AMSTERDAM_COORDINATES,
  createCyclingRoutesFeatureLayerConfig,
  createFeatureLayerConfig,
  createGraphicJson,
  createStandPlaceFeatureLayerConfig,
  createWalkingRoutesFeatureLayerConfig,
  DEFAULT_ZOOM,
} from './ResearchAreaGraphicMap.model'
import { useProject } from '@/hooks/api/useProject'
import {
  getCyclingRoutesGraphics,
  getGraphicsByType,
  getPolygonGraphics,
  getStandPlaceGraphics,
  getWalkingRoutesGraphics,
} from '@/utils/researchAreaGraphics'

export type ResearchAreaGraphicsMapProps = {
  projectId: number
  areaId?: number
  areaAddress?: AreaAddress | null
  graphics: ResearchAreaGraphic[]
  otherResearchAreas: ResearchArea[]
  onSave: (graphics: ResearchAreaGraphic[]) => void
}
export const ResearchAreaGraphicsMap = ({
  projectId,
  areaId,
  areaAddress,
  graphics,
  otherResearchAreas,
  onSave,
}: ResearchAreaGraphicsMapProps) => {
  const MapElement = useRef(null)
  const [featureLayer, setFeatureLayer] = useState<__esri.FeatureLayer>()
  const [cyclingRoutesFeatureLayer, setCyclingRoutesFeatureLayer] =
    useState<__esri.FeatureLayer>()
  const [walkingRoutesFeatureLayer, setWalkingRoutesFeatureLayer] =
    useState<__esri.FeatureLayer>()
  const [standPlaceFeatureLayer, setStandPlaceFeatureLayer] =
    useState<__esri.FeatureLayer>()

  const { close } = useModal()
  const { t } = useTranslation()

  const { data: project } = useProject({ id: projectId })

  const getFormTitle = (areaId?: number) =>
    areaId
      ? t('projects:areas.researchAreaNumber', {
          areaNumber: areaId,
        })
      : t('projects:areas.emptyResearchAreaName')

  const getGraphicsJsonByTypeFromResearchAreas = (
    researchAreas: ResearchArea[],
    graphic_type: ResearchAreaGraphicType
  ) =>
    researchAreas
      ?.map((ra) =>
        getGraphicsByType(ra.graphics, graphic_type).map((gr) =>
          createGraphicJson(gr, getFormTitle(ra.id))
        )
      )
      .flat(1) ?? []

  useEffect(() => {
    loadModules(
      [
        'esri/views/MapView',
        'esri/Map',
        'esri/layers/FeatureLayer',
        'esri/widgets/Editor',
        'esri/Graphic',
        'esri/widgets/Search',
        'esri/layers/VectorTileLayer',
        'esri/Basemap',
      ],
      { css: true }
    )
      .then(
        ([
          MapView,
          Map,
          FeatureLayer,
          Editor,
          Graphic,
          Search,
          VectorTileLayer,
          Basemap,
        ]) => {
          const createReadOnlyFeatureLayer = (
            researchAreas: ResearchArea[],
            graphic_type: ResearchAreaGraphicType,
            color = '#808080'
          ) =>
            new FeatureLayer(
              createFeatureLayerConfig(
                getGraphicsJsonByTypeFromResearchAreas(
                  researchAreas,
                  graphic_type
                ).map((j) => Graphic.fromJSON(j)),
                color
              )
            )

          const source =
            getPolygonGraphics(graphics ?? []).map((gr) =>
              Graphic.fromJSON(createGraphicJson(gr, getFormTitle(areaId)))
            ) ?? []

          const cyclingRoutesSource =
            getCyclingRoutesGraphics(graphics ?? []).map((gr) =>
              Graphic.fromJSON(createGraphicJson(gr, getFormTitle(areaId)))
            ) ?? []

          const walkingRoutesSource =
            getWalkingRoutesGraphics(graphics ?? []).map((gr) =>
              Graphic.fromJSON(createGraphicJson(gr, getFormTitle(areaId)))
            ) ?? []

          const standPlacesSource =
            getStandPlaceGraphics(graphics ?? []).map((gr) =>
              Graphic.fromJSON(createGraphicJson(gr, getFormTitle(areaId)))
            ) ?? []

          const readOnlyFeatureLayer = createReadOnlyFeatureLayer(
            otherResearchAreas,
            ResearchAreaGraphicType.AREA
          )

          const readOnlyCyclingRoutesFeatureLayer = createReadOnlyFeatureLayer(
            otherResearchAreas,
            ResearchAreaGraphicType.CYCLING_ROUTE
          )

          const readOnlyWalkingRoutesFeatureLayer = createReadOnlyFeatureLayer(
            otherResearchAreas,
            ResearchAreaGraphicType.WALKING_ROUTE
          )

          const readOnlyStandPlaceFeatureLayer = createReadOnlyFeatureLayer(
            otherResearchAreas,
            ResearchAreaGraphicType.STAND_PLACE
          )

          const _featureLayer = new FeatureLayer(
            createFeatureLayerConfig(source, [255, 3, 7, 255])
          )

          setFeatureLayer(_featureLayer)

          const _cyclingRoutesFeatureLayer = new FeatureLayer(
            createCyclingRoutesFeatureLayerConfig(
              cyclingRoutesSource,
              '#730000'
            )
          )

          setCyclingRoutesFeatureLayer(_cyclingRoutesFeatureLayer)

          const _walkingRoutesFeatureLayer = new FeatureLayer(
            createWalkingRoutesFeatureLayerConfig(
              walkingRoutesSource,
              '#a87000'
            )
          )

          setWalkingRoutesFeatureLayer(_walkingRoutesFeatureLayer)

          const _standPlaceFeatureLayer = new FeatureLayer(
            createStandPlaceFeatureLayerConfig(standPlacesSource, '#a87000')
          )

          setStandPlaceFeatureLayer(_standPlaceFeatureLayer)

          const mapBaseLayer = new VectorTileLayer({
            url: 'https://tiles.arcgis.com/tiles/nSZVuSZjHpEZZbRo/arcgis/rest/services/Topo_RD/VectorTileServer/resources/styles/root.json',
          })

          // Create a Basemap with the VectorTileLayer
          const customBasemap = new Basemap({
            baseLayers: [mapBaseLayer],
            title: 'Terrain',
          })

          const map = new Map({
            basemap: customBasemap,
            layers: [
              _featureLayer,
              _cyclingRoutesFeatureLayer,
              _walkingRoutesFeatureLayer,
              _standPlaceFeatureLayer,
              readOnlyFeatureLayer,
              readOnlyCyclingRoutesFeatureLayer,
              readOnlyWalkingRoutesFeatureLayer,
              readOnlyStandPlaceFeatureLayer,
            ],
          })

          const view = new MapView({
            zoom: DEFAULT_ZOOM,
            map,
            center:
              areaAddress && areaAddress.locationLat && areaAddress.locationLong
                ? [areaAddress.locationLong, areaAddress.locationLat]
                : AMSTERDAM_COORDINATES,
            container: MapElement.current,
          })

          view.when(() => {
            const editor = new Editor({
              view,
              layerInfos: [
                {
                  layer: _featureLayer,
                  enabled: true,
                  addEnabled: true,
                  updateEnabled: true,
                  deleteEnabled: true,
                  attributeUpdatesEnabled: true,
                  geometryUpdatesEnabled: true,
                  formTemplate: {
                    name: 'New area',
                    description: '',
                    elements: [
                      {
                        type: 'field',
                        fieldName: 'round_type',
                      },
                    ],
                  },
                },
                {
                  layer: _cyclingRoutesFeatureLayer,
                  enabled: true,
                  addEnabled: true,
                  updateEnabled: true,
                  deleteEnabled: true,
                  attributeUpdatesEnabled: true,
                  geometryUpdatesEnabled: true,
                  formTemplate: {
                    name: 'New cycling route',
                    description: '',
                    elements: [
                      {
                        type: 'field',
                        fieldName: 'round_type',
                      },
                    ],
                  },
                },
                {
                  layer: _walkingRoutesFeatureLayer,
                  enabled: true,
                  addEnabled: true,
                  updateEnabled: true,
                  deleteEnabled: true,
                  attributeUpdatesEnabled: true,
                  geometryUpdatesEnabled: true,
                  formTemplate: {
                    name: 'New walking route',
                    description: '',
                    elements: [
                      {
                        type: 'field',
                        fieldName: 'round_type',
                      },
                    ],
                  },
                },
                {
                  layer: _standPlaceFeatureLayer,
                  enabled: true,
                  addEnabled: true,
                  updateEnabled: true,
                  deleteEnabled: true,
                  attributeUpdatesEnabled: true,
                  geometryUpdatesEnabled: true,
                  formTemplate: {
                    name: 'New stand place',
                    description: '',
                    elements: [
                      {
                        type: 'field',
                        fieldName: 'round_type',
                      },
                    ],
                  },
                },
                {
                  layer: readOnlyFeatureLayer,
                  enabled: false,
                  addEnabled: false,
                  updateEnabled: false,
                  deleteEnabled: false,
                  attributeUpdatesEnabled: false,
                  geometryUpdatesEnabled: false,
                },
                {
                  layer: readOnlyCyclingRoutesFeatureLayer,
                  enabled: false,
                  addEnabled: false,
                  updateEnabled: false,
                  deleteEnabled: false,
                  attributeUpdatesEnabled: false,
                  geometryUpdatesEnabled: false,
                },
                {
                  layer: readOnlyWalkingRoutesFeatureLayer,
                  enabled: false,
                  addEnabled: false,
                  updateEnabled: false,
                  deleteEnabled: false,
                  attributeUpdatesEnabled: false,
                  geometryUpdatesEnabled: false,
                },
                {
                  layer: readOnlyStandPlaceFeatureLayer,
                  enabled: false,
                  addEnabled: false,
                  updateEnabled: false,
                  deleteEnabled: false,
                  attributeUpdatesEnabled: false,
                  geometryUpdatesEnabled: false,
                },
              ],
              supportingWidgetDefaults: {
                featureTemplates: {
                  groupBy: () => 'Features',
                },
              },
            })

            const searchWidget = new Search({
              view,
            })

            view.ui.add(searchWidget, {
              position: 'top-left',
              index: 0,
            })

            view.ui.add(editor, 'top-right')

            view
              .goTo([
                ...source,
                ...cyclingRoutesSource,
                ...walkingRoutesSource,
                ...standPlacesSource,
              ])
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .catch((error) => {
                if (error.name !== 'AbortError') {
                  console.error(error)
                }
              })
          })
        }
      )
      .catch((err) => {
        console.error(err)
      })

    return () => {
      MapElement.current = null
    }
  }, [])

  const _onSave = () => {
    Promise.all([
      featureLayer?.queryFeatures(),
      cyclingRoutesFeatureLayer?.queryFeatures(),
      walkingRoutesFeatureLayer?.queryFeatures(),
      standPlaceFeatureLayer?.queryFeatures(),
    ]).then((results) => {
      const mappedResult: ResearchAreaGraphic[] = []

      results.forEach((result) =>
        result?.features.forEach((gr) => {
          const grJson = gr.toJSON()

          mappedResult.push({
            id: grJson.attributes.id ?? undefined,
            round_type: grJson.attributes.round_type,
            animal: grJson.attributes.animal,
            geometry: grJson.geometry,
            type: grJson.attributes.type,
          })
        })
      )

      onSave(mappedResult)
      close()
    })
  }

  return (
    <>
      <ModalLayout
        title={`${project?.name ?? ''} - ${getFormTitle(areaId)}`}
        cardContentSx={{ maxHeight: '70vh', overflowY: 'auto' }}
        footerActions={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box>
              <Button onClick={() => close()}>
                {t('common:buttons.closeWindow')}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => _onSave()}
              >
                {t('common:buttons.apply')}
              </Button>
            </Box>
          </Stack>
        }
      >
        <Box width={{ md: '85vw', sm: 'auto' }}>
          <div style={{ height: '70vh' }} ref={MapElement} key="arcgis-map" />
        </Box>
      </ModalLayout>
    </>
  )
}

import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { theme } from '@/theme'
import { alpha } from '@mui/material'

const getBorderColor = (isActive: boolean, isPassed: boolean): string => {
  if (isActive) {
    return theme.palette.green['600']
  } else if (isPassed) {
    return theme.palette.green['200']
  }

  return 'transparent'
}

export type StepProps = {
  step: number
  description: string
  isActive?: boolean
  isPassed?: boolean
}

export const Step = ({
  step,
  description,
  isActive = false,
  isPassed = false,
}: StepProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const color =
    isActive || isPassed
      ? theme.palette.green['600']
      : alpha(theme.palette.grey['700'], 0.5)

  return (
    <Box
      borderRadius={2.5}
      border="1px solid"
      borderColor="border.main"
      color={color}
      sx={{ transition: 'color .2s' }}
    >
      <Stack
        borderRadius={1.75}
        spacing={1.5}
        py={4}
        px={8}
        borderLeft="7px solid"
        borderColor={getBorderColor(isActive, isPassed)}
        sx={{ transition: 'border-color .2s' }}
      >
        <Typography>
          {t('projectExtendInfo.stepper.stepLabel', { step })}
        </Typography>
        <Typography fontWeight={600}>{description}</Typography>
      </Stack>
    </Box>
  )
}

import { capitalizeFirstLetter } from '@/utils'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type ColorType<T extends string> = {
  [key in T]: {
    backgroundColor: string
    text: string
  }
}

export type StatusProps<T extends string> = {
  status: T | null
  colors: ColorType<T>
  keyNamespace?: string
}

export const Status = <T extends string>({
  status,
  colors,
  keyNamespace = 'projects',
}: StatusProps<T>): JSX.Element => {
  const statusName = status === null ? ('new' as T) : status
  const { t } = useTranslation(keyNamespace)

  return (
    <Box
      sx={{
        borderRadius: 90,
        padding: '6px 9px',
        width: 'max-content',
        backgroundColor: colors[statusName]
          ? colors[statusName].backgroundColor
          : 'error.light',
        color: colors[statusName] ? colors[statusName].text : 'error.main',
      }}
    >
      <Typography fontSize={12} lineHeight="16px">
        {t(`status.${statusName}`, capitalizeFirstLetter(statusName))}
      </Typography>
    </Box>
  )
}

import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { Project } from '@/types/api/entities'
import { QUERY_KEYS } from '@/constants'
import { APIErrorResponse } from '@/types'

export type UseProjectProps = {
  id: number
  isEnabled?: boolean
}

export const getUseProjectKey = (id: number): string[] =>
  QUERY_KEYS.PROJECT.map((key) => (key === ':id' ? id.toString() : key))

export const useProject = ({
  id,
  isEnabled = true,
}: UseProjectProps): UseQueryResult<Project, APIErrorResponse> => {
  const client = useApiClient()

  const queryKey = getUseProjectKey(id)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/projects/${id}/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: isEnabled }
  )
}

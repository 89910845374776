import { useEffect, useState, memo, useRef } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDebounce } from '@/hooks/useDebounce'
import { KeyboardEvent } from 'react'

type SearchTextFieldProps = {
  placeholder?: string
  searchValue?: string
  onChange: (value: string) => void
  searchIcon?: boolean
  width?: string
}

export const SearchTextField = memo(
  ({
    placeholder = '',
    searchValue = '',
    onChange,
    searchIcon = true,
    width,
  }: SearchTextFieldProps): JSX.Element => {
    const firstRender = useRef(true)
    const [debounceSearchInput, setDebounceSearchInput] = useState('')
    const [searchInput, setSearchInput] = useState('')

    const onChangeDebounced = async (text: string) => {
      setDebounceSearchInput(text)
    }

    const onChangeDebounce = useDebounce(onChangeDebounced, 2000)

    const changeValue = (value: string) => {
      if (value !== searchValue) {
        onChange(value)
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        changeValue(searchInput)
      }
    }

    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false
      } else {
        changeValue(debounceSearchInput)
      }
    }, [debounceSearchInput])

    useEffect(() => {
      setSearchInput(searchValue ?? '')
    }, [searchValue])

    return (
      <TextField
        placeholder={placeholder}
        onKeyDown={(e) => handleKeyDown(e as KeyboardEvent)}
        onBlur={(e) => changeValue(e.target.value)}
        onChange={(e) => {
          onChangeDebounce(e.target.value)
          setSearchInput(e.target.value)
        }}
        value={searchInput}
        inputProps={{ 'data-testid': 'search-text-field' }}
        InputProps={{
          startAdornment: searchIcon && (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: width ?? '200px' }}
      />
    )
  }
)

import dayjs from 'dayjs'
import { DATE_FORMAT_DMY } from '@/constants'
import { Plannings } from '@/types'

type FilterPlanningByDayParams = {
  plannings: Plannings
  date: Date
}

export const filterPlanningByDay = ({
  plannings,
  date,
}: FilterPlanningByDayParams) =>
  plannings
    .map((planning) => ({
      ...planning,
      research_areas: planning.research_areas
        .map((area) => ({
          ...area,
          research_visits_events: area.research_visits_events.filter(
            (event) =>
              dayjs(date).format(DATE_FORMAT_DMY) ===
              dayjs(event.start_datetime).format(DATE_FORMAT_DMY)
          ),
        }))
        .filter((area) => !!area.research_visits_events.length),
    }))
    .filter((planning) => !!planning.research_areas.length)

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { usePlacesPredictions } from '@/hooks/usePlacesPredictions'
import { getGeoLocation } from '@/libs/googleMaps'
import { Controller } from 'react-hook-form'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { AreaAddress } from '@/components/forms'
import { ControlledFieldProps } from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'

type LocationAutocompleteProps<T extends FieldValues> = Pick<
  ControlledFieldProps<T>,
  'name' | 'control'
> & {
  disabled?: boolean
}

export const LocationAutocomplete = <T extends FieldValues>({
  name,
  control,
  disabled = false,
}: LocationAutocompleteProps<T>): JSX.Element => {
  const { t } = useTranslation('forms')

  const [searchPlace, setSearchPlace] = useState<string>('')

  const { predictions: places, isLoading } = usePlacesPredictions(searchPlace)
  const options: AreaAddress[] = places
    ? places.map((place) => ({
        locationName: place.description,
      }))
    : []

  const { mutateAsync: getLocation, isLoading: isLocationLoading } =
    useMutation((location: string) => getGeoLocation(location))

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: changeFormFieldValue, value },
        fieldState: { error },
      }) => (
        <Autocomplete
          value={value}
          onInputChange={(_, place) => {
            if (value && value.locationName && value.locationName === place) {
              return
            }
            setSearchPlace(place)
          }}
          options={options}
          onChange={(e, value) => {
            if (!value) {
              changeFormFieldValue(null)

              return
            }
            getLocation(value.locationName).then((coordinates) => {
              changeFormFieldValue({
                ...value,
                locationLat: coordinates.lat,
                locationLong: coordinates.lng,
              })
            })
          }}
          getOptionLabel={(option: AreaAddress) => option.locationName}
          isOptionEqualToValue={(option, value) =>
            option.locationName === value.locationName
          }
          renderInput={({ InputProps: RenderInputProps, ...params }) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <TextField
              {...params}
              name={name}
              placeholder={t('placeholders.location')}
              label={
                isLocationLoading
                  ? t('labels.saving-data')
                  : t('labels.location')
              }
              error={!isLocationLoading && !!error}
              helperText={!isLocationLoading && error?.message}
              InputProps={{
                ...RenderInputProps,
                endAdornment: (
                  <>
                    {isLoading || isLocationLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {RenderInputProps.endAdornment}
                  </>
                ),
              }}
              inputProps={{ ...params.inputProps, 'data-testid': name }}
            />
          )}
          loading={isLoading}
          loadingText={t('placeholders.loadingLocations')}
          disabled={isLocationLoading || disabled}
          multiple={false}
        />
      )}
    />
  )
}

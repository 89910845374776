import { APIErrorResponse, PaginatedListResponse, Specie } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '../useQueryList'
import { API_MAX_LIMIT } from '@/constants'

export const useProtocolsSpecies = (
  enabled = true
): UseQueryResult<PaginatedListResponse<Specie>, APIErrorResponse> =>
  useQueryList({
    url: '/protocols/species/',
    params: {
      limit: API_MAX_LIMIT,
    },
    options: {
      enabled,
    },
  })

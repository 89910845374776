import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  APIErrorResponse,
  LoginError,
  UserAuthInfo,
  UserCredentials,
} from '@/types'

export const useAuthJwtCreate = (): UseMutationResult<
  UserAuthInfo,
  APIErrorResponse<LoginError>,
  UserCredentials
> => {
  const client = useApiClient()

  return useMutation((data) =>
    client
      .post<UserAuthInfo>(`/auth/jwt/create/`, data)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}

import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse, ResearcherCommitment } from '@/types'
import { useApiClient } from '@/hooks'

type UsePutResearcherCommitmentParams = {
  researcherId: number
}

export const usePutResearcherCommitment = ({
  researcherId,
}: UsePutResearcherCommitmentParams): UseMutationResult<
  unknown,
  APIErrorResponse,
  ResearcherCommitment[]
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client.put(
      `plannings/researchers/${researcherId}/weekly_availability/`,
      params
    )
  )
}

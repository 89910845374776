import { Button, Stack } from '@mui/material'
import { useModal } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'

type ModalActionsProps = {
  isSaving: boolean
}

export const ModalActions = ({ isSaving }: ModalActionsProps): JSX.Element => {
  const { t } = useTranslation('planning')
  const { close } = useModal()

  return (
    <Stack spacing={2} direction="row">
      <Button variant="text" onClick={() => close()}>
        {t('common:buttons.cancel')}
      </Button>

      <LoadingButton
        type="submit"
        loading={isSaving}
        variant="contained"
        data-testid="submit-button"
      >
        <span>{t('common:buttons.saveAvailability')}</span>
      </LoadingButton>
    </Stack>
  )
}

import { ColorType, Status } from '@/components'
import { ProjectStatus as ProjectStatusType } from '@/types'

export const PROJECT_STATUS_COLORS: ColorType<ProjectStatusType> = {
  new: {
    text: 'error.main',
    backgroundColor: 'error.light',
  },
  plan: {
    text: 'warning.dark',
    backgroundColor: 'warning.light',
  },
  in_planning: {
    text: 'warning.dark',
    backgroundColor: 'warning.light',
  },
  planned: {
    text: 'warning.main',
    backgroundColor: 'warning.light',
  },
  fieldwork: {
    text: 'info.main',
    backgroundColor: 'info.light',
  },
  deskwork: {
    text: 'purple.main',
    backgroundColor: 'purple.light',
  },
  delivered: {
    text: 'success.main',
    backgroundColor: 'success.light',
  },
}

export type ProjectStatusProps = {
  status: ProjectStatusType | null
}

export const ProjectStatus = ({ status }: ProjectStatusProps): JSX.Element => {
  if (!status) return <>-</>

  return <Status status={status ?? 'new'} colors={PROJECT_STATUS_COLORS} />
}

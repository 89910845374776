import * as yup from 'yup'
import { mapRules } from '@/utils'
import translation from 'i18next'

export const protocolRoundResearchersSchema = yup.lazy((map) =>
  yup.object(
    mapRules(
      map,
      yup.object({
        id: yup.number().required(),
        is_customized: yup.boolean().required(),
        rounds: yup.array().of(
          yup.object({
            id: yup.number().required(),
            from_date: yup
              .date()
              .typeError('invalid_date')
              .required()
              .nullable(),
            to_date: yup.date().typeError('invalid_date').required().nullable(),
            required_number_of_researchers: yup
              .number()
              .typeError(translation.t('errors:validation.numberType'))
              .min(1, translation.t('errors:validation.min', { min: 1 }))
              .required(),
            number_of_visits: yup
              .number()
              .typeError(translation.t('errors:validation.numberType'))
              .min(1, translation.t('errors:validation.min', { min: 1 }))
              .required(),
            visit_duration: yup
              .number()
              .typeError(translation.t('errors:validation.numberType'))
              .min(1, translation.t('errors:validation.min', { min: 1 }))
              .required(),
          })
        ),
      })
    )
  )
)

import { Project } from '@/types'
import { Paper, Table, TableContainer } from '@mui/material'
import { ProjectsTableBody } from './ProjectsTableBody'
import { ProjectsTableHead } from './ProjectsTableHead'

export type ProjectsTableProps = {
  rows: Project[]
}

export const ProjectsTable = ({ rows }: ProjectsTableProps): JSX.Element => (
  <TableContainer component={Paper}>
    <Table stickyHeader={true}>
      <ProjectsTableHead />
      <ProjectsTableBody rows={rows} />
    </Table>
  </TableContainer>
)

import { CircularProgress, Stack } from '@mui/material'
import { StepComponentProps, ProjectSpeciesFormContainer } from '@/features'
import { CardLayout } from '@/components'
import { useTranslation } from 'react-i18next'
import { useProjectSpecies } from '@/hooks'
import { QUERY_KEYS } from '@/constants'

export const ProjectSpeciesStep = ({
  projectId,
}: StepComponentProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const { data: projectSpeciesData, isLoading } = useProjectSpecies(
    projectId,
    QUERY_KEYS.RESEARCH_SPECIES.map((key) =>
      key === ':id' ? projectId.toString() : key
    )
  )

  return (
    <CardLayout
      title={t('titles.researchType')}
      dataTestId="research-types-container"
      cardSx={{ pb: 0 }}
    >
      <Stack spacing={5}>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} color="success" />
        ) : (
          <ProjectSpeciesFormContainer
            projectId={projectId}
            projectSpeciesData={projectSpeciesData ?? []}
          />
        )}
      </Stack>
    </CardLayout>
  )
}

import { useMemo, useState } from 'react'
import { DatesSetArg } from '@fullcalendar/react'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD, EMPLOYEE_PLANNING_DAYS_COUNT } from '@/constants'
import { usePlannings, useProjectPlanning } from '@/hooks'
import { ResearcherAutocompleteType } from '@/features/planning/ResearcherAutocomplete'

type UsePlanningData = {
  projectId?: number
  hasLimit?: boolean
}

export const usePlanningData = ({
  projectId,
  hasLimit = true,
}: UsePlanningData = {}) => {
  const [calendarDateRange, setCalendarDateRange] = useState<DatesSetArg>()
  const [searchParam, setSearchParam] = useState('')
  const [researcher, setResearcher] = useState<ResearcherAutocompleteType>(null)

  const startDate = dayjs(calendarDateRange?.view.currentStart)

  const daysCount = hasLimit
    ? dayjs().date() + EMPLOYEE_PLANNING_DAYS_COUNT
    : startDate.daysInMonth()

  const endDate = (hasLimit ? dayjs() : startDate.clone()).set(
    'date',
    daysCount
  )

  const params = {
    startDate: startDate.format(DATE_FORMAT_YMD),
    endDate: endDate.format(DATE_FORMAT_YMD),
    search: searchParam,
  }

  const { data } = projectId
    ? useProjectPlanning({
        ...params,
        projectId,
        enabled: !!calendarDateRange,
      })
    : usePlannings({
        ...params,
        enabled: !!calendarDateRange,
        researcher: researcher?.value,
      })

  const plannings = useMemo(() => {
    if (!data) return undefined

    return Array.isArray(data) ? data : [data]
  }, [data])

  return {
    plannings,
    setCalendarDateRange,
    searchParam,
    setSearchParam,
    researcher,
    setResearcher,
  }
}

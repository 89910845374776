import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import { css } from '@emotion/react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calendarStyles = (props: any) => css`
  .fc {
    .fc-toolbar-chunk div {
      display: flex;
    }
    .fc-prev-button {
      margin-left: 20px;
      border-radius: 5px 0 0 5px;
    }
    .fc-next-button {
      border-radius: 0 5px 5px 0;
      border-left: 0;
    }
    .fc-button,
    .fc-button-primary {
      color: ${props.theme.palette.text.primary};
      background: ${props.theme.palette.background.paper};
      font-weight: ${props.theme.typography.fontWeightMedium};
      font-family: ${props.theme.typography.fontFamily};
      border-color: ${props.theme.palette.divider};
      outline: 0;
      box-shadow: none;

      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):active,
      &:not(:disabled):active:focus {
        box-shadow: none;
        background-color: ${props.theme.palette.primary.dark};
        color: ${props.theme.palette.background.paper};
      }

      &:not(:disabled):active,
      &:not(:disabled).fc-button-active {
        background-color: ${props.theme.palette.primary.main};
        color: ${props.theme.palette.background.paper};

        &:focus {
          box-shadow: none;
        }
        :hover {
          background: ${props.theme.palette.primary.dark};
        }
      }

      &-primary:disabled {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .fc-event {
      padding: ${props.theme.spacing(1)};
      margin: ${props.theme.spacing(0.5)};
    }

    .fc-daygrid-event-harness {
      margin: ${props.theme.spacing(0, 3)};
    }

    .fc-h-event {
      cursor: pointer;
      margin: 0 !important;
      padding: 0;
      border: none;
      background: ${props.theme.palette.grey['100']};

      .fc-event-main {
        color: ${props.theme.palette.text.primary};
      }
    }

    .fc-daygrid-day.fc-day-today {
      box-shadow: inset 0 0.3em ${props.theme.palette.green['600']};
      background-color: ${props.theme.palette.grey['50']};

      .fc-h-event {
        border: 0.1rem solid ${props.theme.palette.grey['200']};
        background: ${props.theme.palette.grey['100']};
      }
    }

    .fc-dayGrid-view .fc-week-number,
    .fc-dayGrid-view .fc-day-number {
      padding: ${props.theme.spacing(1)};
    }

    th {
      padding: ${props.theme.spacing(1)} 0;
      font-weight: 400;
    }
    .fc-daygrid-day-frame {
      min-height: 150px;
    }
  }
`

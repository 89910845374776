import { useProjectsNames } from '@/hooks'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'

type ProjectMultiSelectProps = {
  value?: string
  onChange: (value: string) => void
  placeholder?: string
}

export const ProjectsMultiSelect = ({
  value,
  onChange,
  placeholder,
}: ProjectMultiSelectProps): JSX.Element => {
  const { data: projects } = useProjectsNames({})

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={projects?.map(
        ({ id, name, project_number }) =>
          ({
            label: `${name} - ${project_number}`,
            value: id.toString(),
          } as SearchAutocompleteOption)
      )}
      placeholder={placeholder}
      sort={true}
      fieldSx={{ width: { xs: 390, md: 280 } }}
    />
  )
}

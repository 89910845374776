import * as yup from 'yup'
import translation from 'i18next'
import { RoundType } from '@/types'

type ProjectAreaSchemaProps = {
  researchersMaxNumber: Partial<Record<RoundType, number>>
}

export const projectAreaSchema = ({
  researchersMaxNumber,
}: ProjectAreaSchemaProps) =>
  yup.object({
    protocols: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
          title: yup.string(),
        })
      )
      .min(1),
    areaAddress: yup
      .object({
        locationName: yup.string(),
        locationLat: yup.string(),
        locationLong: yup.string(),
      })
      .nullable()
      .required(translation.t('errors:validation.invalid_place')),
    morningResearchers: yup
      .number()
      .typeError(translation.t('errors:validation.numberType'))
      .min(1, translation.t('errors:validation.min', { min: 1 }))
      .max(
        researchersMaxNumber.morning ?? 100,
        translation.t('errors:validation.max', {
          max: researchersMaxNumber.morning ?? 100,
        })
      )
      .nullable(),
    dayResearchers: yup
      .number()
      .typeError(translation.t('errors:validation.numberType'))
      .min(1, translation.t('errors:validation.min', { min: 1 }))
      .max(
        researchersMaxNumber.day ?? 100,
        translation.t('errors:validation.max', {
          max: researchersMaxNumber.day ?? 100,
        })
      )
      .nullable(),
    eveningResearchers: yup
      .number()
      .typeError(translation.t('errors:validation.numberType'))
      .min(1, translation.t('errors:validation.min', { min: 1 }))
      .max(
        researchersMaxNumber.evening ?? 100,
        translation.t('errors:validation.max', {
          max: researchersMaxNumber.evening ?? 100,
        })
      )
      .nullable(),
    nightResearchers: yup
      .number()
      .typeError(translation.t('errors:validation.numberType'))
      .min(1, translation.t('errors:validation.min', { min: 1 }))
      .max(
        researchersMaxNumber.night ?? 100,
        translation.t('errors:validation.max', {
          max: researchersMaxNumber.night ?? 100,
        })
      )
      .nullable(),
  })

import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { LOCAL_STORAGE_REFRESH_TOKEN_KEY, ROUTES_PATH } from '@/constants'
import { UserAuthInfo } from '@/types'
import { safelyParseJson, isValidToken, setSession } from '@/utils'
import { onAxiosRequestError } from './onAxiosRequestError'

const refreshAccessToken = (
  client: AxiosInstance,
  refresh: string,
  request: AxiosRequestConfig
) =>
  client
    .post<UserAuthInfo>('/auth/jwt/refresh/', { refresh })
    .then((response) => {
      const token = response.data.access

      setSession(token)

      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${token}`
      }

      client(request)
    })
    .catch((err) => {
      console.warn(err)
    })

export const configureInterceptors = (client: AxiosInstance): void => {
  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError<{ code?: string }>) => {
      const { response: errorResponse, config: originalRequest } = error
      const isAuthorizationError = errorResponse?.status === 401

      if (
        isAuthorizationError &&
        originalRequest.url ===
          process.env.REACT_APP_API_ENDPOINT + 'auth/jwt/refresh/'
      ) {
        window.location.href = ROUTES_PATH.LOGIN

        return Promise.reject(error)
      }

      if (
        isAuthorizationError &&
        errorResponse?.data.code === 'token_not_valid'
      ) {
        const localStorageRefreshToken = localStorage.getItem(
          LOCAL_STORAGE_REFRESH_TOKEN_KEY
        )
        const localStorageRefreshTokenParsed = localStorageRefreshToken
          ? safelyParseJson(localStorageRefreshToken)
          : ''

        const sessionStorageRefreshToken = sessionStorage.getItem(
          LOCAL_STORAGE_REFRESH_TOKEN_KEY
        )
        const sessionStorageRefreshTokenParsed = sessionStorageRefreshToken
          ? safelyParseJson(sessionStorageRefreshToken)
          : ''

        const refresh = (localStorageRefreshTokenParsed ??
          sessionStorageRefreshTokenParsed) as string

        if (refresh) {
          if (isValidToken(refresh)) {
            return refreshAccessToken(client, refresh, originalRequest)
          } else {
            onAxiosRequestError(`Refresh token is expired`)
          }
        } else {
          onAxiosRequestError('Refresh token not available.')
        }
      }

      return Promise.reject(error)
    }
  )
}

import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'

export type EventContentProps = {
  researchMoments: string
  researchers: string
  managerReminder: boolean
  date: Date | null
  researchAreaLabels: string[]
  onClick?: (date: Date | null) => void
}

export const EventContent = ({
  researchMoments,
  researchers,
  managerReminder = false,
  date,
  researchAreaLabels,
  onClick,
}: EventContentProps) => {
  const { t } = useTranslation('planning')

  return (
    <div
      onClick={() => onClick && onClick(date)}
      data-testid="calendar-event-content"
    >
      <Stack spacing={3} p={3} position="relative" sx={{ cursor: 'pointer' }}>
        <Box whiteSpace="normal">
          <Typography
            variant="body2"
            fontWeight={700}
            lineHeight="12px"
            data-testid="calendar-event-research-moments"
          >
            {researchMoments}
          </Typography>
          <Typography variant="body2">{t('events.researchMoments')}</Typography>
        </Box>

        <Box whiteSpace="normal">
          <Typography
            variant="body2"
            fontWeight={700}
            lineHeight="12px"
            data-testid="calendar-event-researchers"
          >
            {researchers}
            {managerReminder && (
              <Box component="span" color="error.main">
                &nbsp;*
              </Box>
            )}
          </Typography>
          <Typography variant="body2">{t('events.researchers')}</Typography>
        </Box>
      </Stack>

      <Tooltip
        title={
          <Stack data-testid="calendar-event-tooltip-content">
            {researchAreaLabels.map((label, index) => (
              <Typography variant="body2" key={label + index}>
                {t('areas.researchAreaNumber') + label.toString()}
              </Typography>
            ))}

            {!researchAreaLabels.length && (
              <Typography>{t('events.notResearchArea')}</Typography>
            )}
          </Stack>
        }
        placement="bottom"
        onClick={(event) => event.stopPropagation()}
        data-testid="calendar-event-tooltip"
      >
        <Box
          sx={{
            position: 'absolute',
            top: 6,
            right: 6,
            cursor: 'pointer',
            ':hover': { svg: { color: 'green.500' } },
          }}
        >
          <Info size="16px" />
        </Box>
      </Tooltip>
    </div>
  )
}

import { Box, BoxProps, Stack, SvgIconTypeMap, Typography } from '@mui/material'
import { ReactNode } from 'react'
import SvgIcon from '@mui/material/SvgIcon/SvgIcon'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export type CardAttributeProps = {
  title: string
  value: string | number | null | undefined | ReactNode
  icon?: OverridableComponent<SvgIconTypeMap>
  colorIcon?: string
} & BoxProps

export const CardAttribute = ({
  title,
  value,
  icon,
  colorIcon,
  ...props
}: CardAttributeProps): JSX.Element => (
  <Box {...props}>
    <Stack direction="row" alignItems="flex-start">
      <Typography
        variant="caption"
        color={colorIcon ? colorIcon : 'grey.600'}
        lineHeight="16px"
      >
        {title}
      </Typography>
      {icon && (
        <Box sx={{ height: 14 }} color={colorIcon}>
          <SvgIcon
            component={icon}
            sx={{ height: 14, width: 14, ml: 2, mb: 1 }}
          />
        </Box>
      )}
    </Stack>

    {typeof value === 'object' ? (
      <div>{value ?? '-'}</div>
    ) : (
      <Typography sx={{ wordBreak: 'break-word' }}>{value ?? '-'}</Typography>
    )}
  </Box>
)

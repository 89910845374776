import { Box, Stack, Typography } from '@mui/material'
import {
  ControlledCheckboxField,
  ControlledTextField,
} from '@/components/inputs'
import { Control } from 'react-hook-form'
import { AvailabilityFormData, FORM_KEY_DATE_FORMAT } from '@/features'
import { useTranslation } from 'react-i18next'
import { AMOUNT_OF_DAYS_WEEK, DATE_TEXT_SHORT_FORMAT } from '@/constants'
import { getDateOfDayInWeek } from '@/utils/date'
import dayjs from 'dayjs'

export type DaysSelectionProps = {
  weekNumber: number
  control: Control<AvailabilityFormData>
}

export const DaysSelection = ({
  weekNumber,
  control,
}: DaysSelectionProps): JSX.Element => {
  const { t } = useTranslation('planning')

  return (
    <>
      <Box>
        <Stack
          sx={{
            mr: 2,
            '> div:nth-of-type(odd)': {
              bgcolor: 'grey.100',
            },
          }}
          data-testid="day-list"
        >
          {[...Array(AMOUNT_OF_DAYS_WEEK)].map((_, dayNumber) => {
            const dateOfDay = getDateOfDayInWeek(weekNumber, dayNumber)
            const formIndex = dayjs(dateOfDay).format(FORM_KEY_DATE_FORMAT)

            return (
              <Stack key={dayNumber} mb={2} pt={1} px={2}>
                <Typography>
                  {dayjs(dateOfDay).format(DATE_TEXT_SHORT_FORMAT)}
                </Typography>

                <Stack direction="row" spacing={2} ml={2}>
                  <ControlledCheckboxField
                    name={`availability.${formIndex}.availableForMorningRound`}
                    control={control}
                    label={t('availability.morningRoundLabel')}
                  />
                  <ControlledCheckboxField
                    name={`availability.${formIndex}.availableForDayRound`}
                    control={control}
                    label={t('availability.dayRoundLabel')}
                  />
                  <ControlledCheckboxField
                    name={`availability.${formIndex}.availableForEveningRound`}
                    control={control}
                    label={t('availability.eveningRoundLabel')}
                  />
                </Stack>
              </Stack>
            )
          })}
        </Stack>
      </Box>
      <Box mt={3}>
        <ControlledTextField
          name={`commitment.${weekNumber}`}
          control={control}
          label={t('availability.commitmentInputLabel')}
          fullWidth={false}
        />
      </Box>
    </>
  )
}

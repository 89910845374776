import { GridAccordion } from '@/components'
import { ProtocolRoundInfo } from '@/features'
import { ProjectProtocolRound } from '@/types'

type ProtocolRoundInfoAccordionProps = {
  title: string
  round?: ProjectProtocolRound
  allRounds: ProjectProtocolRound[]
}

export const ProtocolRoundInfoAccordion = ({
  title,
  round,
  allRounds,
}: ProtocolRoundInfoAccordionProps): JSX.Element => {
  if (!round) return <></>

  return (
    <GridAccordion title={title}>
      <ProtocolRoundInfo round={round} allRounds={allRounds} />
    </GridAccordion>
  )
}

import { Control, Path, Controller, FieldValues } from 'react-hook-form'
import { HTMLInputTypeAttribute } from 'react'
import { ApiErrorData } from '@/types'
import {
  capitalizeFirstLetter,
  getFieldErrorMessage,
  hasFieldError,
} from '@/utils'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Select, SelectOption } from '@/components'

export type ControlledSelectPropsProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  label?: string
  labelSx?: SxProps<Theme>
  placeholder?: string
  variant?: 'standard' | 'filled' | 'outlined'
  defaultType?: HTMLInputTypeAttribute
  options?: SelectOption[]
  errorMessagePrefix?: string
  apiErrorData?: ApiErrorData
  apiFieldName?: string
  isOptional?: boolean
  isLoading?: boolean
  fullWidth?: boolean
  displayEmpty?: boolean
  disabled?: boolean
}

export const ControlledSelect = <T extends FieldValues>({
  label,
  labelSx,
  name,
  control,
  placeholder,
  variant = 'outlined',
  defaultType = 'text',
  options,
  errorMessagePrefix = 'errors:validation',
  apiErrorData,
  apiFieldName,
  isOptional = false,
  isLoading,
  fullWidth = true,
  displayEmpty = false,
  disabled = false,
  ...props
}: ControlledSelectPropsProps<T>): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          id={name}
          options={options}
          value={value as string}
          setValue={onChange}
          emptyValueText={placeholder}
          variant={variant}
          type={defaultType}
          label={label}
          error={!!error || hasFieldError(apiFieldName ?? name, apiErrorData)}
          helperText={
            error?.message
              ? t(
                  errorMessagePrefix
                    ? `${errorMessagePrefix}.${error.message}`
                    : error.message
                )
              : capitalizeFirstLetter(
                  getFieldErrorMessage(apiFieldName ?? name, apiErrorData)
                )
          }
          inputProps={{
            'data-testid': name,
          }}
          isLoading={isLoading}
          fullWidth={fullWidth}
          displayEmpty={displayEmpty}
          labelSx={labelSx}
          isOptional={isOptional}
          isOptionalText={t('forms:labels.optional')}
          disabled={disabled}
          {...props}
        />
      )}
    />
  )
}

import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseObservationLogsKey } from '@/hooks'
import { APIErrorResponse, ObservationLog, PatchObservationLog } from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'

export type UsePatchObservationLogProps = {
  id: number
}

export const usePatchObservationLog = ({
  id,
}: UsePatchObservationLogProps): UseMutationResult<
  ObservationLog,
  APIErrorResponse,
  PatchObservationLog
> => {
  const client = useApiClient()

  const queryClient = useQueryClient()

  const queryKey = getUseObservationLogsKey()

  return useMutation(
    (params) =>
      client
        .patch(`plannings/observation_logs/${id}/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )
}

import { useTranslation } from 'react-i18next'
import { Stack, Box, Button, Typography } from '@mui/material'
import { VisitLog } from '@/features'
import { Indicator, LinkButton } from '@/components/ui'
import { useModal, useVisitLog } from '@/hooks'
import { EventVisitLog } from '@/types'
import { getPath } from '@/utils'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY, ROUTES_PATH, PageReferrers } from '@/constants'

type RoundVisitInfoProps = {
  researchVisitLog: EventVisitLog
}

export const RoundVisitInfo = ({
  researchVisitLog,
}: RoundVisitInfoProps): JSX.Element => {
  const { t } = useTranslation('dataValidation')
  const { open } = useModal()

  const { data: visitLog } = useVisitLog({ id: researchVisitLog.id })

  const handleViewButton = () => {
    if (visitLog) {
      open(<VisitLog visitLog={visitLog} />)
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Indicator
          isSmall={false}
          status={visitLog?.status}
          name={t('visit-log.fieldTitles.observationDate')}
        />
        <Box ml={2}>
          {visitLog?.start_datetime
            ? dayjs(visitLog?.start_datetime).format(DATE_FORMAT_DMY)
            : '-'}
        </Box>
      </Stack>
      <Button variant="text" onClick={() => handleViewButton()}>
        <Typography variant="body2" fontWeight={600}>
          {visitLog?.status === 'invalid'
            ? t('buttons.validateData')
            : t('visit-log.buttons.viewVisitLog')}
        </Typography>
      </Button>
      {!!visitLog?.extra_research_visit_event && (
        <LinkButton
          url={getPath(ROUTES_PATH.MONITORING_DETAIL, {
            id: visitLog.extra_research_visit_event.toString(),
          })}
          title={t('visit-log.buttons.goToExtraRoundPage')}
          referrer={PageReferrers.ROUND}
          arrowIcon={true}
        />
      )}
    </Stack>
  )
}

import { useTranslation } from 'react-i18next'
import { TableHead, TableRow, TableCell } from '@mui/material'

const columnNames = [
  'research_area',
  'client_area_number',
  'client_name',
  'project_name',
  'project_number',
  'location_name',
  'researchers',
  'uncertainty',
]

export const MonitoringTableHead = (): JSX.Element => {
  const { t } = useTranslation('monitoring')

  return (
    <TableHead sx={{ borderRadius: 8 }}>
      <TableRow>
        {columnNames.map((columnName) => (
          <TableCell key={columnName}>
            {t(`columnNames.${columnName}`)}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

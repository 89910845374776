import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { ManagerResearchVisitEventWithProjectInfo } from '@/types/api/entities'
import { QUERY_KEYS } from '@/constants'
import { APIErrorResponse } from '@/types'

export type UseRoundProps = {
  id: number
  isEnabled?: boolean
}

export const getUseRoundKey = (id: number): string[] =>
  QUERY_KEYS.EVENT.map((key) => (key === ':id' ? id.toString() : key))

export const useRound = ({
  id,
  isEnabled = true,
}: UseRoundProps): UseQueryResult<
  ManagerResearchVisitEventWithProjectInfo,
  APIErrorResponse
> => {
  const client = useApiClient()

  const queryKey = getUseRoundKey(id)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/plannings/events/manager/${id}/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: isEnabled }
  )
}

import { APIErrorResponse, ResearchArea } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '../useQueryList'

export const useProjectResearchAreas = (
  projectId: number
): UseQueryResult<ResearchArea[], APIErrorResponse> =>
  useQueryList({
    url: `/projects/${projectId}/research_areas/`,
    options: {
      enabled: !!projectId,
      staleTime: Infinity,
    },
  })

import { QUERY_KEYS } from '@/constants'
import { useApiClient } from '@/hooks'
import {
  ApiErrorData,
  APIErrorResponse,
  ProjectProtocol,
  ProjectProtocolRound,
} from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export type PatchProjectProtocolInput = {
  id: number
  is_customized: boolean
  rounds_to_update: Omit<ProjectProtocolRound, 'round_type'>[]
}
export const usePatchProjectProtocol = (
  projectId: number
): UseMutationResult<
  ProjectProtocol,
  APIErrorResponse<ApiErrorData>,
  PatchProjectProtocolInput
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch<ProjectProtocol>(
          `/projects/${projectId}/protocols/${params.id}/`,
          params
        )
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data) => {
        const queryKey = QUERY_KEYS.PROJECT_PROTOCOLS.map((key) =>
          key === ':projectId' ? projectId.toString() : key
        )

        const queryData = queryClient.getQueryData(
          queryKey
        ) as ProjectProtocol[]

        if (queryData) {
          const newData = queryData.map((item) =>
            item.id === data.id ? data : item
          )

          queryClient.setQueryData(queryKey, newData)
        }
      },
    }
  )
}

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { useForm } from 'react-hook-form'
import { FormComponentProps } from '@/types'
import { useTranslation } from 'react-i18next'
import { LoadingButton, PasswordInputAdornment } from '@/components/ui'
import { ControlledTextField } from '@/components/inputs'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import * as yup from 'yup'

const loginSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export type LoginFormData = {
  email: string
  password: string
  isRemember?: boolean
}

export const LoginForm = ({
  onSubmit,
  isLoading,
  apiErrorData,
}: FormComponentProps<LoginFormData>): JSX.Element => {
  const { t } = useTranslation('forms')
  const navigate = useNavigate()

  const { handleSubmit, control, register } = useForm<LoginFormData>({
    defaultValues: {
      email: '',
      password: '',
      isRemember: false,
    },
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
  })
  const [showPassword, setShowPassword] = useState(false)

  const handleForgotPassword = () => navigate('/auth/reset-password')

  return (
    <Box minWidth={{ md: 400 }}>
      <form
        onSubmit={handleSubmit(async (data) => {
          onSubmit({
            email: data.email,
            password: data.password,
            isRemember: data.isRemember,
          })
        })}
      >
        <Stack spacing={6} mb={2}>
          <Box pb={3} color="black">
            <Typography variant="h4" pb={2}>
              {t('titles.logging')}
            </Typography>
            <Typography>{t('descriptions.logging')}</Typography>
          </Box>
          <ControlledTextField
            name="email"
            placeholder={t('labels.email')}
            control={control}
            fullWidth={true}
            apiFieldName="email"
            apiErrorData={apiErrorData}
            endAdornment={
              <InputAdornment position="end">
                <AlternateEmailIcon />
              </InputAdornment>
            }
          />

          <ControlledTextField
            name="password"
            placeholder={t('labels.password')}
            control={control}
            fullWidth={true}
            apiFieldName="password"
            apiErrorData={apiErrorData}
            showPassword={showPassword}
            endAdornment={
              <PasswordInputAdornment
                show={showPassword}
                setShow={setShowPassword}
              />
            }
          />
        </Stack>

        <Stack spacing={6}>
          <FormControlLabel
            control={<Checkbox {...register('isRemember')} />}
            label={t('common:buttons.rememberMe')}
            labelPlacement="end"
          />
          <LoadingButton
            fullWidth={true}
            isLoading={isLoading}
            disabled={isLoading}
            data-testid="submit-login-button"
            type="submit"
          >
            {t('common:buttons.signIn')}
          </LoadingButton>
          <Button variant="text" type="button" onClick={handleForgotPassword}>
            {t('common:buttons.forgotPassword')}
          </Button>
        </Stack>
      </form>
    </Box>
  )
}

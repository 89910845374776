import { InputAdornment } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Dispatch, SetStateAction } from 'react'

export type PasswordInputAdornmentProps = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

export const PasswordInputAdornment = ({
  show,
  setShow,
}: PasswordInputAdornmentProps) => (
  <InputAdornment
    position="end"
    onClick={() => setShow((prev) => !prev)}
    sx={{ cursor: 'pointer' }}
  >
    <>{show ? <VisibilityIcon /> : <VisibilityOffIcon />}</>
  </InputAdornment>
)

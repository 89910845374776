import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { VisitLogFormData } from '@/components/forms'
import { ControlledTextField } from '@/components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export type EnvironmentDataFieldProps = {
  iconName: IconDefinition
  field: keyof VisitLogFormData
  fieldLocalization: string
}

export const EnvironmentDataField = ({
  iconName,
  field,
  fieldLocalization,
}: EnvironmentDataFieldProps) => {
  const { t } = useTranslation('dataValidation')
  const { control } = useFormContext<VisitLogFormData>()

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <FontAwesomeIcon icon={iconName} size="sm" />
      <ControlledTextField
        type="text"
        name={field}
        control={control}
        placeholder={t(`visit-log.placeholders.${fieldLocalization}`)}
        errorMessagePrefix=""
        fullWidth={true}
      />
    </Stack>
  )
}

import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  InputLabel,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { ControlledFieldProps, Specie } from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'

export type AutocompleteOption<T = string> = {
  value: T
  title: string
} & Record<string, string | number | boolean | null | Specie>

export type ControlledAutocompleteProps<T, D extends FieldValues> = {
  inputProps?: Partial<StandardInputProps>
  textFieldProps?: TextFieldProps
  onChangeCustom?: (item: unknown) => void
} & Omit<
  AutocompleteProps<
    AutocompleteOption<T>,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  'renderInput'
> &
  ControlledFieldProps<D>

export const ControlledAutocomplete = <T, D extends FieldValues>({
  name,
  control,
  inputLabel,
  inputLabelSx,
  isOptional,
  inputProps = {},
  textFieldProps = {},
  onChangeCustom,
  ...props
}: ControlledAutocompleteProps<T, D>): JSX.Element => {
  const { t } = useTranslation('forms')

  return (
    <Box sx={{ width: textFieldProps.fullWidth ? '100%' : 'auto' }}>
      {inputLabel && (
        <InputLabel sx={{ mb: 2, ...inputLabelSx }}>
          {inputLabel} {isOptional && t('forms:labels.optional')}
        </InputLabel>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            onChange={(event, item) => {
              onChange(item)

              onChangeCustom && onChangeCustom(item)
            }}
            value={value}
            noOptionsText={t('tips.noOptions' ?? props.noOptionsText)}
            isOptionEqualToValue={(option, value) => {
              const textValue = typeof value === 'string' ? value : value.value

              return typeof option === 'string'
                ? option === textValue
                : option.value === textValue
            }}
            renderInput={({ InputProps: RenderInputProps, ...params }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <TextField
                {...params}
                name={name}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  ...RenderInputProps,
                  endAdornment: (
                    <>
                      {props.loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {RenderInputProps.endAdornment}
                    </>
                  ),
                  ...inputProps,
                }}
                {...textFieldProps}
              />
            )}
            renderOption={(props, option, state) => {
              const title = typeof option === 'string' ? option : option.title
              const matches = match(title, state.inputValue)
              const parts = parse(title, matches)

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              )
            }}
            getOptionLabel={(option: AutocompleteOption<T> | string) =>
              typeof option === 'string' ? option : option.title
            }
            {...props}
          />
        )}
      />
    </Box>
  )
}

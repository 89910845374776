import { createTheme } from '@mui/material/styles'
import { greenVariant } from './variants'
import { shadows } from './shadows'
import { Color, SimplePaletteColorOptions } from '@mui/material'
import { ColorPartial } from '@mui/material/styles/createPalette'

declare module '@mui/material/styles/createPalette' {
  type GreenHue = Pick<
    Color,
    50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800
  >

  interface Palette {
    green: GreenHue
    purple: SimplePaletteColorOptions
    blue: ColorPartial
    border: SimplePaletteColorOptions
    buttonGrey: SimplePaletteColorOptions
  }

  interface PaletteOptions {
    green?: GreenHue
    purple?: SimplePaletteColorOptions
    blue?: ColorPartial
    border?: SimplePaletteColorOptions
    buttonGrey?: SimplePaletteColorOptions
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } })

export const theme = createTheme(
  {
    spacing: 4,
    palette: {
      primary: {
        light: '#1C95C2',
        main: '#00486F',
        dark: '#0777A3',
      },
      secondary: {
        light: '#DAEDC3',
        main: '#A1C85C',
        dark: '#B2D47C',
      },
      error: {
        light: '#FFE5E5',
        main: '#E93D3D',
        contrastText: '#fff',
      },
      warning: {
        light: '#FBF3EF',
        main: '#FFA000',
        dark: '#E96F3D',
        contrastText: '#fff',
      },
      info: {
        light: '#F0F2F9',
        main: '#2D5DF6',
      },
      success: {
        light: '#ECF6F0',
        main: '#54A971',
        contrastText: '#fff',
      },
      purple: {
        light: '#FBF1FF',
        main: '#AF58D2',
      },
      grey: {
        50: '#FBFBFB',
        100: '#F9F9F9',
        200: '#EDEDED',
        300: '#DDDDDD',
        400: '#C9C9C9',
        500: '#CCCCCC',
        600: '#828282',
        700: '#494949',
        900: '#0A0A09',
      },
      green: {
        50: '#FBFFF5',
        100: '#F0F9E3',
        200: '#DAEDC3',
        300: '#C5E0A1',
        400: '#B2D47C',
        500: '#A1C85C',
        600: '#4A6124',
        700: '#283A12',
        800: '#0A1501',
      },
      blue: {
        50: '#EDF2F5',
        100: '#B0DEEB',
        200: '#53B3D0',
        300: '#1C95C2',
        400: '#0777A3',
        500: '#00486F',
      },
      text: {
        primary: '#283A12',
        secondary: '#828282',
      },
      divider: '#CCCCCC',
      background: {
        paper: '#fff',
        default: '#F0F9E3',
      },
      action: {
        disabled: '',
        disabledBackground: '',
      },
      border: {
        main: '#EDEDED',
      },
      buttonGrey: createColor('#828282'),
    },
    shadows,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: [
        'Nunito Sans',
        'Noto Sans',
        '-apple-system',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      h1: {
        fontSize: '3rem', //48px
        fontWeight: 700,
        lineHeight: 1.3,
      },
      h2: {
        fontSize: '2rem', //32px
        fontWeight: 700,
        lineHeight: 1.3,
      },
      h3: {
        fontSize: '1.75rem', //28px
        fontWeight: 700,
        lineHeight: 1.3,
      },
      h4: {
        fontSize: '1.5rem', //24px
        fontWeight: 700,
        lineHeight: 1.4,
      },
      h5: {
        fontSize: '1.25rem', //20px
        fontWeight: 700,
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '1rem', //16px
        fontWeight: 700,
        lineHeight: 1.4,
      },
      // body1: 16px
      // body2: 14px
      // caption: 12px
      subtitle1: {
        fontSize: 18,
      },
      button: {
        textTransform: 'none',
      },
    },
  },
  {
    header: greenVariant.header,
    footer: greenVariant.footer,
  }
)

import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormHelperText,
  ListSubheader,
  Icon,
  InputLabel,
  SxProps,
  Theme,
  Typography,
  CircularProgress,
  Stack,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type SelectOption = {
  name: string
  value: string
  group?: string
}

export type SelectProps = {
  options?: SelectOption[]
  grouping?: boolean
  value: SelectOption['value']
  setValue: (value: SelectOption['value']) => void
  emptyValueText?: string
  helperText?: string
  isLoading?: boolean
  isOptional?: boolean
  isOptionalText?: string
  labelSx?: SxProps<Theme>
} & MuiSelectProps<string>

export const Select = ({
  options,
  grouping = false,
  value,
  setValue,
  emptyValueText,
  fullWidth = true,
  helperText,
  isLoading,
  isOptional = false,
  isOptionalText,
  labelSx,
  ...muiSelectProps
}: SelectProps): JSX.Element => {
  const getOptions = (options: SelectOption[]) =>
    options.map((option, index, options) => {
      const showGroupTitle =
        grouping &&
        (index === 0 ||
          (index > 0 && options[index - 1].group !== option.group))

      if (showGroupTitle)
        return [
          <ListSubheader key={option.group}>{option.group}</ListSubheader>,
          getOption(option),
        ]

      return getOption(option)
    })

  const getOption = ({ name, value }: SelectOption) => (
    <MenuItem key={`key-${value}`} value={value}>
      {name}
    </MenuItem>
  )

  return (
    <FormControl data-testid="select" fullWidth={fullWidth}>
      {muiSelectProps.label && (
        <InputLabel sx={labelSx}>
          {muiSelectProps.label} {isOptional && isOptionalText}
        </InputLabel>
      )}
      <MuiSelect
        IconComponent={(props) => (
          <Icon {...props}>
            <ExpandMoreIcon sx={{ fontSize: '24px' }} />
          </Icon>
        )}
        value={value}
        onChange={(event: SelectChangeEvent) => setValue(event.target.value)}
        {...muiSelectProps}
      >
        {isLoading && (
          <Stack direction="row" justifyContent="center" my={2}>
            <CircularProgress
              size={21}
              sx={{ alignSelf: 'center' }}
              color="success"
            />
          </Stack>
        )}

        {emptyValueText && muiSelectProps.displayEmpty && (
          <MenuItem value="">
            <em>{emptyValueText}</em>
          </MenuItem>
        )}

        {options && getOptions(options)}
      </MuiSelect>
      {helperText && (
        <FormHelperText>
          <Typography variant="body2" color="error" component="span">
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  )
}

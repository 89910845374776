import { Autocomplete, TextField, SxProps, Theme } from '@mui/material'
import { sortArrayOfObjectsByString } from '@/utils'

export type SearchAutocompleteOption = {
  label: string
  value: string
}

type SearchAutocompleteProps = {
  value?: string
  onChange: (value: string) => void
  options?: SearchAutocompleteOption[]
  placeholder?: string
  sort?: boolean
  fieldSx?: SxProps<Theme>
}

const generateSelectedOptions = (
  options: SearchAutocompleteOption[],
  value?: string
) => {
  if (value) {
    const selectedIds = value.split(',')

    return options.reduce((so, o) => {
      if (selectedIds.includes(o.value)) {
        so.push({
          label: o.label,
          value: o.value,
        })
      }

      return so
    }, [] as SearchAutocompleteOption[])
  }

  return []
}

export const SearchAutocomplete = ({
  value,
  onChange,
  options,
  placeholder,
  sort = false,
  fieldSx,
}: SearchAutocompleteProps): JSX.Element => {
  if (!options) return <></>

  const selected = generateSelectedOptions(options, value)

  return (
    <Autocomplete
      multiple={true}
      options={sort ? sortArrayOfObjectsByString(options, 'label') : options}
      filterSelectedOptions={true}
      isOptionEqualToValue={(option, value) => {
        const textValue = typeof value === 'string' ? value : value.value

        return typeof option === 'string'
          ? option === textValue
          : option.value === textValue
      }}
      renderInput={(params) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextField {...params} label={placeholder} />
      )}
      value={selected}
      onChange={(_, value) => onChange(value.map((v) => v.value).join(','))}
      sx={fieldSx || { width: '300px' }}
    />
  )
}

import { UseQueryResult } from '@tanstack/react-query'
import { APIErrorResponse, ResearcherCommitment } from '@/types'
import { useQueryList } from '@/hooks/api/useQueryList'

type UseResearcherCommitmentParams = {
  researcherId: number
}

export const useResearcherCommitment = ({
  researcherId,
}: UseResearcherCommitmentParams): UseQueryResult<
  ResearcherCommitment[],
  APIErrorResponse
> =>
  useQueryList({
    url: `plannings/researchers/${researcherId}/weekly_availability/`,
  })

import { unwrapResponse } from '@/utils'
import { apiClient } from '@/libs/apiClient'

/**
 * This file is responsible for communication on http level (via axios)
 * with Qandidates Django API.
 * It should contain reusable API calls. If react-query hook is the only place
 * where api endpoint is called, please don't move it here as it's overhead.
 */
export const getUserMe = () =>
  apiClient.get('/auth/users/me/').then(unwrapResponse)

import { memo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SearchDatePicker } from '@/components/inputs'
import dayjs from 'dayjs'

type SearchDateFieldProps = {
  searchStartValue?: string | null
  searchEndValue?: string
  onChangeStartDate: (value: string) => void
  onChangeEndDate: (value: string) => void
}

export const SearchDateField = memo(
  ({
    searchStartValue,
    searchEndValue,
    onChangeStartDate,
    onChangeEndDate,
  }: SearchDateFieldProps): JSX.Element => {
    const { t } = useTranslation()

    return (
      <Stack direction="row">
        <SearchDatePicker
          searchValue={searchStartValue}
          onChangeDate={onChangeStartDate}
          label={t('projects:columnNames.startDate')}
        />
        <Box sx={{ mx: 2, mt: 3 }}>
          <Typography>{t('projects:titles.to')}</Typography>
        </Box>
        <SearchDatePicker
          searchValue={searchEndValue}
          onChangeDate={onChangeEndDate}
          minDate={dayjs(searchStartValue)}
          label={t('projects:columnNames.endDate')}
        />
      </Stack>
    )
  }
)

import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
  ROUTES_PATH,
} from '@/constants'
import { setCookie } from 'react-use-cookie'

export const onAxiosRequestError = (error: string): void => {
  console.warn(error)

  setCookie(LOCAL_STORAGE_ACCESS_TOKEN_KEY, '', {
    days: 0,
  })
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY)
  sessionStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY)

  window.location.href = ROUTES_PATH.LOGIN
}

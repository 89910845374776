import { Dispatch, SetStateAction } from 'react'
import {
  FieldReportForm,
  FieldReportFormData,
} from '@/components/forms/FieldReportFormContainer/FieldReportForm'
import { useMutationWrapper, usePatchFieldReport } from '@/hooks'
import { VisitLog as VisitLogType } from '@/types'

export type FieldReportProps = {
  data: VisitLogType
  fieldReportId: number
  setUpdatedVisitLog: Dispatch<SetStateAction<VisitLogType>>
  onSuccessUpdate: () => void
}

export const FieldReportFormContainer = ({
  data,
  fieldReportId,
  setUpdatedVisitLog,
  onSuccessUpdate,
}: FieldReportProps): JSX.Element => {
  const {
    mutateAsync: updateFieldReport,
    isLoading: mutateFieldReportInProcess,
    error,
  } = useMutationWrapper(
    usePatchFieldReport.bind(null, {
      id: fieldReportId,
    })
  )

  const handleFieldReportSubmit = async ({
    field_impression,
    round_impression,
  }: FieldReportFormData) => {
    if (data.research_visit_event) {
      const fieldReportData = {
        field_impression,
        round_impression,
        research_visit_event_id: data.research_visit_event.id,
      }

      await updateFieldReport(fieldReportData, {
        successMessageKey: 'dataValidation:notifications.fieldReportUpdated',
        onSuccess: (fieldReport) => {
          setUpdatedVisitLog((visitLog) =>
            visitLog.research_visit_event
              ? {
                  ...visitLog,
                  research_visit_event: {
                    ...visitLog.research_visit_event,
                    field_report: {
                      ...fieldReport,
                    },
                  },
                }
              : {
                  ...visitLog,
                }
          )

          onSuccessUpdate()
        },
      })
    }
  }

  const defaultValues = {
    field_impression:
      data.research_visit_event?.field_report?.field_impression ?? '',
    round_impression:
      data.research_visit_event?.field_report?.round_impression ?? '',
  }

  return (
    <FieldReportForm
      defaultValues={defaultValues}
      onSubmit={handleFieldReportSubmit}
      isLoading={mutateFieldReportInProcess}
      apiErrorData={error?.data}
    />
  )
}

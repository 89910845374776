import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useMutationWrapper,
  usePutVisitLogApprove,
  useModal,
  useGenerateEventFieldReport,
} from '@/hooks'
import { useRequestExtraVisitEvent } from '@/hooks'
import { Box, Button, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { VisitLog } from '@/types'
import { ROUTES_PATH } from '@/constants'
import { getPath } from '@/utils'

export type VisitLogActionProps = {
  visitLog: VisitLog
  onDataUpdate?: (data: VisitLog) => void
}

export const VisitLogAction = ({
  visitLog,
  onDataUpdate,
}: VisitLogActionProps): JSX.Element => {
  const { t } = useTranslation('dataValidation')
  const navigate = useNavigate()
  const { close } = useModal()

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const { mutateAsync: approveValidation, isLoading: isApproving } =
    useMutationWrapper(
      usePutVisitLogApprove.bind(null, {
        id: visitLog.id,
      })
    )

  const { mutateAsync: requestExtraVisitEvent, isLoading: isRequesting } =
    useMutationWrapper(
      useRequestExtraVisitEvent.bind(null, {
        id: visitLog.id,
      })
    )

  const {
    mutateAsync: generateFieldReport,
    isLoading: isFieldReportGenerating,
  } = useMutationWrapper(
    useGenerateEventFieldReport.bind(null, {
      id: visitLog.research_visit_event?.id || 0,
    })
  )

  const handleApproveValidation = async () => {
    await approveValidation(
      { status_update_reason: '' },
      {
        successMessageKey: 'dataValidation:notifications.approved',
        onSuccess: (data) => {
          onDataUpdate && onDataUpdate(data)
        },
      }
    )

    setShowConfirmation(false)
  }

  const handleRequestExtraVisitEvent = async () => {
    await requestExtraVisitEvent(
      {
        status_update_reason: '',
      },
      {
        successMessageKey:
          'dataValidation:notifications.newResearchEventCreated',
        onSuccess: (data) => {
          onDataUpdate && onDataUpdate(data)
        },
      }
    )
  }

  const handleGenerateFieldReport = async () => {
    await generateFieldReport(undefined, {
      successMessageKey: 'dataValidation:notifications.fieldReportGenerated',
      onSuccess: (data) => {
        onDataUpdate &&
          visitLog.research_visit_event &&
          onDataUpdate({
            ...visitLog,
            research_visit_event: {
              ...visitLog.research_visit_event,
              research_area: {
                ...visitLog.research_visit_event.research_area,
                report_sharepoint_url: data.report_sharepoint_url,
              },
            },
          })
      },
    })
  }

  if (
    visitLog.status === 'extra_event_requested' &&
    visitLog.extra_research_visit_event
  ) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CheckCircleIcon color="secondary" />
        <Typography mr={2}>{t('visit-log.newResearchEventCreated')}</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            close()
            navigate(
              getPath(ROUTES_PATH.MONITORING_DETAIL, {
                id: +(visitLog.extra_research_visit_event || 0),
              })
            )
          }}
        >
          {t('visit-log.buttons.goToExtraRoundPage')}
        </Button>
      </Stack>
    )
  }

  if (showConfirmation) {
    return (
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography>{t('confirmation.label')}</Typography>
        <Button onClick={handleApproveValidation}>
          {t('confirmation.button')}
        </Button>
      </Stack>
    )
  }

  if (visitLog.status === 'invalid') {
    return (
      <>
        <LoadingButton
          variant="outlined"
          loading={isRequesting}
          onClick={() => handleRequestExtraVisitEvent()}
          sx={{ mr: 4 }}
        >
          <span>{t('visit-log.buttons.createNewResearchEvent')}</span>
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={isApproving}
          onClick={() => setShowConfirmation(true)}
          data-testid="approve-validation-button"
        >
          <span>{t('visit-log.buttons.approveValidation')}</span>
        </LoadingButton>
      </>
    )
  }

  return (
    <>
      {visitLog.status !== 'valid' && (
        <Box
          sx={{
            borderRadius: 90,
            padding: '7px 11px',
            width: 'max-content',
            backgroundColor: 'success.light',
            color: 'success.main',
            display: 'inline-flex',
            mr: 4,
          }}
          mr={2}
        >
          <Typography fontSize={12} lineHeight="16px">
            {t('approvedData')}
          </Typography>
        </Box>
      )}

      {visitLog.research_visit_event &&
        !visitLog.research_visit_event?.is_event_approved && (
          <LoadingButton
            variant="contained"
            loading={isFieldReportGenerating}
            onClick={handleGenerateFieldReport}
            sx={{ mr: 4 }}
          >
            <span>{t('visit-log.buttons.generateFieldReport')}</span>
          </LoadingButton>
        )}
    </>
  )
}

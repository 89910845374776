import { ResearchArea } from '@/types'

export const DEFAULT_RESEARCH_AREA: Omit<ResearchArea, 'id'> = {
  location_lat: undefined,
  location_long: undefined,
  location_name: '',
  required_number_of_researchers_for_morning: null,
  required_number_of_researchers_for_day: null,
  required_number_of_researchers_for_evening: null,
  required_number_of_researchers_for_night: null,
  protocols: [],
  researchers: [],
  graphics: [],
}

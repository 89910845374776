import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box } from '@mui/material'
import {
  ObservationLogFormData,
  ObservationLogFormActions,
} from '@/components/forms'
import {
  ControlledDateTimeField,
  ControlledTextField,
  ControlledSelect,
} from '@/components/inputs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormComponentProps } from '@/types'
import {
  BATS_BIRDS_COUNTING_METHOD_OPTIONS,
  BATS_SPECIE_OPTIONS,
  WIND_DIRECTION_OPTIONS,
} from './fieldsOptions'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'

const schema = yup.object({
  specie: yup.string().required(),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  wind_direction: yup.string().required(),
  direction: yup.string().required(),
  amount_per_15_min: yup.string().required(),
  notes: yup.string().max(256),
  observed_at: yup.date().required(),
})

export const BatsFlightPathForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const methods = useForm<ObservationLogFormData>({
    mode: 'onChange',
    defaultValues: {
      specie: '',
      amount: '',
      counting_method: '',
      wind_direction: '',
      direction: '',
      amount_per_15_min: '',
      notes: '',
      observed_at: '',
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  useApiErrorData<ObservationLogFormData>(apiErrorData, methods)

  return (
    <FormProvider {...methods}>
      <Stack mt={4} width="100%" alignItems="stretch" spacing={4}>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Box width="35%">
            <ControlledSelect
              control={methods.control}
              name="specie"
              label={t('observation-log.fieldLabels.specie')}
              options={BATS_SPECIE_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
          <Box width="35%">
            <ControlledTextField
              control={methods.control}
              name="amount"
              placeholder={t('observation-log.fieldLabels.amount')}
              type="number"
              errorMessagePrefix=""
            />
          </Box>
          <Box width="35%">
            <ControlledSelect
              control={methods.control}
              name="counting_method"
              label={t('observation-log.fieldLabels.countingMethod')}
              options={BATS_BIRDS_COUNTING_METHOD_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Box width="33%">
            <ControlledSelect
              control={methods.control}
              name="wind_direction"
              label={t('observation-log.fieldLabels.windDirection')}
              options={WIND_DIRECTION_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
          <Box width="33%">
            <ControlledSelect
              control={methods.control}
              name="direction"
              label={t('observation-log.fieldLabels.direction')}
              options={WIND_DIRECTION_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
        </Stack>
        <Box width="33%">
          <ControlledTextField
            control={methods.control}
            name="amount_per_15_min"
            label={t('observation-log.fieldLabels.amountPer15Min')}
            type="number"
            errorMessagePrefix=""
          />
        </Box>
        <ControlledTextField
          control={methods.control}
          name="notes"
          placeholder={t('observation-log.fieldLabels.notes')}
          multiline={true}
          rows={3}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          errorMessagePrefix=""
        />
        <ControlledDateTimeField
          control={methods.control}
          name="observed_at"
          label={t('observation-log.fieldLabels.observedAt')}
          errorMessagePrefix=""
        />

        <ObservationLogFormActions
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isLoading={isLoading}
          methods={methods}
        />
      </Stack>
    </FormProvider>
  )
}

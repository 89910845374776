import { createContext, ReactNode, useEffect, useReducer } from 'react'
import {
  AuthContextType,
  ActionMap,
  AuthState,
  AuthUser,
  SignInParams,
} from '@/types/auth'
import { isValidToken, setSession, unsetSession } from '@/utils/jwt'
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '@/constants'
import { getUserMe } from '@/libs'

const INITIALIZE = 'INITIALIZE'
const SIGN_IN = 'SIGN_IN'
const SIGN_OUT = 'SIGN_OUT'

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [SIGN_IN]: {
    user: AuthUser
  }
  [SIGN_OUT]: undefined
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const AuthStateReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      }
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }

    default:
      return state
  }
}

const AuthContext = createContext<AuthContextType | null>(null)

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(AuthStateReducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)

        if (accessToken && isValidToken(accessToken)) {
          const user = await getUserMe()

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          })

          return
        }

        if (accessToken) {
          unsetSession()
        }

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      } catch (err) {
        console.error(err)
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    }

    initialize()
  }, [])

  const signIn = async ({
    access,
    refresh,
    isRemember = true,
  }: SignInParams) => {
    setSession(access, refresh, isRemember)

    const user = await getUserMe()

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    })
  }

  const signOut = async () => {
    unsetSession()
    dispatch({ type: SIGN_OUT })
  }

  // eslint-disable-next-line no-console
  const resetPassword = (email: string) => console.log(email)

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }

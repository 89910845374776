import { useMemo } from 'react'
import { AutocompleteOption, ControlledAutocomplete } from '@/components/inputs'
import { useTranslation } from 'react-i18next'
import {
  ControlledFieldProps,
  ProjectSpecie,
  ResearchType,
  Specie,
} from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { UseFormSetValue } from 'react-hook-form'
import { ResearchTypesSelectorFormData } from '@/features/projectExtend/ProjectSpeciesStep/ProjectSpeciesFormContainer/ProjectSpeciesForm'

export const getOptionLabel = (
  projectSpecies: ResearchType[],
  selectedProjectSpecies?: Omit<ProjectSpecie, 'specie'>[],
  specie?: Specie
): AutocompleteOption[] =>
  projectSpecies.map((projectSpecie) => {
    const specieId = specie?.id ?? null

    const projectResearchType = (selectedProjectSpecies ?? []).find(
      (obj) => obj.research_type?.id === projectSpecie.id
    )

    return {
      value: projectSpecie.id.toString(),
      title: projectSpecie.name,
      id: projectResearchType?.id ?? null,
      specie_id: projectResearchType?.specie_id ?? specieId,
    }
  })

export type ResearchTypesAutocompleteProps<T extends FieldValues> = {
  researchTypes?: ResearchType[]
  selectedValues: Omit<ProjectSpecie, 'specie'>[]
  specie: Specie
  setValue: UseFormSetValue<ResearchTypesSelectorFormData>
} & Pick<ControlledFieldProps<T>, 'name' | 'control'>

export const ResearchTypesAutocomplete = <T extends FieldValues>({
  name,
  control,
  researchTypes,
  selectedValues,
  specie,
}: ResearchTypesAutocompleteProps<T>): JSX.Element => {
  const { t } = useTranslation('forms')

  const options = useMemo((): AutocompleteOption[] => {
    if (!researchTypes?.length) {
      return []
    }

    return getOptionLabel(researchTypes, selectedValues, specie)
  }, [researchTypes, selectedValues, specie])

  return (
    <ControlledAutocomplete
      name={name}
      control={control}
      options={options}
      multiple={true}
      disableClearable={true}
      textFieldProps={{
        placeholder: t('placeholders.researchType'),
        label: t('labels.researchType'),
        fullWidth: true,
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.value + option.title}>
          {option.title}
        </li>
      )}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : `${option.title}`
      }
    />
  )
}

import { Dispatch, SetStateAction } from 'react'
import {
  BatsForm,
  BatsFeedingAreaForm,
  BatsFlightPathForm,
  CourtshipTerritoryForm,
  BirdsBreedingForm,
  MammalsForm,
  AmphibianFishForm,
  ReptilesForm,
  InsectsForm,
} from '@/components/forms/ObservationLogFormContainer'
import {
  PLACE_FOUND_OPTION_VALUE,
  PLACE_NOT_FOUND_OPTION_VALUE,
} from '@/components/forms/ObservationLogFormContainer/fieldsOptions'
import { useMutationWrapper, usePatchObservationLog } from '@/hooks'
import { ObservationLog, PatchObservationLog } from '@/types'
import dayjs, { Dayjs } from 'dayjs'

export type ObservationLogFormData = Partial<
  Omit<
    PatchObservationLog,
    | 'amount'
    | 'amount2'
    | 'amount3'
    | 'amount4'
    | 'amount_per_15_min'
    | 'is_place_found'
    | 'first_fledgling_time'
    | 'observed_at'
    | 'research_visit_event_id'
    | 'research_observation_log_type_id'
  >
> & {
  amount?: string
  amount2?: string
  amount3?: string
  amount4?: string
  amount_per_15_min?: string
  is_place_found?: string
  first_fledgling_time?: Dayjs
  observed_at?: Dayjs
}

export type ObservationLogFormContainerProps = {
  data: ObservationLog
  setUpdatedObservationLog: Dispatch<SetStateAction<ObservationLog>>
  onSuccessUpdate: () => void
}

export const ObservationLogFormContainer = ({
  data,
  setUpdatedObservationLog,
  onSuccessUpdate,
}: ObservationLogFormContainerProps): JSX.Element => {
  const {
    mutateAsync: updateObservationLog,
    isLoading: mutateObservationLogInProcess,
    error,
  } = useMutationWrapper(
    usePatchObservationLog.bind(null, {
      id: data.id,
    })
  )

  const handleObservationLogSubmit = async (
    formData: ObservationLogFormData
  ) => {
    if (data.research_visit_event && data.research_observation_log_type) {
      const observationLogData = {
        ...formData,
        amount: !!formData.amount ? Number(formData.amount) : null,
        amount2: !!formData.amount2 ? Number(formData.amount2) : null,
        amount3: !!formData.amount3 ? Number(formData.amount3) : null,
        amount4: !!formData.amount4 ? Number(formData.amount4) : null,
        research_visit_event_id: data.research_visit_event.id,
        research_observation_log_type_id: data.research_observation_log_type.id,
        is_place_found: formData.is_place_found === PLACE_FOUND_OPTION_VALUE,
        recording_number: formData.recording_number || '',
        amount_per_15_min: Number(formData.amount_per_15_min),
        is_sound_made: !!formData.recording_number,
        observed_at:
          formData.observed_at && dayjs(formData.observed_at).utc().format(),
        first_fledgling_time:
          formData.first_fledgling_time &&
          dayjs(formData.first_fledgling_time).utc().format(),
      }

      await updateObservationLog(observationLogData, {
        successMessageKey:
          'observationLogs:notifications.observationLogChanged',
        onSuccess: (data) => {
          setUpdatedObservationLog(data)
          onSuccessUpdate()
        },
      })
    }
  }

  const defaultValues = {
    specie: data?.specie ?? '',
    specie2: data?.specie2 ?? '',
    specie3: data?.specie3 ?? '',
    specie4: data?.specie4 ?? '',
    other_specie: data?.other_specie ?? '',
    amount: data?.amount?.toString() ?? '',
    amount2: data?.amount2?.toString() ?? '',
    amount3: data?.amount3?.toString() ?? '',
    amount4: data?.amount4?.toString() ?? '',
    counting_method: data?.counting_method ?? '',
    counting_method2: data?.counting_method2 ?? '',
    counting_method3: data?.counting_method3 ?? '',
    counting_method4: data?.counting_method4 ?? '',
    behaviour: data?.behaviour ?? '',
    other_behaviour: data?.other_behaviour ?? '',
    behaviour_notes: data?.behaviour_notes ?? '',
    direction: data?.direction ?? '',
    wind_direction: data?.wind_direction ?? '',
    recording_number: data?.recording_number ?? '',
    amount_per_15_min: data?.amount_per_15_min?.toString() ?? '',
    is_place_found: data?.is_place_found
      ? PLACE_FOUND_OPTION_VALUE
      : data?.is_place_found === false
      ? PLACE_NOT_FOUND_OPTION_VALUE
      : undefined,
    place_type: data?.place_type ?? '',
    first_fledgling_time: data?.first_fledgling_time
      ? dayjs(data.first_fledgling_time)
      : undefined,
    place_type_notes: data?.place_type_notes ?? '',
    notes: data?.notes ?? '',
    images: data?.photos ?? [],
    observed_at: data?.observed_at ? dayjs(data.observed_at) : undefined,
    address: data?.address ?? '',
  }

  const props = {
    defaultValues,
    onSubmit: handleObservationLogSubmit,
    isLoading: mutateObservationLogInProcess,
    apiErrorData: error?.data,
  }

  switch (data.research_observation_log_type.code) {
    case 'bats':
      return <BatsForm {...props} />
    case 'bats_feeding_area':
      return <BatsFeedingAreaForm {...props} />
    case 'bats_flight_path':
      return <BatsFlightPathForm {...props} />
    case 'courtship_territory':
      return <CourtshipTerritoryForm {...props} />
    case 'birds_breeding':
      return <BirdsBreedingForm {...props} />
    case 'mammals':
      return <MammalsForm {...props} />
    case 'amphibian_fish':
      return <AmphibianFishForm {...props} />
    case 'reptiles':
      return <ReptilesForm {...props} />
    case 'insects':
      return <InsectsForm {...props} />
    default:
      return <></>
  }
}

import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { Grid, AppBar as MuiAppBar, Stack, Container } from '@mui/material'
import NavbarLanguagesDropdown from './NavbarLanguagesDropdown'
import NavbarUserDropdown from './NavbarUserDropdown'
import { NavbarMenu } from './NavbarMenu'

const AppBar = styled(MuiAppBar)`
  background: ${({ theme }) => theme.header.background};
  color: ${({ theme }) => theme.header.color};
`

const Navbar = (): JSX.Element => (
  <AppBar position="sticky" elevation={0}>
    <Container maxWidth="xl">
      <Grid container={true} alignItems="center">
        <Grid item={true}>
          <NavbarMenu />
        </Grid>
        <Grid item={true} xs={true} />
        <Grid item={true}>
          <Stack spacing={4} direction="row">
            <NavbarLanguagesDropdown />
            <NavbarUserDropdown />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </AppBar>
)

export default withTheme(Navbar)

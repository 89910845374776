import { DataValidationEnvironmentalData, VisitLog } from '@/types'

export type FormatEnvironmentalDataProps = Pick<
  VisitLog,
  | 'start_temperature'
  | 'end_temperature'
  | 'wind_force'
  | 'wind_direction'
  | 'cloud_cover'
  | 'precipitation'
  | 'precipitation_duration'
  | 'invalid_variables'
>

export const formatEnvironmentalData = (
  data: FormatEnvironmentalDataProps
): DataValidationEnvironmentalData => ({
  start_temperature: {
    value: data.start_temperature,
    has_error: data.invalid_variables?.includes('start_temperature') ?? false,
  },
  end_temperature: {
    value: data.end_temperature,
    has_error: data.invalid_variables?.includes('end_temperature') ?? false,
  },
  cloud_cover: {
    value: data.cloud_cover,
    has_error: data.invalid_variables?.includes('cloud_cover') ?? false,
  },
  wind_force: {
    value: data.wind_force,
    has_error: data.invalid_variables?.includes('wind_force') ?? false,
  },
  wind_direction: {
    value: data.wind_direction,
    has_error: data.invalid_variables?.includes('wind_direction') ?? false,
  },
  precipitation: {
    value: data.precipitation,
    has_error: data.invalid_variables?.includes('max_precipitation') ?? false,
  },
  precipitation_duration: {
    value: data.precipitation_duration,
    has_error: data.invalid_variables?.includes('max_precipitation') ?? false,
  },
})

import * as yup from 'yup'

export const roundSchema = yup.object({
  startDatetime: yup.date().typeError('invalid_date').optional().nullable(),
  endDatetime: yup.date().typeError('invalid_date').optional().nullable(),
  researchers: yup.array().of(
    yup.object({
      value: yup.string(),
      title: yup.string(),
    })
  ),
})

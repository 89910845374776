import { ReactChildren } from '@/types'
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material'

export type LoadingButtonProps = {
  isLoading?: boolean
} & ReactChildren &
  ButtonProps

/**
 * @deprecated Use LoadingButton from '@mui/lab'
 */
export const LoadingButton = ({
  isLoading,
  children,
  ...props
}: LoadingButtonProps): JSX.Element => (
  <Button {...props} disabled={isLoading ?? props.disabled}>
    {isLoading && (
      <CircularProgress
        size="1.2rem"
        sx={{ position: 'absolute' }}
        color="secondary"
      />
    )}

    <Box visibility={isLoading ? 'hidden' : 'visible'}>{children}</Box>
  </Button>
)

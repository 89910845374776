import { ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
})

interface ContextProvidersProps {
  children: ReactNode
  client?: QueryClient
}

export const ReactQueryProvider = ({
  children,
  client,
}: ContextProvidersProps): JSX.Element => (
  <QueryClientProvider client={client ?? queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

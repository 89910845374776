import { EventResearcher, Plannings } from '@/types'
import { formatEventResearcherName } from '@/utils'
import { Autocomplete, TextField } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type ResearcherAutocompleteType = {
  label: string
  value: number
} | null

export type ResearcherAutocompleteProps = {
  plannings: Plannings | undefined
  value: ResearcherAutocompleteType
  setValue: Dispatch<SetStateAction<ResearcherAutocompleteType>>
}

const collectResearchersFromPlanningsToAutocompleteOptions = (
  plannings: Plannings | undefined
): ResearcherAutocompleteType[] => {
  if (!plannings) return []

  const allResearchers: EventResearcher[] = []

  for (const planning of plannings) {
    for (const researchArea of planning.research_areas) {
      for (const research_visits_event of researchArea.research_visits_events) {
        for (const researcher of research_visits_event.researchers) {
          allResearchers.push(researcher)
        }
      }
    }
  }

  const uniqueResearchers = Array.from(
    new Set(allResearchers.map((item) => JSON.stringify(item)))
  ).map((item) => JSON.parse(item))

  return uniqueResearchers.map((researcher) => ({
    label: formatEventResearcherName(researcher),
    value: researcher.id,
  }))
}

export const ResearcherAutocomplete = ({
  plannings,
  value,
  setValue,
}: ResearcherAutocompleteProps): JSX.Element => {
  const { t } = useTranslation('planning')
  const [inputValue, setInputValue] = useState('')
  const researchers =
    collectResearchersFromPlanningsToAutocompleteOptions(plannings)

  return (
    <Autocomplete
      options={researchers}
      renderInput={(params) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextField {...params} label={t('placeholders.filterByResearcher')} />
      )}
      value={value}
      onChange={(_event, newValue: ResearcherAutocompleteType) => {
        setValue(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      sx={{ width: 300 }}
    />
  )
}

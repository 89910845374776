import { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'

import dayjs, { Dayjs } from 'dayjs'
import { DATE_FORMAT, DATE_FORMAT_YMD } from '@/constants'

type SearchDatePickerProps = {
  searchValue?: string | null
  onChangeDate: (value: string) => void
  minDate?: Dayjs
  label: string
}

export const SearchDatePicker = ({
  searchValue,
  onChangeDate,
  minDate,
  label,
}: SearchDatePickerProps): JSX.Element => {
  const [value, setValue] = useState<Dayjs | null>(null)

  const changeValue = (newValue: Dayjs | null) => {
    if (
      dayjs(newValue).format(DATE_FORMAT_YMD) !==
      dayjs(searchValue).format(DATE_FORMAT_YMD)
    ) {
      onChangeDate(dayjs(newValue).format(DATE_FORMAT_YMD))
      setValue(newValue)
    }
    if (newValue === null) {
      onChangeDate('')
    }
  }

  useEffect(() => {
    if (!!searchValue) {
      setValue(dayjs(searchValue))
    } else {
      setValue(null)
    }
  }, [searchValue])

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={changeValue}
      renderInput={(params) => (
        <TextField sx={{ maxWidth: '180px' }} {...params} />
      )}
      inputFormat={DATE_FORMAT}
      OpenPickerButtonProps={{
        sx: { color: 'green.500' },
      }}
      PaperProps={{
        sx: {
          boxShadow: 2,
        },
      }}
      minDate={minDate ? minDate : undefined}
    />
  )
}

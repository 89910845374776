import { APIErrorResponse, Planning } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '@/hooks/api/useQueryList'
import { QUERY_KEYS } from '@/constants'

type UsePlanningsParams = {
  projectId: number
  startDate?: string
  endDate?: string
  search?: string
  enabled?: boolean
}

export const useProjectPlanning = ({
  projectId,
  startDate,
  endDate,
  search,
  enabled = true,
}: UsePlanningsParams): UseQueryResult<Planning, APIErrorResponse> => {
  const params = {
    ...(startDate && { date_start: startDate }),
    ...(endDate && { date_end: endDate }),
    ...(search && { search }),
  }

  return useQueryList({
    key: [...QUERY_KEYS.PLANNING_EVENTS, projectId, params],
    url: `/plannings/${projectId}/`,
    params,
    options: {
      enabled,
      staleTime: Infinity,
    },
  })
}

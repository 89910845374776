import { Button, Divider, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

export type FooterActionsProps = {
  onCancel: () => void
  mutateInProcess?: boolean
  isNextStepDisabled?: boolean
}

export const FooterActions = ({
  onCancel,
  mutateInProcess,
  isNextStepDisabled,
}: FooterActionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Divider sx={{ mx: -14, my: 6, borderColor: 'border.main' }} />

      <Stack direction="row" spacing={6}>
        <Button variant="text" onClick={onCancel}>
          {t('projects:buttons.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          loading={mutateInProcess}
          disabled={isNextStepDisabled}
          variant="contained"
        >
          <span>{t('projects:buttons.confirmInformation')}</span>
        </LoadingButton>
      </Stack>
    </>
  )
}

import { Helmet } from 'react-helmet-async'
import { CacheProvider } from '@emotion/react'

import './i18n'

import { createEmotionCache } from './utils/createEmotionCache'

import { ContextProviders } from '@/contexts/ContextProviders'
import { DevSupport } from '@react-buddy/ide-toolbox'
import { ComponentPreviews, useInitial } from '@/dev'
import { CssBaseline } from '@mui/material'
import { GlobalStyle, ModalWrapper } from '@/components'
import { useRoutes } from 'react-router-dom'
import { routes } from '@/routes'
import { useTranslation } from 'react-i18next'

const clientSideEmotionCache = createEmotionCache()

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes)
  const { i18n } = useTranslation()

  return (
    <CacheProvider value={emotionCache}>
      <ContextProviders>
        <Helmet titleTemplate="%s | Teia.nl">
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&language=${i18n.language}&libraries=places&callback=Function.prototype`}
            async={true}
          />
        </Helmet>

        <CssBaseline />
        <GlobalStyle />

        <DevSupport
          ComponentPreviews={ComponentPreviews}
          useInitialHook={useInitial}
        >
          {content as JSX.Element}
        </DevSupport>
        <ModalWrapper />
      </ContextProviders>
    </CacheProvider>
  )
}

export default App

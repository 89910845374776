import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material'
import { ReactChildren } from '@/types'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { ReactNode } from 'react'

export type CardLayoutProps = {
  title: string
  count?: number
  hasHeaderDivider?: boolean
  headerAction?: ReactNode
  footerAction?: ReactNode
  cardSx?: SxProps<Theme>
  dataTestId?: string
} & ReactChildren

export const CardLayout = ({
  title,
  count,
  hasHeaderDivider = true,
  headerAction,
  children,
  footerAction,
  cardSx,
  dataTestId = 'card-container',
}: CardLayoutProps): JSX.Element => (
  <Card
    variant="outlined"
    sx={{
      ...cardSx,
    }}
    data-testid={dataTestId}
  >
    <CardHeader
      action={headerAction}
      title={
        <>
          {title} {count && <>( {count} )</>}
        </>
      }
    />
    {hasHeaderDivider && <Divider variant="middle" sx={{ mx: 4, my: 1 }} />}

    <CardContent>
      <Box pt={4}>{children}</Box>
    </CardContent>

    {footerAction && (
      <>
        <Divider sx={{ mx: -5, my: 4, borderColor: 'border.main' }} />
        <CardActions> {footerAction} </CardActions>
      </>
    )}
  </Card>
)

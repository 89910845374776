import { TestContext } from 'yup'

import {
  OPTIONAL_RECORDING_NUMBER_SPECIES,
  PLACE_TYPE_MATERNITY_HOME,
} from './fieldsOptions'

import translation from 'i18next'

export const validateRecordingNumber = (
  value: string | undefined,
  context: TestContext
) => {
  const specie = context.parent.specie
  const placeType = context.parent.place_type

  if (placeType === PLACE_TYPE_MATERNITY_HOME && value === undefined) {
    return context.createError({
      message: translation.t('errors:validation.requiredRecordingForMaternity'),
    })
  }

  if (
    specie &&
    !OPTIONAL_RECORDING_NUMBER_SPECIES.includes(specie) &&
    value === undefined
  ) {
    return context.createError({
      message: translation.t('errors:validation.requiredRecordingForSpecie', {
        specie,
      }),
    })
  }

  return true
}

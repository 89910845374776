import { APIErrorResponse, ProjectSpecie } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '@/hooks/api/useQueryList'
import { QueryKey } from '@tanstack/query-core'

export const useProjectSpecies = (
  projectId: number,
  key?: QueryKey
): UseQueryResult<ProjectSpecie[], APIErrorResponse> =>
  useQueryList({
    url: `/projects/${projectId}/species/`,
    options: {
      enabled: !!projectId,
      staleTime: Infinity,
    },
    ...(key && { key }),
  })

import jwtDecode from 'jwt-decode'
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '@/constants'
import { apiClient, setAuthHeaders } from '@/libs'

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

export const setSession = (
  accessToken: string,
  refreshToken?: string,
  isRemember?: boolean
) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken)
  setAuthHeaders(accessToken)

  if (!refreshToken) {
    return
  }

  if (isRemember) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken)
  } else {
    sessionStorage.setItem(
      LOCAL_STORAGE_REFRESH_TOKEN_KEY,
      JSON.stringify(refreshToken)
    )
  }
}

export const unsetSession = () => {
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY)
  delete apiClient.defaults.headers.common.Authorization
}

import { Box, Stack } from '@mui/material'
import { PageBodyLayout } from '@/components'
import { usePlanningData } from '@/features/planning/hooks'
import { OpenAvailabilityModalButton } from '@/features'
import { Calendar } from './Calendar'
import { useModal } from '@/hooks'
import { ResearcherAutocomplete } from './ResearcherAutocomplete'
import { DailyPlanningModal } from '@/features/planning/DailyPlanningModal'

export type ManagerPlanningProps = {
  projectId?: number
  showEditAvailability?: boolean
}

export const ManagerPlanning = ({
  projectId,
  showEditAvailability = false,
}: ManagerPlanningProps): JSX.Element => {
  const { open } = useModal()

  const { plannings, setCalendarDateRange, researcher, setResearcher } =
    usePlanningData({
      projectId,
      hasLimit: false,
    })

  const handleOpenDailyPlanningEdit = (date: Date | null) => {
    if (plannings && date) {
      open(
        <DailyPlanningModal
          day={date}
          plannings={plannings}
          canGoToRoundDetail={true}
        />
      )
    }
  }

  return (
    <Stack flex={1} sx={{ overflowY: 'auto' }}>
      <PageBodyLayout minWidth="900px">
        <Box pb={10}>
          <Calendar
            plannings={plannings ?? []}
            setCalendarDateRange={setCalendarDateRange}
            rightBar={
              <Stack direction="row" spacing={6.75}>
                {!projectId && (
                  <ResearcherAutocomplete
                    plannings={plannings}
                    value={researcher}
                    setValue={setResearcher}
                  />
                )}

                {showEditAvailability && <OpenAvailabilityModalButton />}

                {/*{projectId && <EditPlanningButton projectId={projectId} />}*/}
              </Stack>
            }
            onDayClick={handleOpenDailyPlanningEdit}
          />
        </Box>
      </PageBodyLayout>
    </Stack>
  )
}

import {
  Box,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material'
import { VisitLogStatus } from '@/types'

type IndicatorStatus = VisitLogStatus | 'empty'

const getStatusColor = (status: IndicatorStatus): string => {
  switch (status) {
    case 'valid':
      return 'success.main'
    case 'valid_without_field_report':
    case 'approved_without_field_report':
      return 'purple.main'
    case 'approved':
      return 'warning.main'

    case 'empty':
      return 'grey.500'

    case 'extra_event_requested':
      return 'info.main'

    default:
      return 'red'
  }
}

export type IndicatorProps = {
  status?: IndicatorStatus
  name: string
  isSmall?: boolean
  tooltipText?: string
  tooltipPlacement?: TooltipProps['placement']
  sxProps?: SxProps<Theme>
}

export const Indicator = ({
  isSmall = true,
  status = 'empty',
  name,
  tooltipText,
  tooltipPlacement = 'top',
  sxProps = {},
}: IndicatorProps): JSX.Element => (
  <Stack direction="row" alignItems="center" spacing={3} sx={sxProps}>
    <Tooltip title={tooltipText} arrow={true} placement={tooltipPlacement}>
      <Box
        bgcolor={getStatusColor(status)}
        sx={{
          minWidth: isSmall ? '6px' : '9px',
          height: isSmall ? '6px' : '9px',
          borderRadius: '50%',
        }}
      />
    </Tooltip>
    <Typography variant="body2" color="grey.600">
      {name}
    </Typography>
  </Stack>
)

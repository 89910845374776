import { FallbackProps } from 'react-error-boundary'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <div role="alert">
    <p>Global error boundary catch an error: </p>
    <pre>{error.message}</pre>
    <pre>{error.stack}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
)

import { Button, Link, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { getWeeksInYear, getDateOfISOWeek } from '@/utils/date'
import { useTranslation } from 'react-i18next'
import { ArrowRight } from 'react-feather'

export type WeekSelectionProps = {
  onSelect: (weekNumber: number) => void
}

export const WeekSelection = ({
  onSelect,
}: WeekSelectionProps): JSX.Element => {
  const { t } = useTranslation('planning')

  dayjs.extend(localeData)
  const amountOfWeeks = getWeeksInYear(dayjs().get('year'))

  return (
    <Stack data-testid="week-list">
      {[...Array(amountOfWeeks)].map((_, index) => {
        const weekNumber = index + 1
        const weekStartDate = getDateOfISOWeek(
          weekNumber,
          dayjs().get('year')
        ).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })

        return (
          <Stack
            key={weekNumber}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            px={2}
            mx={-2}
            borderRadius={1}
            sx={{
              cursor: 'pointer',
              ':hover': {
                bgcolor: 'grey.50',
                '.MuiTypography-root': { fontWeight: 600 },
                '.MuiButton-root': {
                  mr: 2,
                },
              },
            }}
            onClick={() => onSelect(weekNumber)}
          >
            <Typography>
              {t('availability.weekItem', {
                week: weekNumber,
                date: weekStartDate,
              })}
            </Typography>

            <Button
              variant="text"
              sx={{
                px: 0,
                transition: 'margin 0.25s',
                mr: 0,
                hover: { bgcolor: 'transparent' },
              }}
              endIcon={<ArrowRight size={16} />}
              component={Link}
            >
              {t('availability.openWeekButton')}
            </Button>
          </Stack>
        )
      })}
    </Stack>
  )
}

import { useModal } from '@/hooks'
import {
  ResearchAreaGraphicsMap,
  ResearchAreaGraphicsMapProps,
} from '@/components/forms/ResearchAreaForm/ResearchAreaGraphicsMap'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

export const DrawResearchAreaGraphics = (
  researchAreaGraphicsMapProps: ResearchAreaGraphicsMapProps
) => {
  const { open } = useModal()
  const { t } = useTranslation('forms')

  return (
    <Button
      onClick={() =>
        open(<ResearchAreaGraphicsMap {...researchAreaGraphicsMapProps} />)
      }
      startIcon={<EditOutlinedIcon />}
      sx={{ height: '100%' }}
      variant="outlined"
    >
      {researchAreaGraphicsMapProps.graphics &&
      researchAreaGraphicsMapProps.graphics.length
        ? t('projectArea.editArea')
        : t('projectArea.drawArea')}
    </Button>
  )
}

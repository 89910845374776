import { Outlet, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Paper } from '@mui/material'
import { ReactComponent as Logo } from '../vendor/logo.svg'
import { Helmet } from 'react-helmet-async'
import { ReactNode } from 'react'
import { theme } from '@/theme'

const Root = styled.div`
  max-width: 520px;
  height: 100vh;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 186px;
  height: 57px;
  margin: 0 0 72px 0;
  :hover {
    cursor: pointer;
  }
`

const Wrapper = styled(Paper)`
  border: ${({ theme }) => `1px solid ${theme.palette.grey[200]}`};
  border-radius: 20px;
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

interface AuthType {
  title?: string
  children?: ReactNode
}

export const AuthLayout = ({
  title = 'Teia',
  children,
}: AuthType): JSX.Element => {
  const navigate = useNavigate()
  const handleBrandClick = () => navigate('/')

  return (
    <Box bgcolor={theme.palette.green[50]} width="100%">
      <Root>
        <Brand onClick={handleBrandClick} />
        <Wrapper>
          <Helmet title={title} />
          {children}
          <Outlet />
        </Wrapper>
      </Root>
    </Box>
  )
}

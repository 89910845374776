import { Checkbox, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { AvailabilityFormData, FORM_KEY_DATE_FORMAT } from './AvailabilityModal'
import { UseFormReset, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getDateOfDayInWeek } from '@/utils/date'
import dayjs from 'dayjs'
import { AMOUNT_OF_DAYS_WEEK } from '@/constants'

export type CheckAllProps = {
  weekNumber: number
  reset: UseFormReset<AvailabilityFormData>
  watch: UseFormWatch<AvailabilityFormData>
}

export const CheckAll = ({
  weekNumber,
  reset,
  watch,
}: CheckAllProps): JSX.Element => {
  const { t } = useTranslation('planning')
  const [allChecked, setAllChecked] = useState(false)

  const formValues = watch()

  const handleCheckAll = (checked: boolean) => {
    const defaultValues = { ...formValues } as AvailabilityFormData

    for (let i = 0; i < AMOUNT_OF_DAYS_WEEK; i++) {
      const dateOfDay = getDateOfDayInWeek(weekNumber, i)
      const formIndex = dayjs(dateOfDay).format(FORM_KEY_DATE_FORMAT)

      defaultValues.availability[formIndex] = {
        availableForMorningRound: checked,
        availableForDayRound: checked,
        availableForEveningRound: checked,
      }
    }

    reset(defaultValues)
  }

  useEffect(() => {
    const allChecked = Array(AMOUNT_OF_DAYS_WEEK)
      .fill(1)
      .every((_, index) => {
        const dateOfDay = getDateOfDayInWeek(weekNumber, index)
        const formIndex = dayjs(dateOfDay).format(FORM_KEY_DATE_FORMAT)

        const day = formValues.availability?.[formIndex]

        return (
          day?.availableForEveningRound &&
          day?.availableForMorningRound &&
          day?.availableForDayRound
        )
      })

    setAllChecked(allChecked)
  }, [formValues])

  return (
    <FormControlLabel
      control={
        <Checkbox
          value={allChecked}
          checked={allChecked}
          onChange={(_, checked) => handleCheckAll(checked)}
        />
      }
      label={
        allChecked ? t('availability.uncheckAll') : t('availability.checkAll')
      }
    />
  )
}

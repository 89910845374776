import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseVisitLogsKey, getUseVisitLogKey } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import { APIErrorResponse, VisitLog, PostRequestExtraVisitEvent } from '@/types'
import { QUERY_KEYS } from '@/constants'

export type UseRequestExtraVisitEvent = {
  id: number
}

export const useRequestExtraVisitEvent = ({
  id,
}: UseRequestExtraVisitEvent): UseMutationResult<
  VisitLog,
  APIErrorResponse,
  PostRequestExtraVisitEvent
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const visitLogsListKey = getUseVisitLogsKey()
  const visitLogItemKey = getUseVisitLogKey(id)

  return useMutation(
    (params: PostRequestExtraVisitEvent) =>
      client
        .post(`/plannings/visit_logs/${id}/request_extra_visit_event/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data: VisitLog) => {
        const queryKey = [
          ...QUERY_KEYS.PLANNING_EVENTS,
          data.research_visit_event?.research_area.project.id,
        ]

        queryClient.invalidateQueries(queryKey)
        queryClient.invalidateQueries(visitLogsListKey)
        queryClient.invalidateQueries(visitLogItemKey)
      },
    }
  )
}

import { ModalLayout } from '@/components'
import { ResearchAreaSpecieEvents } from './ResearchAreaEvent'
import { Plannings } from '@/types'
import dayjs from 'dayjs'
import { DATE_TEXT_FULL_FORMAT } from '@/constants'
import { filterPlanningByDay } from '@/features/planning/utils'

export type DailyPlanningModalProps = {
  plannings: Plannings
  day: Date
  canGoToRoundDetail?: boolean
}

export const DailyPlanningModal = ({
  plannings,
  day,
  canGoToRoundDetail = false,
}: DailyPlanningModalProps): JSX.Element => {
  const dailyPlannings = filterPlanningByDay({ plannings, date: day })

  return (
    <ModalLayout
      title={dayjs(day).format(DATE_TEXT_FULL_FORMAT)}
      cardContentSx={{
        maxHeight: '70vh',
        overflowY: 'auto',
        minWidth: { xs: 'auto', md: '920px' },
      }}
    >
      {dailyPlannings.map((planning, index) => (
        <ResearchAreaSpecieEvents
          index={index}
          key={planning.id}
          planning={planning}
          canGoToRoundDetail={canGoToRoundDetail}
        />
      ))}
    </ModalLayout>
  )
}

import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box } from '@mui/material'
import {
  ObservationLogFormData,
  ObservationLogFormActions,
} from '@/components/forms'
import {
  ControlledTextField,
  ControlledDateTimeField,
  ControlledSelect,
} from '@/components/inputs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'
import { FormComponentProps } from '@/types'
import {
  INSECTS_COUNTING_METHOD_OPTIONS,
  INSECTS_GEDRAG_OPTIONS,
  OTHER_GEDRAG_OPTION_VALUE,
} from './fieldsOptions'

const schema = yup.object({
  specie: yup.string().required(),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  behaviour: yup.string().required(),
  other_behaviour: yup.string().when('behaviour', {
    is: OTHER_GEDRAG_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  behaviour_notes: yup.string().max(256),
  observed_at: yup.date().required(),
})

export const InsectsForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const methods = useForm<ObservationLogFormData>({
    mode: 'onChange',
    defaultValues: {
      specie: '',
      amount: '',
      counting_method: '',
      behaviour: '',
      other_behaviour: '',
      behaviour_notes: '',
      observed_at: '',
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  useApiErrorData<ObservationLogFormData>(apiErrorData, methods)

  return (
    <FormProvider {...methods}>
      <Stack mt={4} width="100%" alignItems="stretch" spacing={4}>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <ControlledTextField
            control={methods.control}
            name="specie"
            placeholder={t('observation-log.fieldLabels.specie')}
            errorMessagePrefix=""
          />
          <ControlledTextField
            control={methods.control}
            name="amount"
            placeholder={t('observation-log.fieldLabels.amount')}
            type="number"
            errorMessagePrefix=""
          />
          <ControlledSelect
            control={methods.control}
            name="counting_method"
            label={t('observation-log.fieldLabels.countingMethod')}
            options={INSECTS_COUNTING_METHOD_OPTIONS}
            errorMessagePrefix=""
          />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Box width="33%">
            <ControlledSelect
              control={methods.control}
              name="behaviour"
              label={t('observation-log.fieldLabels.behaviour')}
              options={INSECTS_GEDRAG_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
          {methods.watch('behaviour') === OTHER_GEDRAG_OPTION_VALUE && (
            <Box width="33%">
              <ControlledTextField
                control={methods.control}
                name="other_behaviour"
                placeholder={t('observation-log.fieldLabels.otherBehaviour')}
                errorMessagePrefix=""
              />
            </Box>
          )}
        </Stack>
        <ControlledTextField
          control={methods.control}
          name="behaviour_notes"
          placeholder={t('observation-log.fieldLabels.behaviourNotes')}
          multiline={true}
          rows={3}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          errorMessagePrefix=""
        />
        <ControlledDateTimeField
          name="observed_at"
          control={methods.control}
          label={t('observation-log.fieldLabels.observedAt')}
          errorMessagePrefix=""
        />

        <ObservationLogFormActions
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isLoading={isLoading}
          methods={methods}
        />
      </Stack>
    </FormProvider>
  )
}

import { setLocale } from 'yup'
import * as yup from 'yup'
import YupPassword from 'yup-password'

YupPassword(yup)

/**
 * Override default yup errors to i18n keys
 * errors:validation is the default namespace
 */
setLocale({
  mixed: {
    default: 'invalid',
    required: 'required',
  },
  string: {
    email: 'invalid_email',
  },
})

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, Stack, Button } from '@mui/material'
import { Edit, XSquare } from 'react-feather'
import { ProtocolRoundInfoAccordion } from '@/features'
import {
  CardAttribute,
  LinkButton,
  ModalLayout,
  SharepointUrlLink,
} from '@/components'
import { EnvironmentData } from './EnvironmentData'
import { VisitLogAction } from './VisitLogAction'
import { formatEnvironmentalData } from './formatEnvironmentalData'
import { ResearchEventDate } from '@/features/dataValidation/VisitLog/ResearchEventDate'
import { useModal, useMutationWrapper, usePutVisitLogStatus } from '@/hooks'
import dayjs from 'dayjs'
import { getPath, getSpeciesWithResearchTypesString } from '@/utils'
import { VisitLog as VisitLogType } from '@/types'
import { DATE_FORMAT_DMY_TIME, ROUTES_PATH, PageReferrers } from '@/constants'
import {
  VisitLogFormContainer,
  ProcessingStatusForm,
  ProcessingStatusFormData,
} from '@/components/forms'
import { FieldReportFormContainer } from '@/components/forms/FieldReportFormContainer'

export type DataValidationDetailsProps = {
  visitLog: VisitLogType
}

export const VisitLog = ({
  visitLog,
}: DataValidationDetailsProps): JSX.Element => {
  const { t } = useTranslation('dataValidation')
  const [data, setData] = useState<VisitLogType>(visitLog)

  const [isVisitLogEdit, setIsVisitLogEdit] = useState(false)
  const [isFieldReportEdit, setIsFieldReportEdit] = useState(false)

  const { close } = useModal()

  const {
    mutateAsync: updateVisitLogStatus,
    isLoading: mutateStatusInProcess,
  } = useMutationWrapper(
    usePutVisitLogStatus.bind(null, {
      id: data.id,
    })
  )

  const handleStatusSubmit = async ({
    processingStatus,
    processingComment,
    isFieldReportAdded,
  }: ProcessingStatusFormData) => {
    const visitLogStatusData = {
      processing_status: !!processingStatus ? processingStatus : null,
      processing_comment: !!processingComment ? processingComment : null,
      is_field_report_added: isFieldReportAdded ?? false,
    }

    await updateVisitLogStatus(visitLogStatusData, {
      successMessageKey: 'dataValidation:notifications.processingStatusChanged',
      onSuccess: (data) => {
        setData(data)
      },
    })
  }

  const updatedDataHandler = (data: VisitLogType) => setData(data)

  const environmentalData = {
    start_temperature: data.start_temperature,
    end_temperature: data.end_temperature,
    cloud_cover: data.cloud_cover,
    wind_force: data.wind_force,
    wind_direction: data.wind_direction,
    precipitation: data.precipitation,
    precipitation_duration: data.precipitation_duration,
    invalid_variables: data.invalid_variables,
  }

  const protocol = data.research_visit_event?.research_area.protocols.find(
    (p) => p.id === data.research_visit_event?.protocol_id
  )

  const handleEditVisitLog = () => {
    setIsVisitLogEdit(!isVisitLogEdit)
  }

  const handleEditFieldReport = () => {
    setIsFieldReportEdit(!isFieldReportEdit)
  }

  return (
    <ModalLayout
      title={t('visitDetailTitle', { id: data.id })}
      headerAction={
        <VisitLogAction visitLog={data} onDataUpdate={updatedDataHandler} />
      }
    >
      <Grid container={true} rowSpacing={5} width={{ md: 925, sm: 'auto' }}>
        <>
          {!data ? (
            <Typography>{t('visit-log.noData')}</Typography>
          ) : (
            <>
              <Grid xs={12} item={true} container={true} spacing={4}>
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.projectNumber')}
                    value={
                      data.research_visit_event?.research_area.project
                        .project_number
                    }
                  />
                </Grid>
                <Grid xs={6} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.projectName')}
                    value={
                      data.research_visit_event?.research_area.project.name
                    }
                  />
                </Grid>
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.clientName')}
                    value={
                      data.research_visit_event?.research_area.project
                        .client_name
                    }
                  />
                </Grid>

                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.researchAreaNumber')}
                    value={data.research_visit_event?.research_area.id}
                  />
                </Grid>
                <Grid xs={6} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.researchAreaLocation')}
                    value={
                      data.research_visit_event?.research_area.location_name
                    }
                  />
                </Grid>
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.client_area_number')}
                    value={
                      !!data.research_visit_event?.research_area
                        .client_area_number
                        ? data.research_visit_event?.research_area
                            .client_area_number
                        : '-'
                    }
                  />
                </Grid>
                {data.research_visit_event?.research_area
                  .report_sharepoint_url && (
                  <Grid xs={6} item={true}>
                    <CardAttribute
                      title={t('visit-log.fieldTitles.field_report_url')}
                      value={
                        <SharepointUrlLink
                          sharepointUrl={
                            data.research_visit_event?.research_area
                              .report_sharepoint_url
                          }
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
              {protocol && (
                <Grid xs={12} item={true}>
                  <ProtocolRoundInfoAccordion
                    title={t('visit-log.fieldTitles.protocolInfo')}
                    allRounds={protocol.rounds}
                    round={protocol.rounds.find((r) =>
                      visitLog.research_visit_event?.visit_protocol_id
                        ? r.id ===
                          visitLog.research_visit_event.visit_protocol_id
                        : r.round_type ===
                          visitLog.research_visit_event?.round_type
                    )}
                  />
                </Grid>
              )}
              <Grid xs={12} item={true} container={true} spacing={4}>
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.round')}
                    value={
                      !!data.research_visit_event ? (
                        <LinkButton
                          url={getPath(ROUTES_PATH.MONITORING_DETAIL, {
                            id: data.research_visit_event?.id,
                          })}
                          title={`${data.research_visit_event?.round_type}${
                            data.research_visit_event?.round_number ?? ''
                          }`}
                          referrer={PageReferrers.DATA_VALIDATION}
                          arrowIcon={true}
                          variant="textThin"
                          action={() => close()}
                        />
                      ) : (
                        '-'
                      )
                    }
                  />
                </Grid>
                <Grid xs={9} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.species')}
                    value={
                      !!data.research_visit_event
                        ? getSpeciesWithResearchTypesString(
                            data.research_visit_event
                          )
                        : '-'
                    }
                  />
                </Grid>
              </Grid>
              <Grid xs={12} item={true} container={true} spacing={4}>
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.creationDate')}
                    value={
                      data.created_at
                        ? dayjs(data.created_at)
                            .format(DATE_FORMAT_DMY_TIME)
                            .toString()
                        : '-'
                    }
                  />
                </Grid>
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.createdBy')}
                    value={`${data.created_by?.first_name ?? '-'} ${
                      data.created_by?.last_name ?? ''
                    }`}
                  />
                </Grid>
                <Grid xs={6} item={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.researchers')}
                    value={
                      data.researchers?.map(
                        ({ first_name, last_name }, i) =>
                          `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                      ) ?? t('visit-log.noResearchers')
                    }
                  />
                </Grid>

                {data.updated_at !== data.created_at && (
                  <>
                    <Grid xs={3} item={true}>
                      <CardAttribute
                        title="Edited on:"
                        value={
                          data.updated_at
                            ? dayjs(data.updated_at)
                                .format(DATE_FORMAT_DMY_TIME)
                                .toString()
                            : '-'
                        }
                      />
                    </Grid>
                    <Grid xs={9} item={true}>
                      <CardAttribute
                        title="Edited by:"
                        value={`${data.edited_by?.first_name ?? '-'} ${
                          data.edited_by?.last_name ?? ''
                        }`}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={4}
                width="100%"
                mt={6}
              >
                {data.research_visit_event && (
                  <Button
                    onClick={handleEditVisitLog}
                    variant="textThin"
                    endIcon={
                      isVisitLogEdit ? (
                        <XSquare size={14} />
                      ) : (
                        <Edit size={14} />
                      )
                    }
                  >
                    {isVisitLogEdit
                      ? t('buttons.closeEditing')
                      : t('buttons.editVisitLog')}
                  </Button>
                )}
              </Stack>

              {data.research_visit_event && isVisitLogEdit ? (
                <VisitLogFormContainer
                  data={data}
                  setUpdatedVisitLog={setData}
                  onSuccessUpdate={() => setIsVisitLogEdit(false)}
                />
              ) : (
                <Grid xs={12} item={true} container={true}>
                  <ResearchEventDate
                    start_datetime={data.start_datetime}
                    end_datetime={data.end_datetime}
                    invalid_variables={data.invalid_variables}
                  />

                  <CardAttribute
                    title={t('visit-log.sections.environmentalData')}
                    value={
                      <EnvironmentData
                        {...formatEnvironmentalData(environmentalData)}
                      />
                    }
                    mt={4}
                  />

                  {data.is_left_earlier && (
                    <Grid xs={6} item={true}>
                      <CardAttribute
                        title={t('visit-log.fieldTitles.leftEarlier')}
                        value={data.reason_of_leaving}
                        mt={4}
                      />
                    </Grid>
                  )}
                  {!!data.comment && (
                    <Grid xs={6} item={true}>
                      <CardAttribute
                        title={t('visit-log.fieldTitles.comment')}
                        value={data.comment}
                        mt={4}
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              {!!data.photos.length && (
                <Grid xs={12} item={true} container={true}>
                  <CardAttribute
                    title={t('visit-log.fieldTitles.photos')}
                    value={data.photos.map(({ id, link }) => (
                      <span key={id}>
                        <a
                          key={id}
                          href={link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Photo {id}
                        </a>
                        &nbsp;&nbsp;
                      </span>
                    ))}
                  />
                </Grid>
              )}

              <Stack
                direction="row"
                justifyContent="flex-end"
                width="100%"
                mt={6}
              >
                {data.research_visit_event && (
                  <Button
                    onClick={handleEditFieldReport}
                    variant="textThin"
                    endIcon={
                      isFieldReportEdit ? (
                        <XSquare size={14} />
                      ) : (
                        <Edit size={14} />
                      )
                    }
                  >
                    {isFieldReportEdit
                      ? t('buttons.closeEditing')
                      : t('buttons.editFieldReport')}
                  </Button>
                )}
              </Stack>

              {data.research_visit_event &&
              data.research_visit_event.field_report &&
              isFieldReportEdit ? (
                <FieldReportFormContainer
                  data={data}
                  fieldReportId={data.research_visit_event.field_report.id}
                  setUpdatedVisitLog={setData}
                  onSuccessUpdate={() => setIsFieldReportEdit(false)}
                />
              ) : (
                <>
                  {!!data.research_visit_event?.field_report
                    ?.field_impression && (
                    <Grid xs={12} item={true}>
                      <CardAttribute
                        title={t('visit-log.fieldTitles.fieldImpression')}
                        value={
                          data.research_visit_event?.field_report
                            ?.field_impression
                        }
                      />
                    </Grid>
                  )}
                  {!!data.research_visit_event?.field_report
                    ?.round_impression && (
                    <Grid xs={12} item={true}>
                      <CardAttribute
                        title={t('visit-log.fieldTitles.roundImpression')}
                        value={
                          data.research_visit_event?.field_report
                            ?.round_impression
                        }
                      />
                    </Grid>
                  )}
                  {!!data.research_visit_event?.field_report?.photos.length && (
                    <Grid xs={12} item={true} container={true}>
                      <CardAttribute
                        title={t('visit-log.fieldTitles.fieldReportPhotos')}
                        value={data.research_visit_event?.field_report?.photos.map(
                          ({ id, link }) => (
                            <span key={id}>
                              <a
                                key={id}
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Photo {id}
                              </a>
                              &nbsp;&nbsp;
                            </span>
                          )
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item={true} sm={12}>
                <CardAttribute
                  title={t('visit-log.fieldTitles.processingStatus')}
                  value={
                    <ProcessingStatusForm
                      statusSelect={true}
                      fieldReportCheckbox={true}
                      defaultValues={{
                        processingStatus: data.processing_status ?? '',
                        processingComment: data.processing_comment ?? '',
                        isFieldReportAdded: data.is_field_report_added ?? false,
                      }}
                      onSubmit={handleStatusSubmit}
                      isLoading={mutateStatusInProcess}
                    />
                  }
                />
              </Grid>
            </>
          )}
        </>
      </Grid>
    </ModalLayout>
  )
}

import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'

type BooleanSelectSelectProps = {
  label: string
  yesLabel?: string
  noLabel?: string
  value?: boolean
  onChange: (value: boolean | undefined) => void
}

export const BooleanSelect = ({
  label,
  yesLabel,
  noLabel,
  value,
  onChange,
}: BooleanSelectSelectProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const yesLabelText = yesLabel || t('labels.yes')
  const noLabelText = noLabel || t('labels.no')

  const options = [
    {
      label: yesLabelText,
      value: true,
    },
    {
      label: noLabelText,
      value: false,
    },
  ]

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextField {...params} label={label} />
      )}
      value={
        value !== undefined
          ? {
              label: value ? yesLabelText : noLabelText,
              value,
            }
          : null
      }
      onChange={(_event, option) => {
        onChange(option ? (option.value as boolean) : undefined)
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{ width: 316 }}
    />
  )
}

import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Stack,
  Box,
  Button,
  Typography,
  Container,
  Skeleton,
} from '@mui/material'
import { ArrowLeft } from 'react-feather'
import { PageReferrers } from '@/constants'

export type PageHeaderLayoutProps = {
  backButton?: boolean
  backButtonUrl?: string
  subtitle?: string
  title?: string
  rightBar?: JSX.Element
  isTitleLoading?: boolean
}

export const PageHeaderLayout = ({
  backButton = false,
  backButtonUrl,
  subtitle,
  title = '',
  rightBar,
  isTitleLoading = false,
}: PageHeaderLayoutProps): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const location = useLocation()
  const referrer = location.state?.referrer as PageReferrers

  return (
    <Stack bgcolor="background.default" py={8}>
      <Container maxWidth="xl">
        <Box>
          {backButton && (
            <Button
              onClick={() =>
                backButtonUrl ? navigate(backButtonUrl) : navigate(-1)
              }
              variant="textThin"
              startIcon={<ArrowLeft size={16} />}
            >
              {!!referrer
                ? t('buttons.backToPageButton', { page: referrer })
                : t('buttons.backButton')}
            </Button>
          )}
        </Box>

        <Stack
          pt={1}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', md: 'flex-end' }}
          spacing={2}
        >
          <Box>
            {subtitle && <Typography variant="h5">{subtitle}</Typography>}

            <Typography variant="h1" gutterBottom={false}>
              {isTitleLoading ? <Skeleton variant="rounded" /> : title}
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" spacing={3}>
            {rightBar && rightBar}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  )
}

import { useContext } from 'react'
import { ModalContext, ModalContextType } from '@/contexts/ModalContext'

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('ModalContext must be placed within ModalProvider')
  }

  return context
}

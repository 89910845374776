import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { ProjectProtocol } from '@/types/api/entities'
import { QUERY_KEYS } from '@/constants'
import { APIErrorResponse } from '@/types'

export type UseProjectProtocolsProps = {
  projectId: number
  isEnabled?: boolean
}

export const getUseProjectProtocolsKey = (id: number): string[] =>
  QUERY_KEYS.PROJECT_PROTOCOLS.map((key) =>
    key === ':projectId' ? id.toString() : key
  )

export const useProjectProtocols = ({
  projectId,
  isEnabled = true,
}: UseProjectProtocolsProps): UseQueryResult<
  ProjectProtocol[],
  APIErrorResponse
> => {
  const client = useApiClient()

  const queryKey = getUseProjectProtocolsKey(projectId)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/projects/${projectId}/protocols/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: isEnabled }
  )
}

import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { ProjectProtocolRound } from '@/types'

type ProtocolRoundInfoProps = {
  round: ProjectProtocolRound
  allRounds: ProjectProtocolRound[]
  showCustomParams?: boolean
}

const isNotEmpty = (value: string | null | number | undefined) =>
  value !== null && value !== '' && value !== undefined

export const ProtocolRoundInfo = ({
  round,
  allRounds,
  showCustomParams = true,
}: ProtocolRoundInfoProps): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <>
      <Typography variant="body2">
        {t('protocolRoundInfo.round_type')}&nbsp;
        <strong>{round.round_type}</strong>
      </Typography>

      {showCustomParams && (
        <>
          {!!round.from_date && (
            <Typography variant="body2">
              {t('protocolRoundInfo.from_date')}&nbsp;
              <strong>{round.from_date}</strong>
            </Typography>
          )}
          {!!round.to_date && (
            <Typography variant="body2">
              {t('protocolRoundInfo.to_date')}&nbsp;
              <strong>{round.to_date}</strong>
            </Typography>
          )}
        </>
      )}
      {(!!round.from_time || !!round.to_time) && (
        <Typography variant="body2">
          {t('protocolRoundInfo.time')}
          {!!round.from_time && (
            <span>
              &nbsp;{t('protocolRoundInfo.from')}&nbsp;
              <strong>{round.from_time}</strong>
            </span>
          )}
          {!!round.to_time && (
            <span>
              &nbsp;{t('protocolRoundInfo.to')}&nbsp;
              <strong>{round.to_time}</strong>
            </span>
          )}
        </Typography>
      )}
      {isNotEmpty(round.touch_time) && (
        <Typography variant="body2">
          {t('protocolRoundInfo.touch_time')}&nbsp;
          <strong>{round.touch_time}</strong>
        </Typography>
      )}
      {isNotEmpty(round.end_time_relative_to_sunrise) && (
        <Typography variant="body2">
          {t('protocolRoundInfo.end_time_relative_to_sunrise')}&nbsp;
          <strong>
            {round.end_time_relative_to_sunrise}&nbsp;
            {t('protocolRoundInfo.minutes')}
          </strong>
        </Typography>
      )}
      {isNotEmpty(round.start_time_relative_to_sunset) && (
        <Typography variant="body2">
          {t('protocolRoundInfo.start_time_relative_to_sunset')}&nbsp;
          <strong>
            {round.start_time_relative_to_sunset}&nbsp;
            {t('protocolRoundInfo.minutes')}
          </strong>
        </Typography>
      )}
      {showCustomParams && (
        <>
          <Typography variant="body2">
            {t('protocolRoundInfo.required_number_of_researchers')}&nbsp;
            <strong>{round.required_number_of_researchers}</strong>
          </Typography>
          <Typography variant="body2">
            {t('protocolRoundInfo.visit_duration')}&nbsp;
            <strong>{round.visit_duration}</strong>
          </Typography>
          <Typography variant="body2">
            {t('protocolRoundInfo.number_of_visits')}&nbsp;
            <strong>{round.number_of_visits}</strong>
          </Typography>
        </>
      )}
      {round.period_between_visits_map && (
        <>
          <Typography variant="body2">
            {t('protocolRoundInfo.period_between_visits')}&nbsp;
          </Typography>
          {Object.keys(round.period_between_visits_map).map(
            (visit_protocol_id) => (
              <Typography
                variant="body2"
                key={visit_protocol_id}
                style={{ paddingLeft: 10 }}
              >
                {allRounds.find((r) => r.id === +visit_protocol_id)?.round_type}
                :{' '}
                <strong>
                  {Number(
                    round.period_between_visits_map?.[visit_protocol_id] ?? 0
                  ) /
                    24 /
                    60}
                  &nbsp;
                  {t('protocolRoundInfo.days')}
                </strong>
              </Typography>
            )
          )}
        </>
      )}
      {(isNotEmpty(round.from_wind_force) ||
        isNotEmpty(round.to_wind_force)) && (
        <Typography variant="body2">
          {t('protocolRoundInfo.wind_force')}
          {isNotEmpty(round.from_wind_force) && (
            <span>
              &nbsp;{t('protocolRoundInfo.from')}&nbsp;
              <strong>{round.from_wind_force}</strong>
            </span>
          )}
          {isNotEmpty(round.to_wind_force) && (
            <span>
              &nbsp;{t('protocolRoundInfo.to')}&nbsp;
              <strong>
                {round.to_wind_force}&nbsp;
                {t('protocolRoundInfo.beaufort')}
              </strong>
            </span>
          )}
        </Typography>
      )}
      {(isNotEmpty(round.from_temperature) ||
        isNotEmpty(round.to_temperature)) && (
        <Typography variant="body2">
          {t('protocolRoundInfo.temperature')}
          {isNotEmpty(round.from_temperature) && (
            <span>
              &nbsp;{t('protocolRoundInfo.from')}&nbsp;
              <strong>
                {round.from_temperature}&nbsp;
                {t('protocolRoundInfo.degrees')}
              </strong>
            </span>
          )}
          {isNotEmpty(round.to_temperature) && (
            <span>
              &nbsp;{t('protocolRoundInfo.to')}&nbsp;
              <strong>{round.to_temperature}</strong>
            </span>
          )}
        </Typography>
      )}
      {!!round.max_precipitation && (
        <Typography variant="body2">
          {t('protocolRoundInfo.max_precipitation')}&nbsp;
          <strong>{round.max_precipitation}</strong>
        </Typography>
      )}
      {!!round.method_of_identification && (
        <Typography variant="body2">
          {t('protocolRoundInfo.method_of_identification')}&nbsp;
          <strong>{round.method_of_identification}</strong>
        </Typography>
      )}
      {!!round.one_round_mandatory_in_a_month && (
        <Typography variant="body2">
          {t('protocolRoundInfo.one_round_mandatory_in_a_month')}&nbsp;
          <strong>{round.one_round_mandatory_in_a_month}</strong>
        </Typography>
      )}
    </>
  )
}

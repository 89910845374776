import { useTranslation } from 'react-i18next'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { VisitLogStatuses } from '@/types'

type VisitLogStatusMultiSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
}

export const VisitLogStatusMultiSelect = ({
  value,
  onChange,
}: VisitLogStatusMultiSelectProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const options = Object.keys(VisitLogStatuses).map((key) => {
    const status = VisitLogStatuses[
      key as keyof typeof VisitLogStatuses
    ] as string

    return {
      label: t(`visitLogStatus.${status}`),
      value: status,
    } as SearchAutocompleteOption
  })

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={options}
      placeholder={t(`placeholders.visitLogStatus`)}
      sort={false}
      fieldSx={{ width: 280 }}
    />
  )
}

import { Box, Stack } from '@mui/material'
import { Step } from '../Step'
import { useStepperContext } from '../StepperContext'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ProjectCreateStep } from '@/features/projectExtend/ProjectCreateStep'
import { ProjectStep } from '@/features/projectExtend/ProjectStep'
import { ProjectSpeciesStep } from '@/features/projectExtend/ProjectSpeciesStep'
import { ProtocolsStep } from '@/features/projectExtend/ProtocolsStep'
import { ResearchAreaStep } from '@/features/projectExtend/ResearchAreaStep'

export const STEPS = [
  {
    step: 1,
    description: 'projectExtendInfo.stepper.step1Title',
    component: ProjectStep,
  },
  {
    step: 2,
    description: 'projectExtendInfo.stepper.step2Title',
    component: ProjectSpeciesStep,
  },
  {
    step: 3,
    description: 'projectExtendInfo.stepper.step3Title',
    component: ProtocolsStep,
  },
  {
    step: 4,
    description: 'projectExtendInfo.stepper.step4Title',
    component: ResearchAreaStep,
  },
]

type StepperContainerProps = {
  projectCreateStep?: boolean
}

export type StepComponentProps = {
  projectId: number
}

export const StepperContainer = ({
  projectCreateStep = false,
}: StepperContainerProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const { id: projectId } = useParams()

  const { activeStep } = useStepperContext()

  if (!projectCreateStep && !projectId) return <></>

  const StepComponent = STEPS[activeStep - 1]?.component || null

  return (
    <Stack direction={{ md: 'row' }} spacing={{ xs: 4, md: 9 }}>
      <Stack
        spacing={3}
        minWidth={{ xs: 'auto', md: '280px' }}
        justifyContent="stretch"
        data-testid="steps"
        data-active-step={activeStep}
      >
        {STEPS.map(({ step, description }) => (
          <Step
            key={step}
            step={step}
            description={t(description)}
            isActive={step === activeStep}
            isPassed={step < activeStep}
          />
        ))}
      </Stack>

      <Box width="100%">
        {projectCreateStep ? (
          <ProjectCreateStep />
        ) : (
          StepComponent && projectId && <StepComponent projectId={+projectId} />
        )}
      </Box>
    </Stack>
  )
}

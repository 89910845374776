import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { SelectOption } from '@/components'
import { VisitLogFormData } from '@/components/forms'
import { ControlledSelect } from '@/components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export type EnvironmentDataSelectFieldProps = {
  iconName: IconDefinition
  field: keyof VisitLogFormData
  options: SelectOption[]
}

export const EnvironmentDataSelectField = ({
  iconName,
  field,
  options,
}: EnvironmentDataSelectFieldProps) => {
  const { control } = useFormContext<VisitLogFormData>()

  return (
    <Stack direction="row" alignItems="center" spacing={1} width={280}>
      <FontAwesomeIcon icon={iconName} size="sm" />
      <ControlledSelect
        placeholder="Select"
        name={field}
        options={options}
        control={control}
        errorMessagePrefix=""
      />
    </Stack>
  )
}

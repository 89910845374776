import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  DAY_ROUND_TIME,
  EVENING_ROUND_TIME,
  MORNING_ROUND_TIME,
} from '@/constants'

export const RoundInfo = (): JSX.Element => {
  const { t } = useTranslation('planning')

  return (
    <Stack
      pl={4}
      pr={6}
      pb={1}
      direction="row"
      justifyContent="start"
      spacing={3}
    >
      <Typography fontSize={13} color="grey.600">
        {t('availability.morningRoundLabel')}: {MORNING_ROUND_TIME},
      </Typography>
      <Typography fontSize={13} color="grey.600">
        {t('availability.dayRoundLabel')}: {DAY_ROUND_TIME},
      </Typography>
      <Typography fontSize={13} color="grey.600">
        {t('availability.eveningRoundLabel')}: {EVENING_ROUND_TIME}
      </Typography>
    </Stack>
  )
}

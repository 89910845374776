import { useAvailableResearchers } from '@/hooks/api/useAvailableResearchers'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'

type ResearcherMultiSelectProps = {
  value?: string
  onChange: (value: string) => void
  placeholder: string
}

export const ResearchersMultiSelect = ({
  value,
  onChange,
  placeholder,
}: ResearcherMultiSelectProps): JSX.Element => {
  const { data: researchers } = useAvailableResearchers({})

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={researchers?.map(
        ({ id, first_name, last_name, email }) =>
          ({
            label: first_name
              ? `${id}: ${first_name} ${last_name}`
              : `${id}: ${email}`,
            value: id.toString(),
          } as SearchAutocompleteOption)
      )}
      sort={true}
      placeholder={placeholder}
      fieldSx={{ width: { xs: 390, md: 300 } }}
    />
  )
}

import { Box, Modal } from '@mui/material'
import { useModal } from '@/hooks'

export const ModalWrapper = (): JSX.Element => {
  const { isOpen, close, modalContent, options } = useModal()

  return (
    <Modal
      open={isOpen}
      onClose={(_, reason) => close(reason)}
      disableEscapeKeyDown={options.isEscapeButtonDisabled}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        minWidth={{ xs: 'calc(100vw - 10%)', md: '700px', lg: '700px' }}
        sx={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        {modalContent}
      </Box>
    </Modal>
  )
}

import {
  ResearchAreaGraphic,
  ResearchAreaGraphicGeometry,
  ResearchAreaGraphicType,
} from '@/types'

export const getFirstGeometryPoint = (graphic: ResearchAreaGraphic) => {
  if (
    graphic.type === ResearchAreaGraphicType.AREA &&
    'rings' in graphic.geometry
  ) {
    return graphic.geometry.rings[0][0]
  } else if (
    [
      ResearchAreaGraphicType.CYCLING_ROUTE,
      ResearchAreaGraphicType.WALKING_ROUTE,
    ].includes(graphic.type) &&
    'paths' in graphic.geometry
  ) {
    return graphic.geometry.paths[0][0]
  } else if (
    graphic.type === ResearchAreaGraphicType.STAND_PLACE &&
    'x' in graphic.geometry &&
    'y' in graphic.geometry
  ) {
    return [graphic.geometry.x, graphic.geometry.y]
  }
}

export const getPolygonGraphics = (graphics: ResearchAreaGraphic[]) =>
  getGraphicsByType(graphics, ResearchAreaGraphicType.AREA)

export const getCyclingRoutesGraphics = (graphics: ResearchAreaGraphic[]) =>
  getGraphicsByType(graphics, ResearchAreaGraphicType.CYCLING_ROUTE)

export const getWalkingRoutesGraphics = (graphics: ResearchAreaGraphic[]) =>
  getGraphicsByType(graphics, ResearchAreaGraphicType.WALKING_ROUTE)

export const getStandPlaceGraphics = (graphics: ResearchAreaGraphic[]) =>
  getGraphicsByType(graphics, ResearchAreaGraphicType.STAND_PLACE)

export const getGraphicsByType = (
  graphics: ResearchAreaGraphic[],
  graphic_type: ResearchAreaGraphicType
) => graphics.filter((gr) => gr.type === graphic_type)

export const defineGeometryType = (geometry: ResearchAreaGraphicGeometry) => {
  if ('rings' in geometry) {
    return ResearchAreaGraphicType.AREA
  } else if ('paths' in geometry) {
    return ResearchAreaGraphicType.CYCLING_ROUTE
  } else if ('x' in geometry && 'y' in geometry) {
    return ResearchAreaGraphicType.STAND_PLACE
  }
}

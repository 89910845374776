import {
  ResearchAreaGraphic,
  ResearchAreaGraphicType,
  RoundType,
} from '@/types'

export const AMSTERDAM_COORDINATES = [4.9041, 52.3676]
export const DEFAULT_ZOOM = 14
export const SPATIAL_REFERENCE = 4326

export const createGraphicJson = (
  graphic: ResearchAreaGraphic,
  areaName: string
) => ({
  geometry: graphic.geometry,
  attributes: {
    ObjectID: graphic.id,
    id: graphic.id,
    type: graphic.type,
    round_type: graphic.round_type,
    animal: graphic.animal,
    area_name: areaName,
  },
})

const animalFieldConfig = {
  name: 'animal',
  type: 'string',
  alias: 'Soort Onderzoek',
  sqlType: 'sqlTypeOther',
  length: 256,
  nullable: true,
  editable: true,
  domain: {
    type: 'coded-value',
    codedValues: [
      {
        name: 'Huismussen',
        code: 'Huismussen',
      },
      {
        name: 'Gierzwaluwen',
        code: 'Gierzwaluwen',
      },
      {
        name: 'Vleermuizen',
        code: 'Vleermuizen',
      },
      {
        name: 'Vleermuizen (Overig)',
        code: 'Vleermuizen (Overig)',
      },
    ],
  },
  defaultValue: null,
}

const roundTypeFieldConfig = {
  name: 'round_type',
  type: 'string',
  alias: 'Round',
  sqlType: 'sqlTypeOther',
  length: 256,
  nullable: true,
  editable: true,
  domain: {
    type: 'coded-value',
    codedValues: [
      {
        name: RoundType.MORNING,
        code: RoundType.MORNING,
      },
      {
        name: RoundType.DAY,
        code: RoundType.DAY,
      },
      {
        name: RoundType.EVENING,
        code: RoundType.EVENING,
      },
      {
        name: RoundType.NIGHT,
        code: RoundType.NIGHT,
      },
    ],
  },
}

const areaNameFieldConfig = {
  name: 'area_name',
  type: 'string',
  alias: 'Area name',
  nullable: true,
  editable: false,
  domain: null,
  defaultValue: null,
}

const objectIDFieldConfig = {
  name: 'ObjectID',
  type: 'oid',
  alias: 'ObjectID',
  sqlType: 'sqlTypeOther',
  nullable: false,
  editable: false,
  domain: null,
  defaultValue: null,
}

const idFieldConfig = {
  name: 'id',
  type: 'integer',
  alias: 'id',
  nullable: true,
  editable: false,
  domain: null,
  defaultValue: null,
}

const getTypeFieldConfig = (type: ResearchAreaGraphicType) => ({
  name: 'type',
  type: 'string',
  alias: 'type',
  nullable: false,
  editable: false,
  domain: null,
  defaultValue: type,
})

const labelingInfoConfig = [
  {
    symbol: {
      type: 'text',
      color: '#000000',
      font: {
        size: '12px',
        family: 'Noto Sans',
        weight: 'normal',
      },
    },
    labelExpressionInfo: {
      expression: `$feature.area_name + TextFormatting.NewLine + $feature.round_type`,
    },
  },
]

export const createFeatureLayerConfig = (
  source: Array<object>,
  outlineColor: string | [number, number, number, number]
) => ({
  hasGeometryProperties: true,
  geometryType: 'polygon',
  spatialReference: { wkid: SPATIAL_REFERENCE },
  renderer: {
    type: 'simple',
    symbol: {
      color: [0, 0, 0, 0],
      outline: {
        color: outlineColor,
        width: 2,
        type: 'simple-line',
        style: 'solid',
      },
      type: 'simple-fill',
      style: 'solid',
    },
  },
  labelingInfo: labelingInfoConfig,
  popupEnabled: true,
  popupTemplate: {
    title: `{area_name}`,
    content: '<hr/><b>Round</b>: {round_type}',
  },
  transparency: 0,
  objectIdField: 'ObjectID',
  uniqueIdField: {
    name: 'ObjectID',
    isSystemMaintained: true,
  },
  outFields: ['*'],
  fields: [
    objectIDFieldConfig,
    idFieldConfig,
    getTypeFieldConfig(ResearchAreaGraphicType.AREA),
    areaNameFieldConfig,
    roundTypeFieldConfig,
    animalFieldConfig,
  ],
  templates: [
    {
      name: 'New area',
      description: '',
      drawingTool: 'esriFeatureEditToolPolygon',
      prototype: {
        attributes: {
          animal: null,
        },
      },
    },
  ],
  source,
})

export const createCyclingRoutesFeatureLayerConfig = (
  source: Array<object>,
  outlineColor: string | [number, number, number, number]
) => ({
  hasGeometryProperties: true,
  geometryType: 'polyline',
  spatialReference: { wkid: SPATIAL_REFERENCE },
  renderer: {
    type: 'simple',
    symbol: {
      color: [0, 0, 0, 0],
      outline: {
        color: outlineColor,
        width: 2,
        type: 'simple-line',
        style: 'short-dash',
      },
      type: 'simple-fill',
      style: 'solid',
    },
  },
  labelingInfo: labelingInfoConfig,
  popupEnabled: true,
  popupTemplate: {
    title: `Cycling route ({area_name})`,
    content: '<hr/><b>Round</b>: {round_type}',
  },
  transparency: 0,
  objectIdField: 'ObjectID',
  uniqueIdField: {
    name: 'ObjectID',
    isSystemMaintained: true,
  },
  outFields: ['*'],
  fields: [
    objectIDFieldConfig,
    idFieldConfig,
    getTypeFieldConfig(ResearchAreaGraphicType.CYCLING_ROUTE),
    areaNameFieldConfig,
    roundTypeFieldConfig,
    animalFieldConfig,
  ],
  templates: [
    {
      name: 'New cycling route',
      description: '',
      drawingTool: 'esriFeatureEditToolLine',
      prototype: {
        attributes: {
          animal: null,
        },
      },
    },
  ],
  source,
})

export const createWalkingRoutesFeatureLayerConfig = (
  source: Array<object>,
  outlineColor: string | [number, number, number, number]
) => ({
  hasGeometryProperties: true,
  geometryType: 'polyline',
  spatialReference: { wkid: SPATIAL_REFERENCE },
  renderer: {
    type: 'simple',
    symbol: {
      color: [0, 0, 0, 0],
      outline: {
        color: outlineColor,
        width: 2,
        type: 'simple-line',
        style: 'solid',
      },
      type: 'simple-fill',
      style: 'solid',
    },
  },
  labelingInfo: labelingInfoConfig,
  popupEnabled: true,
  popupTemplate: {
    title: `Walking route ({area_name})`,
    content: '<hr/><b>Round</b>: {round_type}',
  },
  transparency: 0,
  objectIdField: 'ObjectID',
  uniqueIdField: {
    name: 'ObjectID',
    isSystemMaintained: true,
  },
  outFields: ['*'],
  fields: [
    objectIDFieldConfig,
    idFieldConfig,
    getTypeFieldConfig(ResearchAreaGraphicType.WALKING_ROUTE),
    areaNameFieldConfig,
    roundTypeFieldConfig,
    animalFieldConfig,
  ],
  templates: [
    {
      name: 'New walking route',
      description: '',
      drawingTool: 'esriFeatureEditToolLine',
      prototype: {
        attributes: {
          animal: null,
        },
      },
    },
  ],
  source,
})

export const createStandPlaceFeatureLayerConfig = (
  source: Array<object>,
  outlineColor: string | [number, number, number, number]
) => ({
  hasGeometryProperties: true,
  geometryType: 'point',
  renderer: {
    type: 'simple',
    symbol: {
      color: outlineColor,
      type: 'simple-marker',
      style: 'x',
      size: '8px',
      outline: {
        color: outlineColor,
        width: 2,
      },
    },
  },
  spatialReference: { wkid: SPATIAL_REFERENCE },
  labelingInfo: labelingInfoConfig,
  popupEnabled: true,
  popupTemplate: {
    title: `Stand place ({area_name})`,
    content: '<hr/><b>Round</b>: {round_type}',
  },
  transparency: 0,
  objectIdField: 'ObjectID',
  uniqueIdField: {
    name: 'ObjectID',
    isSystemMaintained: true,
  },
  outFields: ['*'],
  fields: [
    objectIDFieldConfig,
    idFieldConfig,
    getTypeFieldConfig(ResearchAreaGraphicType.STAND_PLACE),
    areaNameFieldConfig,
    roundTypeFieldConfig,
    animalFieldConfig,
  ],
  templates: [
    {
      name: 'New stand place',
      description: '',
      drawingTool: 'esriFeatureEditToolPoint',
      prototype: {
        attributes: {
          animal: null,
        },
      },
    },
  ],
  source,
})

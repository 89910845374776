import { SearchAutocompleteOption } from '@/components/inputs'

const species = [
  {
    label: 'Baardvleermuis',
    value: 'Baardvleermuis',
  },
  {
    label: "Bechstein's vleermuis",
    value: "Bechstein's vleermuis",
  },
  {
    label: 'Bosvleermuis',
    value: 'Bosvleermuis',
  },
  {
    label: "Brandt's vleermuis",
    value: "Brandt's vleermuis",
  },
  {
    label: 'Franjestaart',
    value: 'Franjestaart',
  },
  {
    label: 'Gewone dwergvleermuis',
    value: 'Gewone dwergvleermuis',
  },
  {
    label: 'Gewone grootoorvleermuis',
    value: 'Gewone grootoorvleermuis',
  },
  {
    label: 'Grijze grootoorvleermuis',
    value: 'Grijze grootoorvleermuis',
  },
  {
    label: 'Ingekorven vleermuis',
    value: 'Ingekorven vleermuis',
  },
  {
    label: 'Kleine dwergvleermuis',
    value: 'Kleine dwergvleermuis',
  },
  {
    label: 'Laatvlieger',
    value: 'Laatvlieger',
  },
  {
    label: 'Meervleermuis',
    value: 'Meervleermuis',
  },
  {
    label: 'Rosse vleermuis',
    value: 'Rosse vleermuis',
  },
  {
    label: 'Ruige dwergvleermuis',
    value: 'Ruige dwergvleermuis',
  },
  {
    label: 'Tweekleurige vleermuis',
    value: 'Tweekleurige vleermuis',
  },
  {
    label: 'Vale vleermuis',
    value: 'Vale vleermuis',
  },
  {
    label: 'Watervleermuis',
    value: 'Watervleermuis',
  },
  {
    label: 'Onbekend', //OTHER
    value: 'Onbekend',
  },
  // BIRD
  {
    label: 'Boerenzwaluw',
    value: 'Boerenzwaluw',
  },
  {
    label: 'Boomklever',
    value: 'Boomklever',
  },
  {
    label: 'Boomkruiper',
    value: 'Boomkruiper',
  },
  {
    label: 'Bosuil',
    value: 'Bosuil',
  },
  {
    label: 'Ekster',
    value: 'Ekster',
  },
  {
    label: 'Fitis',
    value: 'Fitis',
  },
  {
    label: 'Gaai',
    value: 'Gaai',
  },
  {
    label: 'Gierzwaluw',
    value: 'Gierzwaluw',
  },
  {
    label: 'Groenling',
    value: 'Groenling',
  },
  {
    label: 'Houtduif',
    value: 'Houtduif',
  },
  {
    label: 'Huismus',
    value: 'Huismus',
  },
  {
    label: 'Huiszwaluw',
    value: 'Huiszwaluw',
  },
  {
    label: 'Kauw',
    value: 'Kauw',
  },
  {
    label: 'Kerkuil',
    value: 'Kerkuil',
  },
  {
    label: 'Koolmees',
    value: 'Koolmees',
  },
  {
    label: 'Merel',
    value: 'Merel',
  },
  {
    label: 'Ooievaar',
    value: 'Ooievaar',
  },
  {
    label: 'Pimpelmees',
    value: 'Pimpelmees',
  },
  {
    label: 'Roek',
    value: 'Roek',
  },
  {
    label: 'Roodborst',
    value: 'Roodborst',
  },
  {
    label: 'Spreeuw',
    value: 'Spreeuw',
  },
  {
    label: 'Stadsduif',
    value: 'Stadsduif',
  },
  {
    label: 'Steenuil',
    value: 'Steenuil',
  },
  {
    label: 'Tjiftjaf',
    value: 'Tjiftjaf',
  },
  {
    label: 'Tuinfluiter',
    value: 'Tuinfluiter',
  },
  {
    label: 'Zwarte roodstraat',
    value: 'Zwarte roodstraat',
  },
  {
    label: 'Anders, uitleg andere soort', //other
    value: 'Anders%2C uitleg andere soort',
  },
  //MAMALS
  {
    label: 'Boommarter',
    value: 'Boommarter',
  },
  {
    label: 'Bunzing',
    value: 'Bunzing',
  },
  {
    label: 'Das',
    value: 'Das',
  },
  {
    label: 'Eekhoorn',
    value: 'Eekhoorn',
  },
  {
    label: 'Egel',
    value: 'Egel',
  },
  {
    label: 'Haas',
    value: 'Haas',
  },
  {
    label: 'Hermelijn',
    value: 'Hermelijn',
  },
  {
    label: 'Steenmarter',
    value: 'Steenmarter',
  },
  {
    label: 'Vos',
    value: 'Vos',
  },
  {
    label: 'Wezel',
    value: 'Wezel',
  },
  {
    label: 'Wolf',
    value: 'Wolf',
  },
  // AMPHIBIANS
  {
    label: 'Alpenwatersalamander',
    value: 'Alpenwatersalamander',
  },
  {
    label: 'Amerikaanse stierkikker',
    value: 'Amerikaanse stierkikker',
  },
  {
    label: 'Beekprik',
    value: 'Beekprik',
  },
  {
    label: 'Bittervoorn',
    value: 'Bittervoorn',
  },
  {
    label: 'Boomkikker',
    value: 'Boomkikker',
  },
  {
    label: 'Bruine kikker',
    value: 'Bruine kikker',
  },
  {
    label: 'Driedoornige stekelbaars',
    value: 'Driedoornige stekelbaars',
  },
  {
    label: 'Gewone pad',
    value: 'Gewone pad',
  },
  {
    label: 'Grote modderkruiper',
    value: 'Grote modderkruiper',
  },
  {
    label: 'Heikikker',
    value: 'Heikikker',
  },
  {
    label: 'Kamsalamander',
    value: 'Kamsalamander',
  },
  {
    label: 'Kleine modderkruiper',
    value: 'Kleine modderkruiper',
  },
  {
    label: 'Kleine watersalamander',
    value: 'Kleine watersalamander',
  },
  {
    label: 'Knoflookpad',
    value: 'Knoflookpad',
  },
  {
    label: 'Rivierprik',
    value: 'Rivierprik',
  },
  {
    label: 'Rugstreeppad',
    value: 'Rugstreeppad',
  },
  {
    label: 'Vinpootsalamander',
    value: 'Vinpootsalamander',
  },
  {
    label: 'Vroedmeesterpad',
    value: 'Vroedmeesterpad',
  },
  {
    label: 'Vuursalamander',
    value: 'Vuursalamander',
  },
]

export const useSpecies = (): SearchAutocompleteOption[] =>
  species as SearchAutocompleteOption[]

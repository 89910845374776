import { useTranslation } from 'react-i18next'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { UncertaintyReasons } from '@/types'

type UncertaintyReasonMultiSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
}

export const UncertaintyReasonMultiSelect = ({
  value,
  onChange,
}: UncertaintyReasonMultiSelectProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const reasons = Object.keys(UncertaintyReasons).map((key) => {
    const reason = UncertaintyReasons[
      key as keyof typeof UncertaintyReasons
    ] as string

    return {
      label: t(`uncertaintyReason.${reason}`),
      value: reason,
    } as SearchAutocompleteOption
  })

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={reasons}
      placeholder={t(`placeholders.uncertaintyReason`)}
      sort={true}
      fieldSx={{ width: { xs: 390, md: 360 } }}
    />
  )
}

import { ProjectName, SearchRequestParams, APIErrorResponse } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { QUERY_KEYS } from '@/constants'

export const useProjectsNames = ({
  ...params
}: SearchRequestParams): UseQueryResult<ProjectName[], APIErrorResponse> =>
  useQueryList({
    key: [...QUERY_KEYS.PROJECTS_NAMES, params],
    url: '/projects/names/',
    params: {
      ...params,
    },
  })

import { ModalLayout as EditResearchAreasModalLayout } from '@/components'
import { Box, CircularProgress, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { ProjectBasicInfoForm, ProjectBasicInfo } from '@/components/forms'
import { useProject } from '@/hooks/api/useProject'
import { useMutationWrapper } from '@/hooks'
import { usePatchProject } from '@/hooks/api/usePatchProject'
import { ProjectStatus } from '@/types'

export type EditInformationModalProps = {
  projectId: number
}

export const EditInformationModal = ({
  projectId,
}: EditInformationModalProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const { data: project, isLoading } = useProject({ id: projectId })

  const { mutateAsync: extendProject, isLoading: mutateInProcess } =
    useMutationWrapper(usePatchProject.bind(null, projectId))

  const handleSubmit = async ({
    sharepointUrl,
    address,
    hasGisStatus,
    hasDeliveryStatus,
    status,
    isSmp,
    isOnlyPlanning,
    isFieldReportRequired,
  }: ProjectBasicInfo) => {
    const projectExtendedData = {
      is_smp: isSmp,
      is_only_planning: isOnlyPlanning,
      is_field_report_required: isFieldReportRequired,
      sharepoint_url: sharepointUrl,
      address,
      is_in_gis: hasGisStatus,
      status: hasDeliveryStatus
        ? 'delivered'
        : status === 'delivered'
        ? 'deskwork'
        : (status as ProjectStatus),
    }

    await extendProject(projectExtendedData, {
      successMessageKey: 'projects:detail.successEdit',
    })
  }

  if (isLoading || !project)
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <CircularProgress size="5rem" color="secondary" />
      </Stack>
    )

  return (
    <EditResearchAreasModalLayout title={t('detail.editProjectInfo')}>
      <Box width={{ md: 923, sm: 'auto' }} maxHeight="60vh" overflow="auto">
        <ProjectBasicInfoForm
          project={project}
          onSubmit={handleSubmit}
          controls={
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={mutateInProcess}
            >
              <span>{t('common:buttons.saveChanges')}</span>
            </LoadingButton>
          }
          isReadOnlyDate={true}
          hasStatusFields={true}
          showControlsOnDirty={true}
        />
      </Box>
    </EditResearchAreasModalLayout>
  )
}

import { ReactNode } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material'

type GridAccordionProps = {
  title: string
  children?: ReactNode
}

export const GridAccordion = ({
  title,
  children,
}: GridAccordionProps): JSX.Element => (
  <Accordion defaultExpanded={false}>
    <AccordionSummary
      sx={{
        width: 'fit-content',
        minHeight: 0,
        p: 0,
        '& .MuiAccordionSummary-content': { m: 0 },
      }}
    >
      <Typography variant="caption" lineHeight="16px" color="grey.600">
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ p: 0 }}>
      <Box borderRadius={2} p={2} bgcolor="grey.100">
        {children}
      </Box>
    </AccordionDetails>
  </Accordion>
)

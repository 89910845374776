import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'

type CustomTooltipProps = {
  maxWidth?: number
} & MuiTooltipProps

export const CustomTooltip = styled(
  ({ className, ...props }: CustomTooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  )
)<{ maxWidth?: number }>(({ maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth || 600,
  },
}))

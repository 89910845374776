import * as yup from 'yup'
import translation from 'i18next'

export const passwordCreationValidation = yup
  .string()
  .min(8, translation.t('errors:validation.invalid_password.min_length'))
  .minLowercase(
    1,
    translation.t('errors:validation.invalid_password.min_lowercase')
  )
  .minUppercase(
    1,
    translation.t('errors:validation.invalid_password.min_uppercase')
  )
  .minNumbers(
    1,
    translation.t('errors:validation.invalid_password.min_numbers')
  )
  .minSymbols(
    1,
    translation.t('errors:validation.invalid_password.min_symbols')
  )
  .required()

export const passwordConfirmationValidation = yup
  .string()
  .oneOf(
    [yup.ref('password'), null],
    'errors:validation.invalid_password.confirm'
  )
  .required()

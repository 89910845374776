import { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import translation from 'i18next'
import { Stack, Box } from '@mui/material'
import {
  ObservationLogFormData,
  ObservationLogFormActions,
} from '@/components/forms'
import {
  ControlledTextField,
  ControlledDateTimeField,
  ControlledSelect,
} from '@/components/inputs'
import dayjs from 'dayjs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { validateRecordingNumber } from '@/components/forms/ObservationLogFormContainer/validation'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'
import { FormComponentProps } from '@/types'
import {
  BATS_BEHAVIOUR_REQUIRE_PLACE_FOUND,
  BATS_BIRDS_COUNTING_METHOD_OPTIONS,
  BATS_GEDRAG_OPTIONS,
  BATS_SPECIE_OPTIONS,
  OTHER_BAT_SPECIE_OPTION_VALUE,
  OTHER_GEDRAG_OPTION_VALUE,
  PLACE_FOUND_OPTION_VALUE,
  PLACE_FOUND_OPTIONS,
  PLACE_TYPE_OPTIONS,
} from './fieldsOptions'

const schema = yup.object({
  specie: yup.string().required(),
  other_specie: yup.string().when('specie', {
    is: OTHER_BAT_SPECIE_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  behaviour: yup.string().required(),
  other_behaviour: yup.string().when('behaviour', {
    is: OTHER_GEDRAG_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  recording_number: yup
    .string()
    .transform((value) => (value === '' || value === null ? undefined : value))
    .test(validateRecordingNumber),
  is_place_found: yup
    .string()
    .required()
    .test((value, context) => {
      const behavior = context.parent.behaviour

      if (
        BATS_BEHAVIOUR_REQUIRE_PLACE_FOUND.includes(behavior) &&
        value !== PLACE_FOUND_OPTION_VALUE
      ) {
        return context.createError({
          message: translation.t(
            'errors:validation.isPlaceFoundIncorrectBehaviour',
            {
              placeFound: value,
              behavior,
            }
          ),
        })
      }

      return true
    }),
  place_type: yup.string().when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  first_fledgling_time: yup.date().when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  place_type_notes: yup
    .string()
    .max(256)
    .when('is_place_found', {
      is: PLACE_FOUND_OPTION_VALUE,
      then: (schema) => schema.required(),
    }),
  notes: yup.string().max(256),
  address: yup.string().when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  observed_at: yup.date().required(),
})

export const BatsForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const methods = useForm<ObservationLogFormData>({
    mode: 'onChange',
    defaultValues: {
      specie: '',
      other_specie: '',
      amount: '',
      counting_method: '',
      behaviour: '',
      other_behaviour: '',
      recording_number: '',
      place_type: '',
      first_fledgling_time: '',
      place_type_notes: '',
      notes: '',
      address: '',
      observed_at: '',
      is_place_found: '',
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  useApiErrorData<ObservationLogFormData>(apiErrorData, methods)

  const watchedSpecie = methods.watch('specie')
  const watchedIsPlaceFound = methods.watch('is_place_found')

  useEffect(() => {
    if (
      methods.getValues('is_place_found') === PLACE_FOUND_OPTION_VALUE &&
      !methods.getValues('first_fledgling_time')
    ) {
      methods.setValue('first_fledgling_time', dayjs())
    }
  }, [watchedIsPlaceFound])

  return (
    <FormProvider {...methods}>
      <Stack mt={4} width="100%" alignItems="stretch" spacing={4}>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Box width="35%">
            <ControlledSelect
              control={methods.control}
              name="specie"
              label={t('observation-log.fieldLabels.specie')}
              options={BATS_SPECIE_OPTIONS}
              errorMessagePrefix=""
              fullWidth={true}
            />
          </Box>
          {watchedSpecie === OTHER_BAT_SPECIE_OPTION_VALUE && (
            <Box width="35%">
              <ControlledTextField
                control={methods.control}
                name="other_specie"
                placeholder={t('observation-log.fieldLabels.otherSpecie')}
                errorMessagePrefix=""
              />
            </Box>
          )}
          <Box width="15%">
            <ControlledTextField
              control={methods.control}
              name="amount"
              placeholder={t('observation-log.fieldLabels.amount')}
              type="number"
              errorMessagePrefix=""
            />
          </Box>
          <Box width="15%">
            <ControlledSelect
              control={methods.control}
              name="counting_method"
              label={t('observation-log.fieldLabels.countingMethod')}
              options={BATS_BIRDS_COUNTING_METHOD_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Box width="35%">
            <ControlledSelect
              control={methods.control}
              name="behaviour"
              label={t('observation-log.fieldLabels.behaviour')}
              options={BATS_GEDRAG_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
          {methods.watch('behaviour') === OTHER_GEDRAG_OPTION_VALUE && (
            <Box width="35%">
              <ControlledTextField
                control={methods.control}
                name="other_behaviour"
                placeholder={t('observation-log.fieldLabels.otherBehaviour')}
                errorMessagePrefix=""
              />
            </Box>
          )}
        </Stack>

        <Box width="35%">
          <ControlledSelect
            control={methods.control}
            name="is_place_found"
            label={t('observation-log.fieldLabels.isPlaceFound')}
            options={PLACE_FOUND_OPTIONS}
            errorMessagePrefix=""
          />
        </Box>
        {methods.watch('is_place_found') === PLACE_FOUND_OPTION_VALUE && (
          <>
            <Stack direction="row" spacing={2} justifyContent="flex-start">
              <Box width="35%">
                <ControlledSelect
                  control={methods.control}
                  name="place_type"
                  label={t('observation-log.fieldLabels.placeType')}
                  options={PLACE_TYPE_OPTIONS}
                  errorMessagePrefix=""
                />
              </Box>
              <ControlledDateTimeField
                name="first_fledgling_time"
                control={methods.control}
                label={t('observation-log.fieldLabels.firstFledglingTime')}
                errorMessagePrefix=""
              />
            </Stack>

            <ControlledTextField
              name="address"
              control={methods.control}
              placeholder={t('observation-log.fieldLabels.address')}
              errorMessagePrefix=""
            />

            <ControlledTextField
              control={methods.control}
              name="place_type_notes"
              placeholder={t('observation-log.fieldLabels.placeTypeNotes')}
              multiline={true}
              rows={4}
              errorMessagePrefix=""
              inputProps={{
                style: {
                  padding: 0,
                },
              }}
            />
          </>
        )}

        <Box width="35%">
          <ControlledTextField
            control={methods.control}
            name="recording_number"
            placeholder={t('observation-log.fieldLabels.recordingNumber')}
            errorMessagePrefix=""
          />
        </Box>

        <ControlledTextField
          control={methods.control}
          name="notes"
          placeholder={t('observation-log.fieldLabels.notes')}
          multiline={true}
          rows={3}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          errorMessagePrefix=""
        />

        <ControlledDateTimeField
          name="observed_at"
          control={methods.control}
          label={t('observation-log.fieldLabels.observedAt')}
          errorMessagePrefix=""
        />

        <ObservationLogFormActions
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isLoading={isLoading}
          methods={methods}
        />
      </Stack>
    </FormProvider>
  )
}

import { Planning, User, UserGroups } from '@/types'
import { EventInput } from '@fullcalendar/react'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD } from '@/constants'
import { addUnique, mergeUnique } from '@/utils'

export const getCalendarEvents = (plannings: Planning[], user: User) => {
  const calendarEvents = {} as Record<string, EventInput>

  plannings.forEach(({ research_areas }) => {
    research_areas.forEach(({ research_visits_events }, researchAreaIndex) => {
      research_visits_events.forEach((event) => {
        const index = dayjs(event.start_datetime).format(DATE_FORMAT_YMD)

        calendarEvents[index] = calendarEvents[index] ?? {}
        const calendarEvent = calendarEvents[index]

        calendarEvent.researchAreaLabels =
          calendarEvent.researchAreaLabels ?? []

        calendarEvent.researchers = calendarEvent.researchers ?? []

        calendarEvent.start = index

        calendarEvent.researchMoments =
          calendarEvent.researchMoments === undefined
            ? 1
            : calendarEvent.researchMoments + 1

        calendarEvent.researchers = mergeUnique(
          calendarEvent.researchers,
          event.researchers.map(
            (researcher) => `${researcher.first_name} ${researcher.last_name}`
          )
        )

        if (user.groups.includes(UserGroups.MANAGER))
          calendarEvent.managerReminder = event.researchers.some(
            ({ id }) => id === user.id
          )

        calendarEvent.researchAreaLabels = addUnique(
          calendarEvent.researchAreaLabels,
          research_areas[researchAreaIndex].id
        )
      })
    })
  })

  return calendarEvents
}

import { HTMLInputTypeAttribute, ReactNode } from 'react'
import { Box, InputLabel, TextField, TextFieldProps } from '@mui/material'
import { Control, Controller, Path } from 'react-hook-form'
import { ApiErrorData } from '@/types'
import {
  capitalizeFirstLetter,
  getFieldErrorMessage,
  hasFieldError,
} from '@/utils'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { FieldValues } from 'react-hook-form/dist/types/fields'

export type ControlledTextFieldProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  label?: string
  labelSx?: SxProps<Theme>
  placeholder?: string
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  defaultType?: HTMLInputTypeAttribute
  showPassword?: boolean
  endAdornment?: ReactNode
  errorMessagePrefix?: string
  apiErrorData?: ApiErrorData
  apiFieldName?: string
  isOptional?: boolean
  isFieldStateErrorShown?: boolean
  readOnly?: boolean
} & TextFieldProps

export const ControlledTextField = <T extends FieldValues>({
  label,
  labelSx,
  name,
  control,
  placeholder,
  fullWidth = true,
  variant = 'outlined',
  defaultType = 'text',
  showPassword,
  endAdornment,
  errorMessagePrefix = 'errors:validation',
  apiErrorData,
  apiFieldName,
  isOptional = false,
  isFieldStateErrorShown = true,
  readOnly = false,
  ...props
}: ControlledTextFieldProps<T>): JSX.Element => {
  const { t } = useTranslation()

  const type =
    showPassword === undefined || showPassword ? defaultType : 'password'

  const { inputProps, ...restProps } = props

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'inherit' }}>
      {label && (
        <InputLabel sx={{ mb: 2, ...labelSx }}>
          {label} {isOptional && t('forms:labels.optional')}
        </InputLabel>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            id={name}
            fullWidth={fullWidth}
            onChange={onChange}
            value={value}
            label={placeholder}
            variant={variant}
            type={type}
            error={!!error || hasFieldError(apiFieldName ?? name, apiErrorData)}
            helperText={
              isFieldStateErrorShown && error?.message
                ? t(
                    errorMessagePrefix
                      ? `${errorMessagePrefix}.${error.message}`
                      : error.message
                  )
                : capitalizeFirstLetter(
                    getFieldErrorMessage(apiFieldName ?? name, apiErrorData)
                  )
            }
            inputProps={{
              ...inputProps,
              'data-testid': name,
            }}
            InputProps={{
              endAdornment,
              readOnly,
            }}
            {...restProps}
          />
        )}
      />
    </Box>
  )
}

import { TableHead, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

const columnNames = [
  'id',
  'company',
  'projectName',
  'projectNumber',
  'researchArea',
  'fieldReportActual',
  'species',
  'researchers',
  'observationDate',
]

export const VisitLogsTableHead = (): JSX.Element => {
  const { t } = useTranslation('dataValidation')

  return (
    <TableHead sx={{ borderRadius: 8 }}>
      <TableRow>
        {columnNames.map((columnName) => (
          <TableCell key={columnName}>
            {t(`columnNames.${columnName}`)}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'

type IsPlaceFoundSelectProps = {
  value?: boolean
  onChange: (value: boolean | undefined) => void
}

export const IsPlaceFoundSelect = ({
  value,
  onChange,
}: IsPlaceFoundSelectProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const options = [
    {
      label: t('residence.foundIt'),
      value: true,
    },
    {
      label: t('residence.noPlaceOfResidence'),
      value: false,
    },
  ]

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextField {...params} label={t('placeholders.residence')} />
      )}
      value={
        value !== undefined
          ? {
              label: value
                ? t('residence.foundIt')
                : t('residence.noPlaceOfResidence'),
              value,
            }
          : null
      }
      onChange={(_event, option) => {
        onChange(option ? (option.value as boolean) : undefined)
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{ width: 240 }}
    />
  )
}

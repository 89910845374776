import { Box, Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectProtocol } from '@/types'

export const SpeciesResearchTypes = ({
  protocol,
  event_original_protocols,
  visit_protocol_id,
  speciesColumnWidth,
  researchTypesColumnWidth,
}: {
  protocol: ProjectProtocol
  event_original_protocols?: number[] | null
  visit_protocol_id?: number
  speciesColumnWidth: string
  researchTypesColumnWidth: string
}): JSX.Element => {
  const { t } = useTranslation()

  const processedSpecies: number[] = []

  const visit_protocol_original_protocols = protocol.rounds.find(
    (r) => r.id === visit_protocol_id
  )?.original_protocols

  return (
    <Stack direction="column">
      <Stack direction="row">
        <Typography
          variant="caption"
          color="grey.600"
          lineHeight="16px"
          width={speciesColumnWidth}
        >
          {t('projects:protocolsStep.researchSpecies')}
        </Typography>
        <Typography
          variant="caption"
          color="grey.600"
          lineHeight="16px"
          width={researchTypesColumnWidth}
        >
          {t('projects:protocolsStep.researchTypes')}
        </Typography>
      </Stack>
      {protocol.original_protocols.map(({ id, specie, research_type }) => {
        if (
          event_original_protocols &&
          event_original_protocols.length &&
          !event_original_protocols.includes(id) &&
          !event_original_protocols.includes(protocol.id)
        )
          return null

        if (
          visit_protocol_original_protocols &&
          visit_protocol_original_protocols.length &&
          !visit_protocol_original_protocols.includes(id) &&
          !visit_protocol_original_protocols.includes(protocol.id)
        )
          return null

        if (processedSpecies.includes(specie.id)) return null
        processedSpecies.push(specie.id)

        return (
          <Box key={id}>
            {processedSpecies.length > 1 ? (
              <Divider sx={{ borderColor: 'border.main', my: '2px' }} />
            ) : null}
            <Stack direction="row">
              <Box width={speciesColumnWidth}>{specie.name}</Box>
              <Box width={researchTypesColumnWidth}>
                {research_type.name}
                {protocol.original_protocols.map((op) => {
                  if (op.id !== id && op.specie.id === specie.id)
                    return `, ${op.research_type.name}`
                })}
              </Box>
            </Stack>
          </Box>
        )
      })}
    </Stack>
  )
}

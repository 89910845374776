import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { useAnimals } from '@/hooks/api'

type AnimalSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
  placeholder?: string
}

export const AnimalSelect = ({
  value,
  onChange,
  placeholder,
}: AnimalSelectProps): JSX.Element => {
  const { data: animals } = useAnimals({})

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={animals?.results?.map(
        ({ id, name }) =>
          ({
            label: name,
            value: id.toString(),
          } as SearchAutocompleteOption)
      )}
      placeholder={placeholder}
      sort={true}
      fieldSx={{ width: 280 }}
    />
  )
}

import { Calendar } from './Calendar'
import { Stack } from '@mui/material'
import { useModal } from '@/hooks'
import { DailyPlanningModal } from '@/features/planning/DailyPlanningModal'
import { usePlanningData } from '@/features/planning/hooks'

export const ResearcherPlanning = (): JSX.Element => {
  const { open } = useModal()

  const { plannings, setCalendarDateRange } = usePlanningData()

  const handleOpenDailyPlanning = (date: Date | null) => {
    open(
      <>
        {plannings && date && (
          <DailyPlanningModal plannings={plannings} day={date} />
        )}
      </>
    )
  }

  // const searchBar = (
  //   <SearchTextField
  //     searchValue={searchParam}
  //     onChange={setSearchParam}
  //     placeholder={t(`placeholders.searchProjectClient`)}
  //   />
  // )

  return (
    <Calendar
      setCalendarDateRange={setCalendarDateRange}
      plannings={plannings}
      onDayClick={handleOpenDailyPlanning}
      rightBar={
        <Stack direction="row" spacing={6.75}>
          {/*<OpenAvailabilityModalButton />*/}
        </Stack>
      }
    />
  )
}

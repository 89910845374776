import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  SendPasswordResetError,
  SendPasswordResetResponse,
} from '@/types/api/auth'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse } from '@/types'

type UsePostProjectEventsGenerate = {
  projectId: number
}

export const usePostProjectEventsGenerate = ({
  projectId,
}: UsePostProjectEventsGenerate): UseMutationResult<
  unknown,
  APIErrorResponse<SendPasswordResetError>,
  void
> => {
  const client = useApiClient()

  return useMutation(() =>
    client
      .post<SendPasswordResetResponse>(
        `plannings/${projectId}/events/generate/`
      )
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}

import { Paper, Table, TableContainer } from '@mui/material'
import { VisitLogsTableBody } from './VisitLogsTableBody'
import { VisitLogsTableHead } from './VisitLogsTableHead'
import { VisitLog } from '@/types'

export type VisitLogsTableProps = {
  rows: VisitLog[]
  showVisitLogDetails: (id: number) => void
}

export const VisitLogsTable = ({
  rows,
  showVisitLogDetails,
}: VisitLogsTableProps): JSX.Element => (
  <TableContainer component={Paper} data-testid="visitLogs-overview-table">
    <Table stickyHeader={true}>
      <VisitLogsTableHead />
      <VisitLogsTableBody
        rows={rows}
        showVisitLogDetails={showVisitLogDetails}
      />
    </Table>
  </TableContainer>
)

import {
  Client,
  PaginatedListResponse,
  SearchRequestParams,
  APIErrorResponse,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'

export const useClients = (
  { search, ...params }: SearchRequestParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<Client>, APIErrorResponse> =>
  useQueryList({
    url: '/clients/',
    options: {
      enabled,
    },
    params: {
      ...params,
      ...(search ? { search } : {}),
    },
  })

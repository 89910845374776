// en
import commonEN from './en/common.json'
import planningEN from './en/planning.json'
import formsEN from './en/forms.json'
import errorEN from './en/errors.json'
import projectsEN from './en/projects.json'
import dataValidationEN from './en/data-validation.json'
import monitoringEN from './en/monitoring.json'
import observationLogsEN from './en/observation-logs.json'

//nl
import commonNL from './nl/common.json'
import planningNL from './nl/planning.json'
import formsNL from './nl/forms.json'
import errorNL from './nl/errors.json'
import projectsNL from './nl/projects.json'
import dataValidationNL from './nl/data-validation.json'
import monitoringNL from './nl/monitoring.json'
import observationLogsNL from './nl/observation-logs.json'

export const resources = {
  en: {
    common: commonEN,
    planning: planningEN,
    forms: formsEN,
    errors: errorEN,
    projects: projectsEN,
    dataValidation: dataValidationEN,
    monitoring: monitoringEN,
    observationLogs: observationLogsEN,
  },
  nl: {
    common: commonNL,
    planning: planningNL,
    forms: formsNL,
    errors: errorNL,
    projects: projectsNL,
    dataValidation: dataValidationNL,
    monitoring: monitoringNL,
    observationLogs: observationLogsNL,
  },
}

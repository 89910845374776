import { UserGroups } from '@/types'

export type NavigationItemType = {
  href: string
  title: string
  group?: UserGroups[]
}

export const navigationItems = [
  {
    href: '/',
    title: 'menu.projects',
    group: [UserGroups.MANAGER, UserGroups.CLIENT],
  },
  {
    href: '/planning',
    title: 'menu.planning',
    group: [UserGroups.MANAGER],
  },
  {
    href: '/monitoring',
    title: 'menu.monitoring',
    group: [UserGroups.MANAGER],
  },
  {
    href: '/data-validation',
    title: 'menu.dataValidation',
    group: [UserGroups.MANAGER],
  },
  {
    href: '/observation-logs',
    title: 'menu.observationLogs',
    group: [UserGroups.MANAGER],
  },
] as NavigationItemType[]

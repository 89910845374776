import {
  Button,
  Divider,
  CardHeader,
  CardContent,
  CardActions,
  Card,
} from '@mui/material'
import { useModal } from '@/hooks'
import { ReactChildren } from '@/types'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'
import { SxProps } from '@mui/system'

export type ModalLayoutProps = {
  title: string
  headerAction?: ReactNode
  footerActions?: ReactNode
  cardContentSx?: SxProps
  aboveHeaderBar?: ReactNode
  belowHeaderBar?: ReactNode
} & ReactChildren

export const ModalLayout = ({
  title,
  headerAction,
  children,
  footerActions: footerActionsProp,
  cardContentSx = {},
  aboveHeaderBar,
  belowHeaderBar,
}: ModalLayoutProps): JSX.Element => {
  const { t } = useTranslation('common')

  const { close } = useModal()

  const footerActions =
    footerActionsProp !== undefined ? (
      footerActionsProp
    ) : (
      <Button onClick={() => close()}>{t('buttons.closeWindow')}</Button>
    )

  return (
    <Card variant="outlined" sx={{ p: 4 }}>
      {aboveHeaderBar && aboveHeaderBar}
      <CardHeader
        action={headerAction}
        title={title}
        sx={{ py: aboveHeaderBar ? 2 : 3 }}
      />
      {belowHeaderBar && belowHeaderBar}

      <Divider variant="middle" sx={{ mx: 4, my: 1 }} />

      <CardContent
        sx={{ pt: 4, ...cardContentSx, maxHeight: '70vh', overflowY: 'auto' }}
      >
        {children}
      </CardContent>

      {footerActions && (
        <>
          <Divider sx={{ mx: -5, my: 4, borderColor: 'border.main' }} />
          <CardActions>{footerActions}</CardActions>
        </>
      )}
    </Card>
  )
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Stack } from '@mui/material'
import { ResearchArea } from './ResearchArea'
import { CardLayout } from '@/components'
import { ResearchAreaFormContainer } from '@/components/forms'
import { EmptyData } from '@/components/ui/EmptyData'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Edit, XSquare } from 'react-feather'
import { ProjectStatus, ResearchArea as ResearchAreaType } from '@/types'

export type ResearchAreasProps = {
  areas: ResearchAreaType[]
  projectId: number
  projectStatus: ProjectStatus
}

export const ResearchAreas = ({
  areas,
  projectId,
  projectStatus,
}: ResearchAreasProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const [isEdit, setIsEdit] = useState(false)
  const [isUnsaved, setIsUnsaved] = useState(false)

  const handleEditAreas = () => {
    setIsEdit(!isEdit)
  }

  return (
    <CardLayout
      title={t('areas.title')}
      count={areas.length || undefined}
      headerAction={
        !!areas.length && (
          <>
            <Box m={2}>
              {isUnsaved ? (
                t('areas.editingActive')
              ) : (
                <Button
                  onClick={handleEditAreas}
                  variant="textThin"
                  endIcon={!isEdit ? <Edit size={14} /> : <XSquare size={14} />}
                >
                  {!isEdit ? t('areas.editAreas') : t('areas.closeEditing')}
                </Button>
              )}
            </Box>
          </>
        )
      }
    >
      {isEdit ? (
        <ResearchAreaFormContainer
          projectId={projectId}
          setIsNextStepDisabled={setIsUnsaved}
          isPlanningCreated={projectStatus !== 'new'}
        />
      ) : (
        <Stack spacing={4}>
          {areas
            .sort((a, b) => a.id - b.id)
            .map((area, index) =>
              area.id ? (
                <ResearchArea
                  open={index === 0}
                  key={area.id}
                  area={area}
                  projectId={projectId}
                />
              ) : null
            )}

          {!areas.length && (
            <EmptyData
              titleLocaleKey={t('projects:emptyAreas')}
              buttonLocaleKey={t('projects:addArea')}
              icon={faArrowRight}
              onButtonClick={handleEditAreas}
            />
          )}
        </Stack>
      )}
    </CardLayout>
  )
}

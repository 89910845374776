import { ApiErrorData, APIErrorResponse } from '@/types'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { capitalizeFirstLetter } from '@/utils/stringUtils'
import translation from 'i18next'
import { FieldError } from 'react-hook-form'

export const hasFieldError = (
  fieldName: string,
  apiErrorData?: ApiErrorData
): boolean => !!apiErrorData && fieldName in apiErrorData

export const getFieldErrorMessage = (
  fieldName: string,
  apiErrorData?: ApiErrorData
): string => {
  const fieldError =
    apiErrorData && fieldName in apiErrorData
      ? apiErrorData[fieldName]
      : undefined

  if (fieldError) {
    return Array.isArray(fieldError) ? fieldError[0] : fieldError
  }

  return ''
}

export const renderFieldErrorMessage = (
  error: APIErrorResponse,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey,
  generalErrorMessage: string,
  setApiErrorData?: (error: ApiErrorData | { detail: string }) => void
): void => {
  if (setApiErrorData && error.status === 400) {
    setApiErrorData(error.data)
  } else {
    enqueueSnackbar(
      error.status === 401 ? error.data.detail : generalErrorMessage,
      {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }
    )
  }
}

/**
 * Parse an error into a single error message string
 */
export const getErrorMessage = (
  error: APIErrorResponse,
  message = ''
): string => {
  if (error?.status === 400) {
    return Object.keys(error.data)
      .map((field) => `${field}: ${getFieldErrorMessage(field, error.data)}`)
      .join(', ')
  }

  if (error?.status === 401) {
    const errorMessage = error.data.detail

    return Array.isArray(errorMessage) ? errorMessage[0] : errorMessage
  }

  return message
}

type HelpTextProps = {
  isFieldStateErrorShown: boolean
  error: FieldError | undefined
  errorMessagePrefix: string
  apiFieldName: string | undefined
  apiErrorData: ApiErrorData | undefined
  name: string
}

export const getHelpText = ({
  isFieldStateErrorShown,
  error,
  errorMessagePrefix,
  apiFieldName,
  apiErrorData,
  name,
}: HelpTextProps) =>
  isFieldStateErrorShown && error?.message
    ? translation.t(
        errorMessagePrefix
          ? `${errorMessagePrefix}.${error.message}`
          : error.message
      )
    : capitalizeFirstLetter(
        getFieldErrorMessage(apiFieldName ?? name, apiErrorData)
      )

import { ReactChildren } from '@/types'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@/contexts/ThemeProvider'
import { ReactQueryProvider } from '@/contexts/ReactQueryProvider'
import { ApiProvider } from '@/contexts/ApiContext'
import { SnackbarProvider } from '@/contexts/SnackbarProvider'
import { ModalProvider } from '@/contexts/ModalContext'
import { AuthProvider } from '@/contexts/AuthContext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

/**
 * ContextProviders wrapper component to abstract all providers into a single
 * provider that is used in the App component.
 */
export const ContextProviders = ({ children }: ReactChildren): JSX.Element => (
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider>
        <ReactQueryProvider>
          <ApiProvider>
            <SnackbarProvider>
              <ModalProvider>
                <AuthProvider>{children}</AuthProvider>
              </ModalProvider>
            </SnackbarProvider>
          </ApiProvider>
        </ReactQueryProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </HelmetProvider>
)

import { Navigate } from 'react-router-dom'

import { useAuth } from '@/hooks/useAuth'
import { ReactNode } from 'react'
import { ROUTES_PATH } from '@/constants'

interface AuthenticationGuardProps {
  children: ReactNode
}

/**
 * Wrap with that component routes that only available for authenticated users (logged in)
 */

export const AuthenticationGuard = ({ children }: AuthenticationGuardProps) => {
  const { isAuthenticated, isInitialized } = useAuth()

  if (isInitialized && !isAuthenticated) {
    return <Navigate to={ROUTES_PATH.LOGIN} />
  }

  return <>{children}</>
}

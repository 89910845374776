import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { PatchProject, Project } from '@/types/api/entities'
import { APIErrorResponse } from '@/types'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { getUseProjectKey } from '@/hooks/api/useProject'
import { QUERY_KEYS } from '@/constants'

export const usePatchProject = (
  id: number
): UseMutationResult<Project, APIErrorResponse, PatchProject> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch(`projects/${id}/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data) => {
        const queryKey = getUseProjectKey(id)

        queryClient.setQueryData(queryKey, data)
        queryClient.invalidateQueries(QUERY_KEYS.PROJECTS)
      },
      onError: (error) => {
        alert(error)
      },
    }
  )
}

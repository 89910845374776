import { Box, Collapse, Grid, Stack, Tooltip, Typography } from '@mui/material'
import {
  ManagerResearchVisitEvent,
  ResearchArea as ResearchAreaType,
} from '@/types'
import { useTranslation } from 'react-i18next'
import { CardAttribute, SharepointUrlLink } from '@/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { formatMinutesDuration } from '@/utils/date'
import { ResearchAreaEvents } from '@/features/projectDetail/ResearchAreas/components/ResearchAreaEvents'
import { usePlanningsEventsManager } from '@/hooks'

export type CardLayoutProps = {
  area: ResearchAreaType
  open?: boolean
  projectId: number
}

export const ResearchArea = ({
  area,
  open,
  projectId,
}: CardLayoutProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const [isOpen, setIsOpen] = useState(open)
  const { data: projectEvents, isLoading: projectEventsLoading } =
    usePlanningsEventsManager({
      limit: 1000,
      offset: 0,
      project: projectId.toString(),
    })
  const areaEvents = useMemo<ManagerResearchVisitEvent[]>(
    () =>
      projectEvents?.results.filter(
        (event) => event.research_area_id === area.id
      ) ?? [],
    [projectEvents]
  )

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const totalDuration = area.protocol_events_total_duration

  const Warning = (
    <Tooltip title={t('areas.needPlanEvents')}>
      <WarningAmberIcon color="warning" />
    </Tooltip>
  )

  const showWarning =
    area.protocol_events_count !== undefined &&
    area.events_count !== undefined &&
    area.protocol_events_count > area.events_count

  return (
    <>
      <Stack
        sx={{ ':hover': { cursor: 'pointer' } }}
        onClick={handleClick}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2}
      >
        <Typography variant="h5">
          {`${t('areas.researchAreaNumber', { areaNumber: area.id })} - ${
            area.location_name
          }`}
        </Typography>
        <Stack direction="row" spacing={4}>
          {showWarning && Warning}
          <Box color="secondary.main">
            {!isOpen ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} />
            )}
          </Box>
        </Stack>
      </Stack>

      <Collapse in={isOpen} timeout="auto">
        <Grid container={true} rowSpacing={5} columnSpacing={4} mb={3}>
          <Grid item={true} xs={6} sm={3}>
            <CardAttribute
              title={t('areas.areaNumber')}
              value={area.id ?? '-'}
            />
          </Grid>
          <Grid item={true} xs={6} sm={3}>
            <CardAttribute
              title={t('areas.clientAreaNumber')}
              value={!!area.client_area_number ? area.client_area_number : '-'}
            />
          </Grid>

          <Grid item={true} xs={6} sm={3}>
            <CardAttribute
              title={t('areas.amountHours')}
              value={totalDuration ? formatMinutesDuration(totalDuration) : '-'}
            />
          </Grid>

          <Grid item={true} sm={6}>
            <CardAttribute
              title={t('areas.researchers')}
              value={
                area.researchers?.map(
                  ({ first_name, last_name }, i) =>
                    `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                ) ?? t('areas.noResearchers')
              }
            />
          </Grid>

          <Grid item={true} sm={6}>
            <CardAttribute
              title={t('areas.numberOfResearchers')}
              value={
                <>
                  {area.required_number_of_researchers_for_morning ? (
                    <Typography>
                      {t('areas.morningRoundLabel')}:{' '}
                      {area.required_number_of_researchers_for_morning}
                    </Typography>
                  ) : null}
                  {area.required_number_of_researchers_for_day ? (
                    <Typography>
                      {t('areas.dayRoundLabel')}:{' '}
                      {area.required_number_of_researchers_for_day}
                    </Typography>
                  ) : null}
                  {area.required_number_of_researchers_for_evening ? (
                    <Typography>
                      {t('areas.eveningRoundLabel')}:{' '}
                      {area.required_number_of_researchers_for_evening}
                    </Typography>
                  ) : null}
                  {area.required_number_of_researchers_for_night ? (
                    <Typography>
                      {t('areas.nightRoundLabel')}:{' '}
                      {area.required_number_of_researchers_for_night}
                    </Typography>
                  ) : null}
                </>
              }
            />
          </Grid>

          <Grid item={true} sm={12}>
            <CardAttribute
              title={t('areas.address')}
              value={area.location_name ?? '-'}
            />
          </Grid>
          <Grid item={true} sm={12}>
            <CardAttribute
              title={t('areas.notes')}
              value={!!area.notes ? area.notes : '-'}
            />
          </Grid>
          {area.report_sharepoint_url && (
            <Grid item={true} sm={12}>
              <CardAttribute
                title={t('areas.reportSharepointUrl')}
                value={
                  <SharepointUrlLink
                    sharepointUrl={area.report_sharepoint_url}
                  />
                }
              />
            </Grid>
          )}
        </Grid>

        {areaEvents.length !== 0 && (
          <Typography py={3} variant="h6">
            {t('areas.events')}
          </Typography>
        )}

        <ResearchAreaEvents
          researchArea={area}
          events={areaEvents}
          isLoading={projectEventsLoading}
          canGoToDetail={true}
        />
      </Collapse>
    </>
  )
}

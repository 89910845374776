import { Navigate } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { ReactNode } from 'react'

export type GuestGuardProps = {
  children: ReactNode
}

/**
 * Wrapper for routes that only available for authenticated users (logged in)
 */
export const GuestGuard = ({ children }: GuestGuardProps): JSX.Element => {
  const { isAuthenticated, isInitialized } = useAuth()

  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

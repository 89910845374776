import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, Button, Pagination } from '@mui/material'
import { ProjectsTable } from '@/features'
import { AuthorizationGuard, EmptyListData, Loader } from '@/components'
import { SearchTextField } from '@/components/inputs'
import { useFilters, useProjects } from '@/hooks'
import { SearchRequestParams, UserGroups } from '@/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { theme } from '@/theme'
import {
  DEFAULT_PROJECT_LIMIT,
  DEFAULT_PROJECT_OFFSET,
  ROUTES_PATH,
} from '@/constants'

export const ProjectsOverviewContainer = (): JSX.Element => {
  const { t } = useTranslation('projects')
  const navigate = useNavigate()

  const defaultFiltersParams: SearchRequestParams = {
    search: undefined,
    limit: DEFAULT_PROJECT_LIMIT,
    offset: DEFAULT_PROJECT_OFFSET,
  }

  const {
    filterParams,
    handleFieldValueChange,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
  } = useFilters(defaultFiltersParams)

  const { data, isLoading } = useProjects(filterParams)

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)
    }
  }, [data])

  return (
    <Stack data-testid="projects-overview-table">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={5.75}
      >
        <Typography color={theme.palette.green[600]}>
          {t('amount', { amount: data?.count ?? 0 })}
        </Typography>
        <Stack direction="row" spacing={6.75}>
          <SearchTextField
            searchValue={filterParams.search}
            onChange={(value) => handleFieldValueChange(value, 'search')}
            placeholder={t('searchPlaceholder')}
            width="380px"
          />
          <AuthorizationGuard
            allowedGroups={[UserGroups.MANAGER]}
            redirect={false}
          >
            <Button onClick={() => navigate(ROUTES_PATH.PROJECT_CREATE)}>
              {t('action.create')}
              <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={faPlus} />
            </Button>
          </AuthorizationGuard>
        </Stack>
      </Stack>

      {data && data.count ? (
        <>
          <ProjectsTable rows={data.results} />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="projects:noProjects" />
          )}
        </Box>
      )}
    </Stack>
  )
}

import { Paper, Table, TableContainer } from '@mui/material'
import { ObservationLogsTableHead } from './ObservationLogsTableHead'
import { ObservationLogsTableBody } from './ObservationLogsTableBody'
import { ObservationLog } from '@/types'

export type ObservationLogsTableProps = {
  rows: ObservationLog[]
  showObservationLogDetails: (id: number) => void
}

export const ObservationLogsTable = ({
  rows,
  showObservationLogDetails,
}: ObservationLogsTableProps): JSX.Element => (
  <TableContainer
    component={Paper}
    data-testid="observation-logs-overview-table"
  >
    <Table stickyHeader={true}>
      <ObservationLogsTableHead />
      <ObservationLogsTableBody
        rows={rows}
        showObservationLogDetails={showObservationLogDetails}
      />
    </Table>
  </TableContainer>
)

import { Button, Stack } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ROUTES_PATH_PARTS } from '@/constants'
import { useTranslation } from 'react-i18next'

export const ProjectDetailNavigation = (): JSX.Element => {
  const { t } = useTranslation('projects')

  return (
    <Stack
      direction="row"
      pb={5}
      spacing={2}
      sx={{
        a: { color: 'text.primary' },
        'a.active': {
          fontWeight: 700,
          bgcolor: 'secondary.light',
        },
      }}
    >
      <Button
        variant="text"
        color="secondary"
        size="small"
        component={NavLink}
        to="."
        end={true}
      >
        {t('detailLinks.projectInfo')}
      </Button>

      <Button
        variant="text"
        color="success"
        size="small"
        component={NavLink}
        to={ROUTES_PATH_PARTS.PLANNING}
      >
        {t('detailLinks.planning')}
      </Button>
    </Stack>
  )
}

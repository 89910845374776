import { useAuth } from '@/hooks/useAuth'
import { useResearcherAvailability, useResearcherCommitment } from '@/hooks'
import { User } from '@/types'
import { useEffect } from 'react'
import { AvailabilityFormData } from '@/features'
import { UseFormReset } from 'react-hook-form'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { getWeeksInYear } from '@/utils/date'

type UseInitDefaultValuesParams = {
  reset: UseFormReset<AvailabilityFormData>
}

export const useInitDefaultValues = ({ reset }: UseInitDefaultValuesParams) => {
  const { user } = useAuth()

  const { data: researcherAvailability } = useResearcherAvailability({
    researcherId: (user as User).id,
  })

  const { data: researcherCommitment } = useResearcherCommitment({
    researcherId: (user as User).id,
  })

  useEffect(() => {
    if (!researcherAvailability || !researcherCommitment) {
      return
    }

    const apiDefaultValues = {
      availability: {},
      commitment: {},
    } as AvailabilityFormData

    researcherAvailability.forEach(
      ({
        date,
        available_for_morning_round: morning,
        available_for_day_round: day,
        available_for_evening_round: evening,
      }) => {
        if (morning || evening || day) {
          apiDefaultValues.availability[date] = {
            availableForMorningRound: morning,
            availableForDayRound: day,
            availableForEveningRound: evening,
          }
        }
      }
    )

    dayjs.extend(localeData)
    const currentYear = dayjs().get('year')
    const amountOfWeeks = getWeeksInYear(currentYear)

    for (let i = 1; i <= amountOfWeeks; i++) {
      researcherCommitment.forEach(({ year, week, max_days_available }) => {
        if (year === currentYear && week === i) {
          apiDefaultValues.commitment[i] = max_days_available
        }
      })
      if (apiDefaultValues.commitment[i] === undefined) {
        apiDefaultValues.commitment[i] = 7
      }
    }

    reset(apiDefaultValues)
  }, [researcherAvailability, researcherCommitment])
}

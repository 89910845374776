import { Theme } from '@mui/material'
import { checkboxClasses } from '@mui/material/Checkbox'

import { theme } from './theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textThin: true
  }
  interface ButtonPropsColorOverrides {
    buttonGrey: true
  }
}

export const components: Theme['components'] = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '5px',
        fontWeight: 600,
        lineHeight: '19px',
        ':disabled': {
          opacity: 0.5,
        },
        '> .MuiLoadingButton-loadingIndicator': {
          color: theme.palette.green['800'],
        },
        // for component={Link}
        '&.MuiTypography-root': {
          backgroundColor: 'transparent',
        },
      },
      sizeMedium: {
        padding: '10px 24px',
      },
      sizeSmall: {
        padding: '8px 16px',
        lineHeight: '22px',
      },
    },
    variants: [
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: theme.palette.blue[300],
          ':hover': {
            color: theme.palette.primary.main,
          },
        },
      },
      {
        props: { variant: 'textThin' },
        style: {
          padding: 0,
          margin: 0,
          ':hover': {
            backgroundColor: 'transparent',
          },
          '> .MuiButton-startIcon': {
            marginLeft: 0,
          },
          '> .MuiButton-endIcon': {
            marginRight: 0,
          },
        },
      },
      {
        props: { variant: 'textThin', color: 'primary' },
        style: {
          color: theme.palette.blue[300],
          ':hover': {
            color: theme.palette.primary.main,
          },
        },
      },
      {
        props: { variant: 'textThin', color: 'secondary' },
        style: {
          color: theme.palette.green[500],
          ':hover': {
            color: theme.palette.green[600],
          },
        },
      },
      {
        props: { variant: 'textThin', color: 'buttonGrey' },
        style: {
          color: theme.palette.grey[600],
          ':hover': {
            color: theme.palette.grey[700],
          },
        },
      },
      {
        props: { variant: 'textThin', color: 'warning' },
        style: {
          color: theme.palette.warning.main,
          ':hover': {
            color: theme.palette.warning.dark,
          },
        },
      },
    ],
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        width: 'max-content',
        height: 'max-content',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h3',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '20px',
        padding: '20px',
        boxShadow: theme.shadows[1],
        backgroundImage: 'none',
        borderColor: theme.palette.border.main,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-input': {
          paddingTop: '12px',
          paddingBottom: '12px',
          backgroundColor: theme.palette.common.white,
        },
        '& .MuiInputBase-inputSizeSmall': {
          padding: '4px 8px',
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: `1px solid ${theme.palette.green[500]}`,
          },
        '&.Mui-focused .MuiInputAdornment-root': {
          color: theme.palette.green[500],
        },
        '&.Mui-error .MuiInputAdornment-root': {
          color: theme.palette.error.light,
        },
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <ExpandMoreIcon sx={{ fontSize: '24px' }} />,
    },
    styleOverrides: {
      root: {
        '.MuiInputBase-root': {
          padding: '4px',
        },
        '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
          color: theme.palette.secondary.main,
        },
        '.MuiAutocomplete-listbox .MuiAutocomplete-option': {},
      },
      option: {
        margin: '2px 12px',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        borderRadius: '10px',
        '&[aria-disabled="true"]': {
          backgroundColor: 'transparent',
        },
        '&[aria-selected="true"]': {
          backgroundColor: `${theme.palette.grey['100']} !important`,
        },
      },
      paper: {
        marginTop: '12px',
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        lineHeight: '1.1rem',
        '&.Mui-focused': {
          color: theme.palette.grey[600],
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[400],
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.MuiContainer-root': {
          padding: '0 34px',
          [theme.breakpoints.down('md')]: {
            padding: '0 20px',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '0 12px',
          },
        },
        '&.MuiContainer-maxWidthXl': {
          maxWidth: '1600px',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        '&.MuiAlert-filled': {
          color: theme.palette.common.white,
        },
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        filter: 'drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.07))',
        borderRadius: 8,
        '& MuiTable-root': {
          border: `1px solid ${theme.palette.blue[50]}`,
          borderRadius: 2,
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.blue[50]}`,
        borderRadius: 8,
      },
    },
  },

  MuiTableBody: {
    styleOverrides: {
      root: {
        '& tr:last-child': {
          '& td, th': {
            border: 0,
          },
        },
        '& tr:nth-of-type(even)': {
          backgroundColor: theme.palette.grey[50],
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      },
      body: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '120%',
        color: theme.palette.grey[600],
      },
      head: {
        backgroundColor: theme.palette.green[400],
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '19px',
        color: theme.palette.grey[800],
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      root: {
        '& .MuiAvatar-root': {
          height: 33,
          width: 33,
          border: 'none',
          boxShadow: '4px 0px 5px 2px rgba(0, 0, 0, 0.05)',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '150%',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.green[500],
        [`&.${checkboxClasses.checked}`]: {
          color: theme.palette.green[500],
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: '143%',
        fontWeight: 400,
        letterSpacing: '0.17px',
        color: theme.palette.blue[500],
        '&.Mui-selected': {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.blue[500],
          '&:hover': {
            backgroundColor: theme.palette.blue[400],
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      MenuProps: {
        disablePortal: false,
        PaperProps: {
          sx: {
            borderRadius: 1,
            border: `1px solid ${theme.palette.green[500]}`,
            marginTop: 3,
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.08)',
            '& .MuiList-padding': {
              px: 2.75,
            },
            '& .MuiMenuItem-root': {
              my: 1,
              padding: '6px',
              borderRadius: 2.5,
            },
          },
        },
      },
    },
    styleOverrides: {
      icon: {
        color: theme.palette.green[500],
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      elevation: 0,
    },
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMoreIcon sx={{ fontSize: '28px' }} />,
    },
    styleOverrides: {
      content: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '150%',
        color: theme.palette.grey[400],
        '&.Mui-expanded': {
          color: theme.palette.text.primary,
        },
      },
      expandIconWrapper: {
        color: theme.palette.green[500],
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 11,
      },
      arrow: {
        color: theme.palette.common.white,
      },
    },
  },
}

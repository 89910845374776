import { useEffect } from 'react'
import { Stack, Box, Button, Icon } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { SpeciesSelectorFormData } from '@/features'
import { SpeciesAutocomplete } from '@/components/inputs/SpecieAutocomplete'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Specie } from '@/types'

export type AddSpeciesComponentProps = {
  isLoading: boolean
  species?: Specie[]
  onSubmitNewSpecies: (data: SpeciesSelectorFormData) => void
}

export const AddSpeciesComponent = ({
  isLoading,
  species = [],
  onSubmitNewSpecies,
}: AddSpeciesComponentProps) => {
  const { control, reset, formState, getValues, setValue, watch } =
    useForm<SpeciesSelectorFormData>({
      mode: 'onChange',
      defaultValues: { newSpecies: [] },
    })
  const { t } = useTranslation('projects')

  useEffect(() => {
    formState.isSubmitSuccessful && reset()
  }, [formState])

  return (
    <Stack mb={5} pb={4} direction="row">
      <Box width={640}>
        <SpeciesAutocomplete
          name="newSpecies"
          control={control}
          isLoading={isLoading}
          species={species}
        />
      </Box>
      {watch('newSpecies').length ? (
        <Button
          variant="contained"
          sx={{ ml: 4 }}
          startIcon={
            <Icon sx={{ width: 21, height: 21 }}>
              <AddCircleOutlineIcon sx={{ width: 21, height: 21 }} />
            </Icon>
          }
          onClick={() => {
            onSubmitNewSpecies(getValues())
            setValue('newSpecies', [])
          }}
          data-testid="add-research-specie-button"
        >
          {t('areas.addResearchSpecie')}
        </Button>
      ) : (
        <></>
      )}
    </Stack>
  )
}

import { ControlledTextField } from '@/components/inputs'
import { FormComponentProps } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputAdornment, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import * as yup from 'yup'

const resetPasswordSchema = yup.object({
  email: yup.string().email().required(),
})

export type ResetPasswordFormData = {
  email: string
}

export const ResetPasswordForm = ({
  onSubmit,
  isLoading,
  apiErrorData,
}: FormComponentProps<ResetPasswordFormData>) => {
  const { t } = useTranslation('forms')

  const { control, handleSubmit } = useForm<ResetPasswordFormData>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
  })

  return (
    <form onSubmit={handleSubmit(async (data) => onSubmit(data))}>
      <Stack mt={6} spacing={6}>
        <ControlledTextField
          name="email"
          control={control}
          fullWidth={true}
          placeholder={t('placeholders.email')}
          apiFieldName="email"
          apiErrorData={apiErrorData}
          endAdornment={
            <InputAdornment position="end">
              <AlternateEmailIcon />
            </InputAdornment>
          }
        />
        <LoadingButton
          variant="contained"
          fullWidth={true}
          loading={isLoading}
          disabled={isLoading}
          data-testid="submit-reset-password-button"
          type="submit"
        >
          <span>{t('common:buttons.resetPassword')}</span>
        </LoadingButton>
      </Stack>
    </form>
  )
}

import { SearchAutocompleteOption } from '@/components/inputs'

const behaviours = [
  //bats
  {
    label: 'Aantikken boom',
    value: 'Aantikken boom',
  },
  {
    label: 'Aantikken gebouw',
    value: 'Aantikken gebouw',
  },
  {
    label: 'Baltsen',
    value: 'Baltsen',
  },
  {
    label: 'Baltsen vanuit vaste plaats',
    value: 'Baltsen vanuit vaste plaats',
  },
  {
    label: 'Foerageren',
    value: 'Foerageren',
  },
  {
    label: 'Invliegen uit verblijfplaats boom',
    value: 'Invliegen uit verblijfplaats boom',
  },
  {
    label: 'Invliegen uit verblijfplaats gebouw',
    value: 'Invliegen uit verblijfplaats gebouw',
  },
  {
    label: 'Overvliegen',
    value: 'Overvliegen',
  },
  {
    label: 'Sociale roep',
    value: 'Sociale roep',
  },
  {
    label: 'Uitvliegen uit verblijfplaats boom',
    value: 'Uitvliegen uit verblijfplaats boom',
  },
  {
    label: 'Uitvliegen uit verblijfplaats gebouw',
    value: 'Uitvliegen uit verblijfplaats gebouw',
  },
  {
    label: 'Zwermen',
    value: 'Zwermen',
  },
  {
    label: 'Anders, uitleg ander gedrag', //other
    value: 'Anders%2C uitleg ander gedrag',
  },
  //birds
  {
    label: 'Aantikken',
    value: 'Aantikken',
  },
  {
    label: 'Aanwezig',
    value: 'Aanwezig',
  },
  {
    label: 'Baltsend',
    value: 'Baltsend',
  },
  {
    label: 'Baltsend paar in broedbiotoop',
    value: 'Baltsend paar in broedbiotoop',
  },
  {
    label: 'Jagend',
    value: 'Jagend',
  },
  {
    label: 'Nest',
    value: 'Nest',
  },
  {
    label: 'Nest met jongen',
    value: 'Nest met jongen',
  },
  {
    label: 'Nestindicerend gedrag',
    value: 'Nestindicerend gedrag',
  },
  {
    label: 'Op nest',
    value: 'Op nest',
  },
  {
    label: 'Overvliegend, zie richting',
    value: 'Overvliegend%2C zie richting',
  },
  {
    label: 'Paar in broedbiotoop',
    value: 'Paar in broedbiotoop',
  },
  {
    label: 'Roepend',
    value: 'Roepend',
  },
  {
    label: 'Territorium',
    value: 'Territorium',
  },
  {
    label: 'Vernietigd nest',
    value: 'Vernietigd nest',
  },
  {
    label: 'Zingend',
    value: 'Zingend',
  },
  {
    label: 'Dood',
    value: 'Dood',
  },
  {
    label: 'Foeragerend',
    value: 'Foeragerend',
  },
  {
    label: 'Nabij (nest, burcht)',
    value: 'Nabij (nest%2C burcht)',
  },
  {
    label: 'Nest/burcht',
    value: 'Nest/burcht',
  },
  {
    label: 'Nest/burcht met jongen',
    value: 'Nest/burcht met jongen',
  },
  {
    label: 'Sociaal gedrag',
    value: 'Sociaal gedrag',
  },
  {
    label: 'Sporen',
    value: 'Sporen',
  },
  {
    label: 'Territorium gedrag',
    value: 'Territorium gedrag',
  },
  {
    label: 'Geparasiteerd',
    value: 'Geparasiteerd',
  },
  {
    label: 'Overwinterend',
    value: 'Overwinterend',
  },
  {
    label: 'Parend',
    value: 'Parend',
  },
  {
    label: 'Zonnend',
    value: 'Zonnend',
  },
  //insects
]

export const useBehaviours = (): SearchAutocompleteOption[] =>
  behaviours as SearchAutocompleteOption[]

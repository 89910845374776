import { useEffect } from 'react'
import { UseFormReturn, FieldValues, Path } from 'react-hook-form'
import { getFieldErrorMessage, hasFieldError } from '@/utils'
import { ApiErrorData } from '@/types/api'

export const useApiErrorData = <T extends FieldValues>(
  apiErrorData: ApiErrorData | undefined,
  methods: UseFormReturn<T>
) => {
  useEffect(() => {
    if (apiErrorData) {
      Object.keys(methods.control._fields).map((field) => {
        if (hasFieldError(field, apiErrorData)) {
          methods.setError(field as Path<T>, {
            type: 'API error',
            message: getFieldErrorMessage(field, apiErrorData),
          })
        }
      })
    }
  }, [apiErrorData, methods])
}

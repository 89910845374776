import { TableHead, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

const columnNames = [
  'id',
  'company',
  'projectName',
  'projectNumber',
  'researchArea',
  'location',
  'round',
  'species',
  'observationType',
  'observationDate',
  'createdBy',
  'foundSpecies',
  'amount',
  'behaviour',
  'placeTypeFound',
]

export const ObservationLogsTableHead = (): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  return (
    <TableHead sx={{ borderRadius: 8 }}>
      <TableRow>
        {columnNames.map((columnName) => (
          <TableCell key={columnName}>
            {t(`columnNames.${columnName}`)}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

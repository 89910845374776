import { rest } from 'msw'
import {
  eventEmployeesFixture,
  projectPlanningFixture,
  projectsFixture,
  researchAreasFixture,
  researcherAvailabilityFixture,
  researcherCommitmentFixture,
  researchTypeProtocolFixture,
  specieProtocolFixture,
  researchAreaSpeciesFixture,
  researchAreaSpecieFixture,
  projectPlanningPatchEventFixture,
  visitLogsFixture,
  planningEventsFixture,
  visitLogFixture,
  observationLogFixture,
} from './fixtures'
import { VisitLog } from '@/types'

export const baseUrl = (path: string) =>
  new URL(`v1${path}`, process.env.REACT_APP_API_ENDPOINT ?? '').toString()

export const researchEventUsersHandler = (delay = 500) =>
  rest.get(baseUrl('/plannings/researchers/'), (req, res, ctx) =>
    res(ctx.delay(delay), ctx.status(200), ctx.json(eventEmployeesFixture))
  )

export const researcherAvailability = () =>
  rest.get(
    new RegExp(baseUrl('/plannings/researchers/[0-9]+/availability/')),
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(researcherAvailabilityFixture))
  )

export const researcherCommitment = () =>
  rest.get(
    new RegExp(baseUrl('/plannings/researchers/[0-9]+/weekly_availability/')),
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(researcherCommitmentFixture))
  )

export const planningEventsHandler = (delay = 500, isEmpty = false) =>
  rest.get(new RegExp(baseUrl('/plannings/[0-9]+/events/$')), (req, res, ctx) =>
    res(
      ctx.delay(delay),
      ctx.status(200),
      ctx.json(isEmpty ? [] : planningEventsFixture)
    )
  )

export const handleProjectPlanning = () =>
  rest.get(new RegExp(baseUrl('/plannings/[0-9]+/$')), (req, res, ctx) =>
    res(ctx.status(200), ctx.json(projectPlanningFixture))
  )

export const updatePlanningEventHandler = () =>
  rest.patch(
    new RegExp(baseUrl('/plannings/[0-9]+/events/[0-9]+/')),
    (req, res, ctx) => res(ctx.json(projectPlanningPatchEventFixture))
  )

export const projectsHandler = (delay = 250) =>
  rest.get(baseUrl('/projects/'), (req, res, ctx) => {
    const limit = req.url.searchParams.get('limit') ?? 10
    const offset = req.url.searchParams.get('offset') ?? 0
    const results = projectsFixture.slice(+offset, +offset + +limit)

    return res(
      ctx.delay(delay),
      ctx.status(200),
      ctx.json({
        count: projectsFixture.length,
        next: null,
        previous: null,
        results,
      })
    )
  })

export const protocolsResearchTypesHandler = (delay = 500) =>
  rest.get(baseUrl('/protocols/research_types/'), (_req, res, ctx) =>
    res(
      ctx.delay(delay),
      ctx.status(200),
      ctx.json(researchTypeProtocolFixture)
    )
  )

export const protocolsSpeciesHandler = (delay = 500) =>
  rest.get(baseUrl('/protocols/species/'), (_req, res, ctx) =>
    res(ctx.delay(delay), ctx.status(200), ctx.json(specieProtocolFixture))
  )

export const researchAreasHandler = (delay = 500) =>
  rest.get(
    new RegExp(baseUrl('/projects/[0-9]+/research_areas/$')),
    (_req, res, ctx) =>
      res(ctx.delay(delay), ctx.status(200), ctx.json(researchAreasFixture))
  )

export const createResearchAreaHandler = (delay = 500) =>
  rest.post(
    new RegExp(baseUrl('/projects/[0-9]+/research_areas/$')),
    async (req, res, ctx) => {
      const data = await req.json()

      res(
        ctx.delay(delay),
        ctx.status(201),
        ctx.json({
          id: 1,
          ...data,
        })
      )
    }
  )

export const updateResearchAreaHandler = (delay = 500) =>
  rest.patch(
    new RegExp(baseUrl('/projects/[0-9]+/research_areas/[0-9]+/$')),
    async (req, res, ctx) => {
      const data = await req.json()

      res(ctx.delay(delay), ctx.status(200), ctx.json(data))
    }
  )

export const deleteResearchAreaHandler = (delay = 500) =>
  rest.delete(
    new RegExp(baseUrl('/projects/[0-9]+/research_areas/[0-9]+/$')),
    async (_req, res, ctx) => {
      res(ctx.delay(delay), ctx.status(204))
    }
  )

export const createResearchAreaSpeciesHandler = (delay = 500) =>
  rest.post(
    new RegExp(baseUrl('/projects/[0-9]+/research_areas/[0-9]+/species/$')),
    async (_req, res, ctx) => {
      res(
        ctx.delay(delay),
        ctx.status(201),
        ctx.json(researchAreaSpecieFixture)
      )
    }
  )

export const updateResearchAreaSpeciesHandler = (delay = 500) =>
  rest.patch(
    new RegExp(
      baseUrl('/projects/[0-9]+/research_areas/[0-9]+/species/[0-9]+/$')
    ),
    async (_req, res, ctx) => {
      res(
        ctx.delay(delay),
        ctx.status(200),
        ctx.json(researchAreaSpecieFixture)
      )
    }
  )

export const deleteResearchAreaSpeciesHandler = (delay = 500) =>
  rest.delete(
    new RegExp(
      baseUrl('/projects/[0-9]+/research_areas/[0-9]+/species/[0-9]+/$')
    ),
    async (_req, res, ctx) => {
      res(ctx.delay(delay), ctx.status(204))
    }
  )

export const researchAreaSpeciesHandler = (delay = 500) =>
  rest.get(
    new RegExp(baseUrl('/projects/[0-9]+/research_areas/[0-9]+/species/$')),
    (_req, res, ctx) =>
      res(
        ctx.delay(delay),
        ctx.status(200),
        ctx.json(researchAreaSpeciesFixture)
      )
  )

export const visitLogsHandler = (delay = 250) =>
  rest.get(baseUrl('/plannings/visit_logs/'), (req, res, ctx) => {
    const limit = req.url.searchParams.get('limit') ?? 10
    const offset = req.url.searchParams.get('offset') ?? 0
    const results = visitLogsFixture.slice(+offset, +offset + +limit)

    return res(
      ctx.delay(delay),
      ctx.status(200),
      ctx.json({
        count: visitLogsFixture.length,
        next: null,
        previous: null,
        results,
      })
    )
  })

export const putVisitLogApproveHandler = (delay = 500) =>
  rest.put(baseUrl('/plannings/visit_logs/:id/approve/'), (req, res, ctx) => {
    const fixtureData = visitLogFixture as VisitLog

    fixtureData.status = 'approved'
    fixtureData.id = +req.params.id

    return res(ctx.delay(delay), ctx.status(200), ctx.json(fixtureData))
  })

export const putVisitLogDeclineHandler = (delay = 500) =>
  rest.put(
    new RegExp(baseUrl('/plannings/visit_logs/[0-9]+/decline/$')),
    (_req, res, ctx) => {
      const fixtureData = visitLogFixture as VisitLog

      fixtureData.status = 'invalid'

      return res(ctx.delay(delay), ctx.status(200), ctx.json(fixtureData))
    }
  )

export const observationLogsHandler = (delay = 250) =>
  rest.get(baseUrl('/plannings/observation_logs/'), (req, res, ctx) => {
    const limit = req.url.searchParams.get('limit') ?? 10
    const offset = req.url.searchParams.get('offset') ?? 0
    const results = observationLogFixture.slice(+offset, +offset + +limit)

    return res(
      ctx.delay(delay),
      ctx.status(200),
      ctx.json({
        count: observationLogFixture.length,
        next: null,
        previous: null,
        results,
      })
    )
  })

export const handlers = [
  projectsHandler(),
  researchEventUsersHandler(),
  researcherAvailability(),
  protocolsResearchTypesHandler(),
  protocolsSpeciesHandler(),
  researchAreasHandler(),
  researchAreaSpeciesHandler(),
  createResearchAreaHandler(),
  updateResearchAreaHandler(),
  deleteResearchAreaHandler(),
  createResearchAreaSpeciesHandler(),
  updateResearchAreaSpeciesHandler(),
  deleteResearchAreaSpeciesHandler(),
  handleProjectPlanning(),
  updatePlanningEventHandler(),
  visitLogsHandler(),
  planningEventsHandler(),
  putVisitLogApproveHandler(),
  putVisitLogDeclineHandler(),
  observationLogsHandler(),
]

import { useEffect, useState } from 'react'
import { ModalLayout } from '@/components'
import { Button, Link } from '@mui/material'
import { WeekSelection } from '../WeekSelection'
import { useTranslation } from 'react-i18next'
import { DaysSelection } from '../DaysSelection'
import { useForm } from 'react-hook-form'
import { ArrowLeft } from 'react-feather'
import { CheckAll } from '../CheckAll'
import { ModalActions } from '../ModalActions'
import { useInitDefaultValues } from '../hooks'
import { usePutResearcherAvailability } from '@/hooks/api'
import { useAuth } from '@/hooks/useAuth'
import { ResearcherAvailability, ResearcherCommitment, User } from '@/types'
import { useMutationWrapper } from '@/hooks'
import { RoundInfo } from '@/features/availability/AvailabilityModal/RoundInfo'
import dayjs from 'dayjs'
import { usePutResearcherCommitment } from '@/hooks/usePutResearcherCommitment'
import { useSnackbar } from 'notistack'

export const FORM_KEY_DATE_FORMAT = 'YYYY-MM-DD'

export type AvailabilityFormData = {
  availability: {
    [key: string]: {
      availableForMorningRound: boolean
      availableForDayRound: boolean
      availableForEveningRound: boolean
    }
  }
  commitment: {
    [key: string]: number
  }
}

export const AvailabilityModal = (): JSX.Element => {
  const { t } = useTranslation('planning')
  const { enqueueSnackbar } = useSnackbar()
  const [activeWeek, setActiveWeek] = useState<number | undefined>()
  const [availabilitySaved, setAvailabilitySaved] = useState(false)
  const [commitmentSaved, setCommitmentSaved] = useState(false)

  const { user } = useAuth()

  const { control, handleSubmit, reset, watch } = useForm<AvailabilityFormData>(
    { defaultValues: { availability: {}, commitment: {} } }
  )

  const { mutateAsync: saveAvailability, isLoading: isSaving } =
    useMutationWrapper(
      usePutResearcherAvailability.bind(null, {
        researcherId: (user as User).id,
      })
    )

  const { mutateAsync: saveCommitment, isLoading: isCommitmentSaving } =
    useMutationWrapper(
      usePutResearcherCommitment.bind(null, {
        researcherId: (user as User).id,
      })
    )

  const handleSubmitForm = async (data: AvailabilityFormData) => {
    const availabilityParams = [] as ResearcherAvailability[]

    Object.entries(data.availability).map(([key, value]) =>
      availabilityParams.push({
        date: key,
        available_for_morning_round: value.availableForMorningRound ?? false,
        available_for_day_round: value.availableForDayRound ?? false,
        available_for_evening_round: value.availableForEveningRound ?? false,
      })
    )

    await saveAvailability(availabilityParams, {
      onSuccess: () => setAvailabilitySaved(true),
    })

    const commitmentParams = [] as ResearcherCommitment[]
    const currentYear = dayjs().year()

    Object.entries(data.commitment).map(([key, value]) =>
      commitmentParams.push({
        year: +currentYear,
        week: +key,
        max_days_available: +value,
      })
    )
    await saveCommitment(commitmentParams, {
      onSuccess: () => setCommitmentSaved(true),
    })
  }

  useEffect(() => {
    if (availabilitySaved && commitmentSaved) {
      enqueueSnackbar(
        t('planning:availability.savedSuccessfully', { week: activeWeek }),
        {
          variant: 'success',
        }
      )
      setAvailabilitySaved(false)
      setCommitmentSaved(false)
    }
  }, [availabilitySaved, commitmentSaved])

  useInitDefaultValues({ reset })

  const isMonthSelected = activeWeek !== undefined

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <ModalLayout
        title={
          isMonthSelected
            ? t('availability.modalTitleWithWeek', {
                week: activeWeek,
              })
            : t('availability.modalTitle')
        }
        aboveHeaderBar={
          isMonthSelected && (
            <Button
              onClick={() => setActiveWeek(undefined)}
              variant="text"
              startIcon={<ArrowLeft size={16} />}
              component={Link}
              data-testid="back-button"
            >
              {t('availability.backToWeeksButton')}
            </Button>
          )
        }
        belowHeaderBar={isMonthSelected && <RoundInfo />}
        headerAction={
          isMonthSelected && (
            <CheckAll weekNumber={activeWeek} watch={watch} reset={reset} />
          )
        }
        footerActions={
          <ModalActions isSaving={isSaving || isCommitmentSaving} />
        }
      >
        {!isMonthSelected ? (
          <WeekSelection onSelect={setActiveWeek} />
        ) : (
          <DaysSelection weekNumber={activeWeek} control={control} />
        )}
      </ModalLayout>
    </form>
  )
}

import { useNavigate } from 'react-router-dom'
import { Button, ButtonProps, Icon, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { PageReferrers } from '@/constants'

export type LinkButtonProps = {
  title: string
  url: string
  referrer?: PageReferrers
  arrowIcon: boolean
  action?: () => void
} & ButtonProps

export const LinkButton = ({
  title,
  url,
  referrer,
  arrowIcon = false,
  variant = 'text',
  action,
}: LinkButtonProps): JSX.Element => {
  const navigate = useNavigate()

  const options = referrer
    ? {
        state: { referrer },
      }
    : {}

  return (
    <Button
      onClick={() => {
        navigate(url, { ...options })
        action && action()
      }}
      variant={variant}
      endIcon={
        arrowIcon && (
          <Icon
            sx={{
              fontSize: 14,
              lineHeight: '21px',
              fontWeight: 600,
            }}
          >
            <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
          </Icon>
        )
      }
    >
      <Typography variant="body2" fontWeight={600}>
        {title}
      </Typography>
    </Button>
  )
}

import {
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
  useTransition,
} from 'react'
import { Router } from 'react-router-dom'
import { BrowserHistory, createBrowserHistory, Update } from 'history'

export interface BrowserRouterProps {
  basename?: string
  children?: ReactNode
}

/**
 * Custom Router that handles suspense state for pages loaded with React. lazy
 * Based on gist: https://gist.github.com/perlow/bb7612b25f37667be964f1a1aba42780
 * Found solution here: https://stackoverflow.com/a/66040433/5801305
 */
export function SuspenseRouter({ basename, children }: BrowserRouterProps) {
  const historyRef = useRef<BrowserHistory | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPending, startTransition] = useTransition()

  if (historyRef.current === null) {
    historyRef.current = createBrowserHistory()
  }

  const history = historyRef.current as BrowserHistory
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  function setStateAsync(update: Update) {
    startTransition(() => {
      setState(update)
    })
  }

  useLayoutEffect(() => history.listen(setStateAsync), [history])

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  )
}

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from '@mui/material'
import { SyntheticEvent, useState } from 'react'

const IconButton = styled(MuiIconButton)`
  background: ${({ theme }) => theme.palette.secondary.light};
  border-radius: 5px;
  padding: 10px;

  svg {
    color: ${({ theme }) => theme.palette.green[600]};
    width: 22px;
    height: 16px;
  }
`

const Flag = styled.img`
  border-radius: 2px;
  width: 22px;
  height: 16px;
`

type languageOptionsType = {
  [key: string]: {
    icon: string
    name: string
  }
}

const languageOptions: languageOptionsType = {
  en: {
    icon: '/static/img/flags/us.png',
    name: 'English',
  },
  nl: {
    icon: '/static/img/flags/nl.png',
    name: 'Dutch',
  },
}

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [anchorMenu, setAnchorMenu] = useState<any>(null)

  const selectedLanguage = languageOptions[i18n.language]

  const toggleMenu = (event: SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
    closeMenu()
  }

  return (
    <>
      <Tooltip title="Languages">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          size="large"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default NavbarLanguagesDropdown

import { Outlet } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import Navbar from '../components/layouts/navbar/Navbar'
import { ReactChildren } from '@/types'

export const PortalLayout = ({ children }: Partial<ReactChildren>) => (
  <Box display="flex" minHeight="100vh">
    <Stack maxWidth="100%" flex={1}>
      <Navbar />

      <Stack height="100%" component="main">
        {children}
        <Outlet />
      </Stack>
    </Stack>
  </Box>
)

import { AutocompleteOption } from '@/components/inputs'
import * as yup from 'yup'
import { Specie } from '@/types'
import translation from 'i18next'

export type SpeciesSelectorFormData = {
  newSpecies: AutocompleteOption<string | Specie>[]
}

export type ResearchTypesSelectorFormData = {
  researchTypes: AutocompleteOption<string>[][]
}
export const researchTypesSelectorSchema = yup.object({
  researchTypes: yup
    .array()
    .of(
      yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            title: yup.string(),
          })
        )
        .min(1, translation.t('errors:validation.array_min', { min: 1 }))
    )
    .min(1, translation.t('errors:validation.add_specie')),
})

import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseVisitLogKey, getUseVisitLogsKey } from '@/hooks'
import { APIErrorResponse, FieldReport, PatchFieldReport } from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'

export type UsePatchFieldReportProps = {
  id: number
}

export const usePatchFieldReport = ({
  id,
}: UsePatchFieldReportProps): UseMutationResult<
  FieldReport,
  APIErrorResponse,
  PatchFieldReport
> => {
  const client = useApiClient()

  const queryClient = useQueryClient()

  const visitLogsListKey = getUseVisitLogsKey()
  const visitLogItemKey = getUseVisitLogKey(id)

  return useMutation(
    (params) =>
      client
        .patch(`field_reports/${id}/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(visitLogsListKey)
        queryClient.invalidateQueries(visitLogItemKey)
      },
    }
  )
}

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { theme } from '@/theme'
import { ReactChildren } from '@/types'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export const ThemeProvider = ({ children }: ReactChildren): JSX.Element => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </LocalizationProvider>
)

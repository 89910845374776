import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  SendPasswordReset,
  SendPasswordResetError,
  SendPasswordResetResponse,
} from '@/types/api/auth'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse } from '@/types'

export const usePostResetPassword = (): UseMutationResult<
  SendPasswordResetResponse,
  APIErrorResponse<SendPasswordResetError>,
  SendPasswordReset
> => {
  const client = useApiClient()

  return useMutation((params: SendPasswordReset) =>
    client
      .post<SendPasswordResetResponse>('/auth/users/reset_password/', params)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}

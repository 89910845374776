import { AutocompleteOption, ControlledAutocomplete } from '@/components/inputs'
import { useTranslation } from 'react-i18next'
import { ControlledFieldProps, Specie } from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

export const getSpecieOptionLabel = (
  specie: Specie
): AutocompleteOption<string | Specie> => ({
  value: specie.id.toString(),
  title: specie.name,
  specie,
})

export type SpeciesAutocompleteProps<T extends FieldValues> = {
  isLoading: boolean
  species?: Specie[]
  onChange?: (item: AutocompleteOption<string | Specie>[]) => void
} & Pick<ControlledFieldProps<T>, 'name' | 'control'>

export const SpeciesAutocomplete = <T extends FieldValues>({
  name,
  control,
  isLoading,
  species = [],
  onChange,
}: SpeciesAutocompleteProps<T>): JSX.Element => {
  const { t } = useTranslation('forms')

  const [options, setOptions] = useState<AutocompleteOption<string | Specie>[]>(
    []
  )

  useEffect(() => {
    if (!species?.length) {
      return
    }

    const sorted = species.sort((a, b) => {
      // First, compare by the first element (a[0] and b[0])
      const compareByFirst = a.animal.name.localeCompare(b.animal.name)

      // If the first elements are equal, compare by the second element (a[1] and b[1])
      return compareByFirst === 0
        ? a.animal.name.localeCompare(b.animal.name)
        : compareByFirst
    })

    const mapped = sorted.map((specie) => getSpecieOptionLabel(specie))

    setOptions(mapped)
  }, [species])

  return (
    <ControlledAutocomplete
      name={name}
      control={control}
      options={options}
      multiple={true}
      textFieldProps={{
        placeholder: t('placeholders.specie'),
        label: t('labels.add-species'),
        fullWidth: true,
      }}
      loading={isLoading}
      renderOption={(props, option) => (
        <li {...props} key={option.value + option.title}>
          <Box
            component="span"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            fontSize={12}
            height={22}
            p={2}
            mr={2}
            bgcolor="green.100"
            borderRadius="12px"
            fontWeight={700}
          >
            {(option.specie as Specie).animal.name}
          </Box>
          {option.title}
        </li>
      )}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : `(${(option.specie as Specie).animal.name}) ${option.title}`
      }
      onChangeCustom={(value) => {
        onChange && onChange(value as AutocompleteOption<Specie>[])
      }}
      data-testid="researchers-autocomplete"
    />
  )
}

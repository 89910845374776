import { Link, Stack } from '@mui/material'
import { ReactComponent as Logo } from '../../../vendor/logo.svg'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { NavbarMenuItems } from '@/components/layouts/navbar/NavbarMenuItems'

const Brand = styled(Link)<{
  component: typeof RouterLink
  to: string
}>`
  min-height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`

const BrandIcon = styled(Logo)`
  width: 100px;
  height: 32px;
`

export const NavbarMenu = (): JSX.Element => (
  <Stack direction="row" alignItems="center" spacing={12}>
    <Brand component={RouterLink} to="/">
      <BrandIcon />
    </Brand>
    <NavbarMenuItems />
  </Stack>
)

import { CircularProgress, Stack, StackProps } from '@mui/material'

export const Loader = (props: StackProps) => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    minHeight="100%"
    {...props}
  >
    <CircularProgress color="secondary" />
  </Stack>
)

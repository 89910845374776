import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import {
  ObservationLogFormData,
  ObservationLogFormActions,
} from '@/components/forms'
import {
  ControlledTextField,
  ControlledDateTimeField,
} from '@/components/inputs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'
import { FormComponentProps } from '@/types'

const schema = yup.object({
  notes: yup.string().required().max(256),
  observed_at: yup.date().required(),
})

export const CourtshipTerritoryForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const methods = useForm<ObservationLogFormData>({
    mode: 'onChange',
    defaultValues: {
      notes: '',
      observed_at: '',
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  useApiErrorData<ObservationLogFormData>(apiErrorData, methods)

  return (
    <FormProvider {...methods}>
      <Stack spacing={4} mt={4} width="100%">
        <ControlledTextField
          control={methods.control}
          name="notes"
          placeholder={t('observation-log.fieldLabels.notes')}
          multiline={true}
          rows={3}
          errorMessagePrefix=""
        />
        <ControlledDateTimeField
          name="observed_at"
          control={methods.control}
          label={t('observation-log.fieldLabels.observedAt')}
          errorMessagePrefix=""
        />
        <ObservationLogFormActions
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isLoading={isLoading}
          methods={methods}
        />
      </Stack>
    </FormProvider>
  )
}

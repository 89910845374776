import { getUseRoundKey, useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  ManagerResearchVisitEvent,
  PatchManagerResearchVisitEvent,
} from '@/types/api/entities'
import { APIErrorResponse } from '@/types'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { QUERY_KEYS } from '@/constants'

export const usePatchRound = (
  id: number
): UseMutationResult<
  ManagerResearchVisitEvent,
  APIErrorResponse,
  PatchManagerResearchVisitEvent
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch(`/plannings/events/manager/${id}/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data) => {
        const queryKey = getUseRoundKey(id)

        queryClient.setQueryData(queryKey, data)
        queryClient.invalidateQueries(QUERY_KEYS.PLANNING_EVENTS_MANAGER)
      },
      onError: (error) => {
        alert(error)
      },
    }
  )
}

import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectsTableProps } from './ProjectsTable'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { AuthorizationGuard, Indicator, ProgressBar } from '@/components'
import { UserGroups } from '@/types'
import { Link } from 'react-router-dom'
import { getPath } from '@/utils'
import { DATE_FORMAT_DMY, NEW_PROJECT_STATUSES, ROUTES_PATH } from '@/constants'
import { ProjectStatus } from '@/features/projectDetail'
import dayjs from 'dayjs'

export const ProjectsTableBody = ({
  rows,
}: ProjectsTableProps): JSX.Element => {
  const { t } = useTranslation('projects')

  return (
    <TableBody>
      {rows.map(
        ({
          id,
          project_number,
          name,
          client_name,
          start_date,
          end_date,
          progress,
          status,
          is_in_gis,
        }) => {
          const showProjectDetail = status
            ? !NEW_PROJECT_STATUSES.includes(status)
            : false

          const detailPath = getPath(
            showProjectDetail
              ? ROUTES_PATH.PROJECT_DETAIL
              : ROUTES_PATH.PROJECT_EXTEND,
            { id }
          )

          return (
            <TableRow key={id}>
              <TableCell component="th" scope="row">
                {is_in_gis ? (
                  <AuthorizationGuard
                    allowedGroups={[UserGroups.MANAGER]}
                    redirect={false}
                  >
                    <Indicator name={name} tooltipText={t('isInGisTooltip')} />
                  </AuthorizationGuard>
                ) : (
                  <>{name}</>
                )}
              </TableCell>
              <TableCell>{project_number}</TableCell>
              <TableCell>{client_name}</TableCell>
              <TableCell>
                {dayjs(start_date).format(DATE_FORMAT_DMY) ?? '-'}
              </TableCell>
              <TableCell>
                {dayjs(end_date).format(DATE_FORMAT_DMY) ?? '-'}
              </TableCell>
              <TableCell width={180}>
                <ProgressBar progress={progress} />
              </TableCell>
              <TableCell>
                <ProjectStatus status={status} />
              </TableCell>
              <TableCell width="190px">
                <AuthorizationGuard
                  allowedGroups={[UserGroups.MANAGER]}
                  redirect={false}
                >
                  <Button
                    variant="text"
                    endIcon={
                      <Icon
                        sx={{
                          fontSize: 14,
                          lineHeight: '21px',
                          fontWeight: 600,
                        }}
                      >
                        <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
                      </Icon>
                    }
                    component={Link}
                    to={detailPath}
                    data-testid="open-project-button"
                  >
                    <Typography variant="body2" fontWeight={600}>
                      {t(`action.${showProjectDetail ? 'open' : 'new'}`)}
                    </Typography>
                  </Button>
                </AuthorizationGuard>
              </TableCell>
            </TableRow>
          )
        }
      )}
    </TableBody>
  )
}

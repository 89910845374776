import { ReactNode, useEffect, useState } from 'react'
import FullCalendar, { DatesSetArg, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { calendarStyles } from './Calendar.styles'
import { useAuth } from '@/hooks'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD } from '@/constants'
import { Stack, styled } from '@mui/material'
import { Plannings } from '@/types'
import { EventContent } from './EventContent'
import { getCalendarEvents } from '@/features/planning/utils'

const FullCalendarWrapper = styled('div')`
  position: relative;
  ${calendarStyles}
`

export type CalendarProps = {
  setCalendarDateRange: (arg: DatesSetArg) => void
  onDayClick?: (date: Date | null) => void
  plannings?: Plannings
  rightBar?: ReactNode
}

export const Calendar = ({
  setCalendarDateRange,
  plannings,
  onDayClick,
  rightBar,
}: CalendarProps): JSX.Element => {
  const [events, setEvents] = useState<EventInput[]>([])

  const { user } = useAuth()

  useEffect(() => {
    if (!plannings || !user) {
      return
    }

    setEvents(Object.values(getCalendarEvents(plannings, user)))
  }, [plannings, user])

  return (
    <FullCalendarWrapper data-testid="planning-calendar">
      {rightBar && (
        <Stack
          style={{ position: 'absolute', top: 0, right: 0 }}
          alignItems="end"
        >
          {rightBar}
        </Stack>
      )}

      <FullCalendar
        firstDay={1}
        initialView="dayGridMonth"
        initialDate={dayjs().format(DATE_FORMAT_YMD)}
        plugins={[dayGridPlugin, interactionPlugin]}
        events={events}
        height="auto"
        headerToolbar={{
          left: 'title,prev,next',
          right: '',
        }}
        datesSet={(arg) => {
          setCalendarDateRange(arg)
        }}
        eventContent={({ event }) => (
          <EventContent
            researchMoments={event.extendedProps.researchMoments}
            researchers={event.extendedProps.researchers?.length}
            managerReminder={event.extendedProps.managerReminder}
            date={event.start}
            onClick={onDayClick}
            researchAreaLabels={event.extendedProps.researchAreaLabels ?? []}
          />
        )}
      />
    </FullCalendarWrapper>
  )
}

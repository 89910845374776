import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, IconButton, Pagination } from '@mui/material'
import { X } from 'react-feather'
import { VisitLog } from '@/features'
import { VisitLogsTable } from '@/features/dataValidation/VisitLogsTable'
import { Loader } from '@/components'
import {
  SearchTextField,
  SearchDateField,
  ResearchersMultiSelect,
  VisitLogStatusMultiSelect,
  BooleanSelect,
} from '@/components/inputs'
import { EmptyListData, Indicator } from '@/components/ui'
import {
  useModal,
  useFilters,
  useVisitLogs,
  VisitLogsSearchParams,
} from '@/hooks'
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from '@/constants'
import { theme } from '@/theme'

export const DataValidationContainer = (): JSX.Element => {
  const { t } = useTranslation('dataValidation')
  const { open } = useModal()

  const defaultFiltersParams: VisitLogsSearchParams = {
    id: undefined,
    search: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    visitResearchers: undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  }

  const openVisitLog = (id: number) => {
    if (data && data.results) {
      const index = data.results.findIndex((row) => row.id === id)

      if (index !== -1) {
        open(<VisitLog visitLog={data.results[index]} />)
      }
    }
  }

  const {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
    openDetail,
  } = useFilters<VisitLogsSearchParams>(defaultFiltersParams)

  const { data, isLoading } = useVisitLogs(filterParams)

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)

      const detailItemId = openDetail()

      if (detailItemId) {
        openVisitLog(detailItemId)
      }
    }
  }, [data])

  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
          spacing={4}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <SearchTextField
              searchValue={filterParams.id as string}
              onChange={(value) => handleFieldValueChange(value, 'id')}
              placeholder="ID"
              searchIcon={false}
              width="80px"
            />
            <SearchTextField
              searchValue={filterParams.search}
              onChange={(value) => handleFieldValueChange(value, 'search')}
              placeholder={t('searchPlaceholder')}
              width="500px"
            />
            <SearchDateField
              searchStartValue={filterParams.startDate as string}
              searchEndValue={filterParams.endDate as string}
              onChangeStartDate={(date) =>
                handleFieldValueChange(date, 'startDate')
              }
              onChangeEndDate={(date) =>
                handleFieldValueChange(date, 'endDate')
              }
            />
            <BooleanSelect
              label={t('forms:placeholders.isFieldReportActual')}
              value={filterParams.isEventApproved as boolean}
              onChange={(value) =>
                handleFieldValueChange(value, 'isEventApproved')
              }
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <ResearchersMultiSelect
              value={filterParams.visitResearchers as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'visitResearchers')
              }
              placeholder={t('placeholders.researchers')}
            />
            <VisitLogStatusMultiSelect
              value={filterParams.status as string}
              onChange={(value) => handleFieldValueChange(value, 'status')}
            />
            <Stack>
              <Stack direction="row" alignItems="center" spacing={4} pl={2}>
                <Indicator status="valid" name={t('indicator.valid')} />
                <Indicator
                  status="valid_without_field_report"
                  name={t('indicator.without_field_report')}
                />
                <Indicator status="approved" name={t('indicator.approved')} />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={4} pl={2}>
                <Indicator status="invalid" name={t('indicator.inValid')} />
                <Indicator
                  status="extra_event_requested"
                  name={t('indicator.extraEvent')}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Typography color="green.600">
          {t('amount', { amount: data?.count ?? 0 })}
        </Typography>
        <IconButton
          onClick={handleResetFilters}
          title={t('buttons.resetFilters')}
        >
          <X size="16px" color={theme.palette.green['500']} />
        </IconButton>
      </Stack>

      {data?.count ? (
        <>
          <VisitLogsTable
            rows={data.results}
            showVisitLogDetails={openVisitLog}
          />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="dataValidation:noDataValidations" />
          )}
        </Box>
      )}
    </Stack>
  )
}

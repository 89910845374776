import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  ControlledTextField,
  ControlledDateTimeField,
  ControlledCheckboxField,
} from '@/components/inputs'
import { FormComponentProps, PatchVisitLog } from '@/types'
import { EnvironmentDataFieldsSet } from '@/components/forms/VisitLogFormContainer/EnvironmentDataFieldsSet'
import { yupResolver } from '@hookform/resolvers/yup'
import { visitLogInfoSchema } from '@/components/forms/VisitLogFormContainer/validationSchema'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'

export type VisitLogFormData = Omit<PatchVisitLog, 'research_visit_event_id'>

export const VisitLogForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<VisitLogFormData>): JSX.Element => {
  const { t } = useTranslation()

  const methods = useForm<VisitLogFormData>({
    mode: 'onChange',
    defaultValues: {
      start_datetime: '',
      end_datetime: '',
      start_temperature: 0,
      end_temperature: 0,
      cloud_cover: '',
      wind_force: 0,
      precipitation: '',
      precipitation_duration: '',
      is_left_earlier: false,
      reason_of_leaving: '',
      comment: '',
    },
    values: defaultValues,
    resolver: yupResolver(visitLogInfoSchema),
  })

  useApiErrorData<VisitLogFormData>(apiErrorData, methods)

  const handleSubmit = methods.handleSubmit((formData) => {
    onSubmit(formData)
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  return (
    <FormProvider {...methods}>
      <Stack direction="column" spacing={6} mt={4}>
        <Stack direction="row" spacing={4}>
          <Box>
            <ControlledDateTimeField
              name="start_datetime"
              control={methods.control}
              label={t(
                'dataValidation:visit-log.fieldTitles.observationStartTime'
              )}
              minutesStep={5}
              textFieldProps={{ fullWidth: false, sx: { width: 200 } }}
              apiErrorData={apiErrorData}
            />
          </Box>
          <Box>
            <ControlledDateTimeField
              name="end_datetime"
              control={methods.control}
              label={t(
                'dataValidation:visit-log.fieldTitles.observationEndTime'
              )}
              minutesStep={5}
              textFieldProps={{ fullWidth: false, sx: { width: 200 } }}
              apiErrorData={apiErrorData}
            />
          </Box>
        </Stack>

        <EnvironmentDataFieldsSet />

        <Box>
          <ControlledCheckboxField
            name="is_left_earlier"
            control={methods.control}
            label={t('dataValidation:visit-log.fieldTitles.leftEarlier')}
          />
          {methods.watch('is_left_earlier') === true && (
            <Box mt={2}>
              <ControlledTextField
                name="reason_of_leaving"
                control={methods.control}
                placeholder={t(
                  'dataValidation:visit-log.fieldTitles.leftEarlierComment'
                )}
                multiline={true}
                inputProps={{
                  style: {
                    padding: 0,
                  },
                }}
                errorMessagePrefix=""
                apiErrorData={apiErrorData}
              />
            </Box>
          )}
        </Box>

        <Box>
          <ControlledTextField
            name="comment"
            control={methods.control}
            placeholder={t('dataValidation:visit-log.fieldTitles.comment')}
            multiline={true}
            inputProps={{
              style: {
                padding: 0,
              },
            }}
            errorMessagePrefix=""
            apiErrorData={apiErrorData}
          />
        </Box>

        {methods.formState.isDirty && (
          <Stack direction="row" spacing={5} pt={1}>
            <Button variant="textThin" color="secondary" onClick={handleReset}>
              {t('forms:buttons.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              loading={isLoading}
              disableElevation={true}
              disabled={!methods.formState.isDirty}
            >
              <span>{t('forms:buttons.save')}</span>
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  )
}

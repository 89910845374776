import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
} from '@mui/material'
import { Loader, LinkButton } from '@/components'
import { usePlanningsEventsManager } from '@/hooks'
import { getPath } from '@/utils'
import dayjs from 'dayjs'
import { UncertaintyReason } from '@/types'
import {
  ROUTES_PATH,
  DATE_FORMAT_DMY,
  DATE_FORMAT_DMY_TIME,
  PageReferrers,
} from '@/constants'
import { theme } from '@/theme'

type MonitoringTableRowDetailsProps = {
  areaId: number
  researchers?: string
  uncertaintyReason?: UncertaintyReason
  startDate?: string
  endDate?: string
}

export const MonitoringTableRowDetails = ({
  areaId,
  researchers = undefined,
  uncertaintyReason = undefined,
  startDate = undefined,
  endDate = undefined,
}: MonitoringTableRowDetailsProps): JSX.Element => {
  const { t } = useTranslation('monitoring')

  const { data, isLoading } = usePlanningsEventsManager({
    researchArea: `${areaId}`,
    researchers,
    uncertaintyReason,
    startDate,
    endDate,
  })

  const columnNames = [
    'round_id',
    'status',
    'researchers',
    'protocol_date_start',
    'protocol_date_end',
    'round_date_start',
    'round_date_end',
    'round_type',
    'round_number',
    'reason_of_uncertainty',
  ]

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {data?.results.length === 0 ? (
            <Typography variant="body1" gutterBottom={true} sx={{ m: 4 }}>
              {t('titles.researchAreaNoRounds', { areaNumber: areaId })}
            </Typography>
          ) : (
            <Table size="small">
              <TableBody>
                <TableRow>
                  {columnNames.map((columnName) => (
                    <TableCell
                      key={columnName}
                      sx={{
                        py: 3,
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {t(`columnNames.${columnName}`)}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
                {data?.results?.map(
                  ({
                    id,
                    is_event_passed,
                    researchers,
                    protocol_start_date,
                    protocol_end_date,
                    start_datetime,
                    end_datetime,
                    round_type,
                    round_number,
                    reason_of_uncertainty,
                  }) => (
                    <TableRow
                      key={id}
                      sx={{
                        '& > *': {
                          backgroundColor: theme.palette.background.paper,
                        },
                      }}
                    >
                      <TableCell>{id}</TableCell>
                      <TableCell>
                        {is_event_passed ? t(`pastEvent`) : t('futureEven')}
                      </TableCell>
                      <TableCell>
                        {researchers?.map(
                          ({ first_name, last_name }, i) =>
                            `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                        ) ?? '-'}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {protocol_start_date
                          ? dayjs(protocol_start_date).format(DATE_FORMAT_DMY)
                          : '-'}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {protocol_end_date
                          ? dayjs(protocol_end_date).format(DATE_FORMAT_DMY)
                          : '-'}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {start_datetime
                          ? dayjs(start_datetime).format(DATE_FORMAT_DMY_TIME)
                          : '-'}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {end_datetime
                          ? dayjs(end_datetime).format(DATE_FORMAT_DMY_TIME)
                          : '-'}
                      </TableCell>
                      <TableCell>{round_type}</TableCell>
                      <TableCell>{round_number}</TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color={
                            reason_of_uncertainty ? 'error.main' : 'inherit'
                          }
                        >
                          {reason_of_uncertainty
                            ? t(`uncertaintyReason.${reason_of_uncertainty}`)
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <LinkButton
                          url={getPath(ROUTES_PATH.MONITORING_DETAIL, { id })}
                          title={t(`buttons.detail`)}
                          referrer={PageReferrers.MONITORING}
                          arrowIcon={true}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </Box>
  )
}

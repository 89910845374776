import { Paper, Table, TableContainer } from '@mui/material'
import { MonitoringTableHead, MonitoringTableBody } from '@/features'
import { UseResearchAreasMonitoringParams } from '@/hooks'
import { ResearchAreaMonitoring } from '@/types'

export type MonitoringTableProps = {
  rows: ResearchAreaMonitoring[]
  filterParams: UseResearchAreasMonitoringParams
}

export const MonitoringTable = ({
  rows,
  filterParams,
}: MonitoringTableProps): JSX.Element => (
  <TableContainer component={Paper}>
    <Table>
      <MonitoringTableHead />
      <MonitoringTableBody rows={rows} filterParams={filterParams} />
    </Table>
  </TableContainer>
)

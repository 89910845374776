import { Stack, Box, Typography, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Info } from 'react-feather'

export const InvalidDataInfo = ({
  invalidData,
}: {
  invalidData?: string[]
}): JSX.Element => {
  const { t } = useTranslation('dataValidation')

  return (
    <Tooltip
      placement="left"
      arrow={true}
      title={
        <Box m={2}>
          <Typography variant="body2" fontWeight="bold">
            {t(`visit-log.fieldTitles.invalidProtocolVariables`)}
          </Typography>
          {invalidData?.map((item) => (
            <Typography variant="body2" key={item}>
              &bull;&nbsp;{t(`visit-log.planningData.${item}`)}
            </Typography>
          ))}
        </Box>
      }
      onClick={(event) => event.stopPropagation()}
    >
      <Stack
        alignItems="center"
        mr={1}
        sx={{
          color: 'error.main',
          cursor: 'pointer',
          ':hover': { svg: { color: 'green.500' } },
        }}
      >
        <Info size="14px" />
      </Stack>
    </Tooltip>
  )
}

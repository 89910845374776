import { Helmet } from 'react-helmet-async'
import { Button, Stack, Typography } from '@mui/material'
import { ResetPasswordForm, ResetPasswordFormData } from '@/components/forms'
import { useTranslation } from 'react-i18next'
import { useMutationWrapper, usePostResetPassword } from '@/hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES_PATH } from '@/constants'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('forms')
  const [isWaitingConfirm, setIsWaitingConfirm] = useState(false)

  const { mutateAsync: resetPassword, isLoading } =
    useMutationWrapper(usePostResetPassword)

  const onSubmit = async (data: ResetPasswordFormData) => {
    await resetPassword(data, {
      onSuccess: () => {
        setIsWaitingConfirm(true)
      },
    })
  }

  const handleBackToLogin = () => navigate(ROUTES_PATH.LOGIN)

  return (
    <>
      <Helmet title="Reset password" />

      <Stack maxWidth={{ md: 400 }}>
        <Stack>
          <Typography component="h1" variant="h4" gutterBottom={true}>
            {t(
              isWaitingConfirm
                ? 'titles.reset-password.email'
                : 'titles.reset-password.main'
            )}
          </Typography>

          <Typography component="h2">
            {t(
              isWaitingConfirm
                ? 'descriptions.reset-password.email'
                : 'descriptions.reset-password.main'
            )}
          </Typography>

          {!isWaitingConfirm && (
            <ResetPasswordForm onSubmit={onSubmit} isLoading={isLoading} />
          )}
        </Stack>

        <Button
          variant={isWaitingConfirm ? 'contained' : 'text'}
          type="button"
          sx={{ mt: 5 }}
          onClick={handleBackToLogin}
        >
          {t('common:buttons.backToLogin')}
        </Button>
      </Stack>
    </>
  )
}

export default ResetPassword

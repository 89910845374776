import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  ControlledSelect,
  ControlledTextField,
  ControlledCheckboxField,
} from '@/components/inputs'
import {
  FormComponentProps,
  ProcessingStatus,
  ProcessingStatuses,
} from '@/types'

export type ProcessingStatusFormData = {
  processingStatus?: ProcessingStatus | ''
  processingComment: string | null
  isFieldReportAdded?: boolean
}

export type ProcessingStatusFormProps = {
  statusSelect?: boolean
  fieldReportCheckbox?: boolean
} & FormComponentProps<ProcessingStatusFormData>

export const ProcessingStatusForm = ({
  statusSelect = false,
  fieldReportCheckbox = false,
  onSubmit,
  defaultValues,
  isLoading,
}: ProcessingStatusFormProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<ProcessingStatusFormData>({
    mode: 'onChange',
    defaultValues: {
      ...(statusSelect ? { processingStatus: '' } : null),
      processingComment: '',
      ...(fieldReportCheckbox ? { isFieldReportAdded: false } : null),
    },
    values: defaultValues,
  })

  const handleReset = () =>
    reset({
      ...defaultValues,
    })

  const options = statusSelect
    ? [
        {
          name: t('placeholders.processingStatus'),
          value: '',
        },
      ].concat(
        Object.keys(ProcessingStatuses).map((key) => {
          const status = ProcessingStatuses[
            key as keyof typeof ProcessingStatuses
          ] as string

          return {
            name: t(`processingStatus.${status}`),
            value: status,
          }
        })
      )
    : []

  return (
    <form onSubmit={handleSubmit(async (data) => onSubmit(data))}>
      <Stack direction="row" alignItems="center" spacing={5} my={4}>
        <Stack width={550} spacing={4}>
          {statusSelect && (
            <Box width="100%">
              <ControlledSelect
                name="processingStatus"
                options={options}
                control={control}
                fullWidth={true}
              />
            </Box>
          )}
          <Box>
            <ControlledTextField
              name="processingComment"
              control={control}
              placeholder={t('placeholders.comment')}
              multiline={true}
              inputProps={{
                style: {
                  padding: 0,
                },
              }}
            />
          </Box>
          {fieldReportCheckbox && (
            <Box>
              <ControlledCheckboxField
                name="isFieldReportAdded"
                control={control}
                label={t('placeholders.isFieldReportAdded')}
              />
            </Box>
          )}
        </Stack>
        {isDirty && (
          <Stack direction="row" spacing={5} pt={1}>
            <Button variant="textThin" color="secondary" onClick={handleReset}>
              {t('buttons.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={isLoading}
              disableElevation={true}
              disabled={!isDirty}
            >
              <span>{t('buttons.save')}</span>
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </form>
  )
}

import { ReactNode, Context, createContext } from 'react'
import { AxiosInstance } from 'axios'
import { configureInterceptors } from './configureInterceptors'
import { apiClient } from '@/libs'

configureInterceptors(apiClient)

interface AxiosInstanceType {
  client: AxiosInstance
}

export const ApiContext: Context<AxiosInstanceType> =
  createContext<AxiosInstanceType>({
    client: apiClient,
  })

interface ContextProvidersProps {
  children: ReactNode
}

export const ApiProvider = ({
  children,
}: ContextProvidersProps): JSX.Element => (
  <ApiContext.Provider
    value={{
      client: apiClient,
    }}
  >
    {children}
  </ApiContext.Provider>
)

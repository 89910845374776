import {
  APIErrorResponse,
  PaginatedListResponse,
  ManagerResearchVisitEvent,
  SearchRequestParams,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { QUERY_KEYS } from '@/constants'

export type UsePlanningsEventsManagerParams = SearchRequestParams & {
  project?: string
  researchArea?: string
  researchers?: string
  researchAreaResearchers?: string
  uncertaintyReason?: string
  startDate?: string
  endDate?: string
}

export const usePlanningsEventsManager = ({
  ...params
}: UsePlanningsEventsManagerParams): UseQueryResult<
  PaginatedListResponse<ManagerResearchVisitEvent>,
  APIErrorResponse
> =>
  useQueryList({
    key: [...QUERY_KEYS.PLANNING_EVENTS_MANAGER, params],
    url: '/plannings/events/manager/',
    params: {
      ...(!!params.project && { project: params.project }),
      ...(!!params.researchArea && { research_area: params.researchArea }),
      ...(!!params.researchers && { researchers: params.researchers }),
      ...(!!params.researchAreaResearchers && {
        research_area__researchers: params.researchAreaResearchers,
      }),
      ...(!!params.uncertaintyReason && {
        reason_of_uncertainty: params.uncertaintyReason,
      }),
      ...(!!params.startDate && { date_start: params.startDate }),
      ...(!!params.endDate && { date_end: params.endDate }),
      limit: params.limit,
      offset: params.offset,
    },
  })

import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse } from '@/types'

type UseGenerateEventFieldReportProps = {
  id: number
}

export const useGenerateEventFieldReport = ({
  id,
}: UseGenerateEventFieldReportProps): UseMutationResult<
  {
    report_sharepoint_url: string
  },
  APIErrorResponse,
  void
> => {
  const client = useApiClient()

  return useMutation(() =>
    client
      .post(`field_reports/generate_for_event/${id}/`)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}

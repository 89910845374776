import { getResearchAreasKey } from '@/hooks'
import { ResearchArea } from '@/types'
import { useQueryClient } from '@tanstack/react-query'
import { DEFAULT_RESEARCH_AREA } from './researchArea.model'

export const useAddNewResearchArea = (
  projectId: number,
  ...setters: ((value: boolean) => void)[]
) => {
  const queryClient = useQueryClient()

  return () => {
    setters.forEach((setter) => setter(true))

    const queryKey = getResearchAreasKey(projectId)

    const researchAreas = queryClient.getQueryData(queryKey) as ResearchArea[]

    queryClient.setQueryData(queryKey, [
      ...researchAreas,
      DEFAULT_RESEARCH_AREA,
    ])
  }
}

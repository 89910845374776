import { formatName } from '@/utils'
import styled from '@emotion/styled'
import {
  Avatar,
  AvatarGroupProps as MuiAvatarGroupProps,
  AvatarGroup as MuiAvatarGroup,
  Typography,
} from '@mui/material'
import { cloneElement } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '@/types'

const DEFAULT_MAX_AVATARS = 3

const DEFAULT_COLORS = [
  {
    text: 'success.main',
    background: 'success.light',
  },
  {
    text: 'purple.main',
    background: 'purple.light',
  },
  {
    text: 'info.main',
    background: 'info.light',
  },
]

const StyledRestAvatar = styled(Avatar)`
  background: transparent;
  border: none;
  margin-left: 0 !important;
  box-shadow: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 120% !important;
  color: ${({ theme }) => theme.palette.grey[600]};
  align-self: center;
`

export type AvatarGroupProps = {
  max?: number
  noResultsElement?: JSX.Element
  users?: Pick<User, 'id' | 'first_name' | 'last_name'>[]
  colors?: {
    text: string
    background: string
  }[]
} & MuiAvatarGroupProps

export const AvatarGroup = ({
  max,
  noResultsElement,
  users = [],
  colors = DEFAULT_COLORS,
  ...restProps
}: AvatarGroupProps): JSX.Element => {
  const { t } = useTranslation('common')

  if (!users.length) {
    return (
      noResultsElement ?? (
        <Typography variant="body2" color="error.main">
          {t('fallback.notAssigned')}
        </Typography>
      )
    )
  }

  const rest = users.length - (max ?? DEFAULT_MAX_AVATARS)
  const displayedAvatars = users
    .slice(0, max)
    .map(({ id, first_name, last_name }, i) => (
      <Avatar
        key={id}
        alt={`${first_name} ${last_name} `}
        sx={{
          color: colors[i % colors.length].text,
          backgroundColor: colors[i % colors.length].background,
        }}
      >
        {formatName(`${first_name} ${last_name} `, 'initials')}
      </Avatar>
    ))

  if (rest > 0) {
    displayedAvatars.push(<StyledRestAvatar>+{rest}</StyledRestAvatar>)
  }

  return (
    <MuiAvatarGroup {...restProps}>
      {displayedAvatars.map((avatar, index) =>
        cloneElement(avatar, { key: index })
      )}
    </MuiAvatarGroup>
  )
}

import { useParams } from 'react-router-dom'
import { Box, Card, Grid, Stack } from '@mui/material'
import { PageBodyLayout, PageHeaderLayout } from '@/components/layouts'
import Page404 from '@/pages/Page404'
import { Loader } from '@/components'
import { RoundInfo } from '@/features'
import { useAuth, useRound } from '@/hooks'

function RoundDetail() {
  const { isInitialized } = useAuth()

  const { id: roundId } = useParams<{ id: string }>()

  const { data: round, isLoading } = useRound({
    id: +(roundId as string),
    isEnabled: isInitialized,
  })

  if (!isLoading && !round) {
    return <Page404 />
  }

  return (
    <>
      <Stack height="100%">
        <PageHeaderLayout isTitleLoading={isLoading} backButton={true} />

        <Stack flex={1} sx={{ overflowY: 'auto' }}>
          <PageBodyLayout minWidth="900px">
            <Grid container={true} rowSpacing={5} columnSpacing={4} mb={3}>
              <Grid item={true} sm={12} md={8}>
                <Box pb={5}>
                  {round && !isLoading ? (
                    <RoundInfo round={round} />
                  ) : (
                    <Card variant="outlined" sx={{ height: 460 }}>
                      <Loader />
                    </Card>
                  )}
                </Box>
              </Grid>
            </Grid>
          </PageBodyLayout>
        </Stack>
      </Stack>
    </>
  )
}

export default RoundDetail

import { Box } from '@mui/material'
import { ProtocolRoundInfo } from '@/features'
import { CustomTooltip } from '@/components/ui'
import { Info } from 'react-feather'
import { ProjectProtocolRound } from '@/types'

type ProtocolRoundInfoTooltipProps = {
  round: ProjectProtocolRound
  allRounds: ProjectProtocolRound[]
}

export const ProtocolRoundInfoTooltip = ({
  round,
  allRounds,
}: ProtocolRoundInfoTooltipProps): JSX.Element => (
  <CustomTooltip
    placement="right-start"
    arrow={true}
    title={
      <Box m={2}>
        <ProtocolRoundInfo
          round={round}
          allRounds={allRounds}
          showCustomParams={false}
        />
      </Box>
    }
    onClick={(event) => event.stopPropagation()}
    maxWidth={800}
  >
    <Box
      mt={1}
      ml={1.5}
      sx={{
        cursor: 'pointer',
        ':hover': { svg: { color: 'green.500' } },
      }}
    >
      <Info size="16px" />
    </Box>
  </CustomTooltip>
)

import { isStringValidUrl } from '@/utils'
import { Link } from '@mui/material'

export const SharepointUrlLink = ({
  sharepointUrl,
}: {
  sharepointUrl: string | null
}) => {
  if (!sharepointUrl) return <>-</>

  if (isStringValidUrl(sharepointUrl)) {
    return (
      <Link
        href={sharepointUrl}
        target="_blank"
        rel="noopener noreferrer"
        color="primary.light"
        underline="none"
        variant="body1"
        sx={{
          ':hover': {
            color: 'primary.main',
          },
        }}
      >
        Link
      </Link>
    )
  }

  return <div>{sharepointUrl}</div>
}

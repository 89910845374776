export const eventEmployeesFixture = [
  {
    id: 1,
    first_name: 'Garrott',
    last_name: 'Toynbee',
    email: 'gtoynbee0@miibeian.gov.cn',
    phone: '+232 760 155 8347',
    address_name: 'Suite 64',
    allowed_number_of_research_areas: 27,
    is_senior: false,
    notes: 'brand ubiquitous web-readiness',
    distance: 75,
    is_available: true,
  },
  {
    id: 2,
    first_name: 'Bat',
    last_name: 'Schapero',
    email: 'bschapero1@imageshack.us',
    phone: '+86 842 936 9508',
    address_name: 'Suite 7',
    allowed_number_of_research_areas: 10,
    is_senior: true,
    notes: 'reintermediate customized initiatives',
    distance: 25,
    is_available: false,
  },
  {
    id: 3,
    first_name: 'Maurene',
    last_name: 'Tille',
    email: 'mtille2@ftc.gov',
    phone: '+357 141 527 7699',
    address_name: 'Room 126',
    allowed_number_of_research_areas: 85,
    is_senior: false,
    notes: 'e-enable dot-com initiatives',
    distance: 50,
    is_available: false,
  },
  {
    id: 4,
    first_name: 'Hyacinth',
    last_name: 'Millard',
    email: 'hmillard3@fastcompany.com',
    phone: '+63 851 783 8306',
    address_name: 'Room 1356',
    allowed_number_of_research_areas: 29,
    is_senior: true,
    notes: 'incentivize open-source interfaces',
    distance: 100,
    is_available: false,
  },
  {
    id: 5,
    first_name: 'Allin',
    last_name: 'Sommerled',
    email: 'asommerled4@rakuten.co.jp',
    phone: '+7 702 530 0372',
    address_name: 'Room 1076',
    allowed_number_of_research_areas: 78,
    is_senior: true,
    notes: 'repurpose sticky supply-chains',
    distance: 36,
    is_available: false,
  },
  {
    id: 6,
    first_name: 'Kylynn',
    last_name: 'Mogridge',
    email: 'kmogridge5@google.fr',
    phone: '+374 265 744 9474',
    address_name: 'PO Box 22306',
    allowed_number_of_research_areas: 83,
    is_senior: true,
    notes: 'incentivize best-of-breed infomediaries',
    distance: 60,
    is_available: false,
  },
  {
    id: 7,
    first_name: 'Dwight',
    last_name: 'Stannett',
    email: 'dstannett6@amazon.co.uk',
    phone: '+385 180 963 7648',
    address_name: 'Apt 1961',
    allowed_number_of_research_areas: 7,
    is_senior: false,
    notes: 'morph ubiquitous e-markets',
    distance: 50,
    is_available: true,
  },
  {
    id: 8,
    first_name: 'Titos',
    last_name: 'Shrubshall',
    email: 'tshrubshall7@thetimes.co.uk',
    phone: '+7 333 288 0194',
    address_name: 'Apt 1214',
    allowed_number_of_research_areas: 67,
    is_senior: false,
    notes: 'whiteboard proactive users',
    distance: 72,
    is_available: false,
  },
  {
    id: 9,
    first_name: 'Dawna',
    last_name: 'Galfour',
    email: 'dgalfour8@networksolutions.com',
    phone: '+84 493 237 4691',
    address_name: 'PO Box 6014',
    allowed_number_of_research_areas: 16,
    is_senior: false,
    notes: 'deliver ubiquitous users',
    distance: 17,
    is_available: false,
  },
  {
    id: 10,
    first_name: 'Thomasina',
    last_name: 'Gristwood',
    email: 'tgristwood9@pen.io',
    phone: '+86 948 816 7984',
    address_name: 'Apt 924',
    allowed_number_of_research_areas: 21,
    is_senior: true,
    notes: 'productize open-source vortals',
    distance: 76,
    is_available: true,
  },
  {
    id: 13,
    email: 'test2@gmail.com',
    first_name: 'Jone',
    last_name: 'Doe 2',
    phone: '+86 948 816 7984',
    address_name: 'Ódessa, Odessa Oblast, Ukraine',
    address_name_long: '30.7233095000000000',
    address_name_lat: '46.4825260000000000',
    allowed_number_of_research_areas: 13,
    is_senior: false,
    notes: '',
    distance: 76,
    is_available: true,
  },
  {
    id: 15,
    email: 'test4@gmail.com',
    first_name: 'Jone',
    last_name: 'Doe 4',
    phone: '+86 948 816 7984',
    address_name: 'Mariupol, Donetsk Oblast, Ukraine',
    address_name_long: '37.5433670000000000',
    address_name_lat: '47.0971330000000000',
    allowed_number_of_research_areas: null,
    is_senior: true,
    notes: '',
    distance: 76,
    is_available: true,
  },
  {
    id: 14,
    email: 'test3@gmail.com',
    groups: ['employee'],
    first_name: 'Jone',
    last_name: 'Doe 3',
    phone: '+86 948 816 7984',
    address_name: 'Donetsk, Donetsk Oblast, Ukraine',
    address_long: '37.8028500000000000',
    address_lat: '48.0158830000000000',
    allowed_number_of_research_areas: null,
    is_senior: false,
    notes: '',
    distance: 76,
    is_available: true,
  },
]

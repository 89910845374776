import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { VisitLog } from '@/types/api/entities'
import { QUERY_KEYS } from '@/constants'
import { APIErrorResponse } from '@/types'

export type UseVisitLogProps = {
  id: number
  isEnabled?: boolean
}

export const getUseVisitLogKey = (id: number): string[] =>
  QUERY_KEYS.VISIT_LOG.map((key) => (key === ':id' ? id.toString() : key))

export const useVisitLog = ({
  id,
  isEnabled = true,
}: UseVisitLogProps): UseQueryResult<VisitLog, APIErrorResponse> => {
  const client = useApiClient()

  const queryKey = getUseVisitLogKey(id)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/plannings/visit_logs/${id}/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: isEnabled }
  )
}

import { CircularProgress, Stack } from '@mui/material'
import { useProject, usePatchProject, useMutationWrapper } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useStepperContext } from './StepperContext'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '@/components/layouts/CardLayout'
import { StepComponentProps } from '@/features/projectExtend/StepperContainer'
import { FooterActions } from '@/features/projectExtend/FooterActions/FooterActions'
import { ProjectBasicInfoForm, ProjectBasicInfo } from '@/components/forms'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD, ROUTES_PATH } from '@/constants'

export const ProjectStep = ({ projectId }: StepComponentProps): JSX.Element => {
  const { t } = useTranslation()
  const { data: project, isLoading } = useProject({ id: projectId })

  const { mutateAsync: extendProject, isLoading: mutateInProcess } =
    useMutationWrapper(usePatchProject.bind(null, projectId))

  const { stepForward } = useStepperContext()

  const navigate = useNavigate()

  const handleSubmit = async ({
    startDate,
    endDate,
    isSmp,
    isOnlyPlanning,
    isFieldReportRequired,
    sharepointUrl,
    address,
  }: ProjectBasicInfo) => {
    const projectExtendedData = {
      start_date: dayjs(startDate).format(DATE_FORMAT_YMD),
      end_date: dayjs(endDate).format(DATE_FORMAT_YMD),
      is_smp: isSmp,
      is_only_planning: isOnlyPlanning,
      is_field_report_required: isFieldReportRequired,
      sharepoint_url: sharepointUrl,
      address,
    }

    await extendProject(projectExtendedData, {
      onSuccess: () => {
        stepForward()
      },
    })
  }

  if (isLoading || !project)
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <CircularProgress size="5rem" color="secondary" />
      </Stack>
    )

  return (
    <CardLayout title={t('projects:titles.projectInfo')} cardSx={{ pb: 0 }}>
      <ProjectBasicInfoForm
        project={project}
        onSubmit={handleSubmit}
        controls={
          <FooterActions
            isNextStepDisabled={false}
            mutateInProcess={mutateInProcess}
            onCancel={() => navigate(ROUTES_PATH.PROJECTS_OVERVIEW)}
          />
        }
      />
    </CardLayout>
  )
}

import { useState } from 'react'

type SomeFunction = (...args: string[]) => void
type Timer = ReturnType<typeof setTimeout>

export const useDebounce = <Func extends SomeFunction>(
  func: Func,
  delay: number
): Func => {
  const [timer, setTimer] = useState<Timer>()

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args)
    }, delay)

    timer && clearTimeout(timer)
    setTimer(newTimer)
  }) as Func

  return debouncedFunction
}

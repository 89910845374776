import { usePostProject, useMutationWrapper } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '@/components/layouts/CardLayout'
import { FooterActions } from '@/features/projectExtend/FooterActions/FooterActions'
import { ProjectBasicInfo, ProjectBasicInfoForm } from '@/components/forms'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD, ROUTES_PATH } from '@/constants'
import { getPath } from '@/utils'
import { ProjectStatus } from '@/types'

export const ProjectCreateStep = (): JSX.Element => {
  const { t } = useTranslation()

  const { mutateAsync: createProject, isLoading: mutateInProcess } =
    useMutationWrapper(usePostProject.bind(null))

  const navigate = useNavigate()

  const handleSubmit = async ({
    clientId,
    projectNumber,
    name,
    description,
    startDate,
    endDate,
    sharepointUrl,
    address,
    isSmp,
    isOnlyPlanning,
    isFieldReportRequired,
  }: ProjectBasicInfo) => {
    const projectData = {
      client_id: +clientId,
      project_number: projectNumber,
      name,
      description,
      start_date: dayjs(startDate).format(DATE_FORMAT_YMD),
      end_date: dayjs(endDate).format(DATE_FORMAT_YMD),
      status: 'new' as ProjectStatus,
      is_smp: isSmp,
      is_only_planning: isOnlyPlanning,
      is_field_report_required: isFieldReportRequired,
      sharepoint_url: sharepointUrl,
      address,
      is_in_gis: false,
    }

    await createProject(projectData, {
      onSuccess: (data) => {
        const extendProjectStep = getPath(ROUTES_PATH.PROJECT_EXTEND, {
          id: data.id,
        })

        navigate(`/${extendProjectStep}?step=2`)
      },
    })
  }

  return (
    <CardLayout title={t('projects:titles.newProject')} cardSx={{ pb: 0 }}>
      <ProjectBasicInfoForm
        onSubmit={handleSubmit}
        controls={
          <FooterActions
            isNextStepDisabled={false}
            mutateInProcess={mutateInProcess}
            onCancel={() => navigate(ROUTES_PATH.PROJECTS_OVERVIEW)}
          />
        }
      />
    </CardLayout>
  )
}

import { QUERY_KEYS } from '@/constants'
import { ProjectSpecie } from '@/types'
import { useQueryClient } from '@tanstack/react-query'

export const useAddNewProjectSpecie = (
  projectId: number,
  ...setters: ((value: boolean) => void)[]
) => {
  const queryClient = useQueryClient()

  return (newSpecies: ProjectSpecie[]) => {
    setters.forEach((setter) => setter(true))

    const queryKey = QUERY_KEYS.RESEARCH_SPECIES.map((key) =>
      key === ':id' ? projectId.toString() : key
    )

    const projectSpecies = queryClient.getQueryData(queryKey) as ProjectSpecie[]
    const newSpeciesToAdd = newSpecies.map((value) => ({
      // ...DEFAULT_PROJECT_SPECIE,
      specie_id: value.specie_id,
      specie: value.specie,
    }))

    queryClient.setQueryData(queryKey, [...projectSpecies, ...newSpeciesToAdd])
  }
}

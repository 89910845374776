import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@mui/material'
import { GridAccordion, CardAttribute } from '@/components'
import dayjs from 'dayjs'
import { SunCalendar } from '@/types'
import {
  DATE_FORMAT_DMY,
  DATE_FORMAT_YMD,
  DATE_HOUR_MINUTE_FORMAT,
  DATE_HOUR_MINUTE_SECOND_FORMAT,
} from '@/constants'

type SunCalendarAccordionProps = {
  title: string
  sunCalendar: SunCalendar[]
}

export const SunCalendarAccordion = ({
  title,
  sunCalendar,
}: SunCalendarAccordionProps): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <GridAccordion title={title}>
      <Grid item={true} container={true} xs={12}>
        <Grid item={true} xs={3}>
          <CardAttribute
            title={t('sunCalendar.date')}
            value={sunCalendar.map(({ date }, i) => (
              <Typography key={i}>
                {dayjs(date, DATE_FORMAT_YMD).format(DATE_FORMAT_DMY)}
              </Typography>
            ))}
          />
        </Grid>
        <Grid item={true} xs={2}>
          <CardAttribute
            title={t('sunCalendar.sunrise')}
            value={sunCalendar.map(({ sunrise }, i) => (
              <Typography key={i}>
                {dayjs
                  .utc(sunrise, DATE_HOUR_MINUTE_SECOND_FORMAT)
                  .local()
                  .format(DATE_HOUR_MINUTE_FORMAT)}
              </Typography>
            ))}
          />
        </Grid>
        <Grid item={true} xs={7}>
          <CardAttribute
            title={t('sunCalendar.sunset')}
            value={sunCalendar.map(({ sunset }, i) => (
              <Typography key={i}>
                {dayjs
                  .utc(sunset, DATE_HOUR_MINUTE_SECOND_FORMAT)
                  .local()
                  .format(DATE_HOUR_MINUTE_FORMAT)}
              </Typography>
            ))}
          />
        </Grid>
      </Grid>
    </GridAccordion>
  )
}

import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { loadModules } from 'esri-loader'
import { ResearchAreaGraphic, ResearchAreaGraphicType } from '@/types'
import { useModal } from '@/hooks'
import { LoadingButton, ModalLayout } from '@/components'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  AMSTERDAM_COORDINATES,
  DEFAULT_ZOOM,
  SPATIAL_REFERENCE,
} from './ResearchAreaGraphicMap.model'
import { defineGeometryType } from '@/utils/researchAreaGraphics'

let EsriRequest: typeof __esri.request
let EsriFeatureLayer: typeof __esri.FeatureLayer
let EsriField: typeof __esri.Field
let EsriGraphic: typeof __esri.Graphic
const ARCGIS_GENERATE_URL =
  'https://www.arcgis.com/sharing/rest/content/features/generate'

export type ResearchAreasImportMapProps = {
  projectId: number
  onSave: (graphics: ResearchAreaGraphic[]) => void
}
export const ResearchAreasImportMap = ({
  onSave,
}: ResearchAreasImportMapProps) => {
  const MapElement = useRef(null)
  const [featureLayers, setFeatureLayers] = useState<__esri.FeatureLayer[]>()
  const [map, setMap] = useState<__esri.Map>()
  const [view, setView] = useState<__esri.MapView>()
  const [file, setFile] = useState<File>()
  const [showMap, setShowMap] = useState(false)
  const [loadError, setLoadError] = useState()
  const [loading, setLoading] = useState(false)

  const { close } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    loadModules(
      [
        'esri/views/MapView',
        'esri/Map',
        'esri/layers/FeatureLayer',
        'esri/widgets/Editor',
        'esri/Graphic',
        'esri/widgets/Search',
        'esri/request',
        'esri/layers/support/Field',
      ],
      { css: true }
    )
      .then(
        ([
          MapView,
          Map,
          FeatureLayer,
          Editor,
          Graphic,
          Search,
          request,
          Field,
        ]) => {
          EsriRequest = request
          EsriFeatureLayer = FeatureLayer
          EsriField = Field
          EsriGraphic = Graphic

          const map = new Map({
            basemap: 'topo-vector',
          })

          const view = new MapView({
            zoom: DEFAULT_ZOOM,
            map,
            center: AMSTERDAM_COORDINATES,
            container: MapElement.current,
            popup: {
              defaultPopupTemplateEnabled: true,
            },
          })

          view.when(() => {
            const editor = new Editor({
              view,
            })

            const searchWidget = new Search({
              view,
            })

            view.ui.add(searchWidget, {
              position: 'top-left',
              index: 0,
            })

            view.ui.add(editor, 'top-right')
          })

          setMap(map)
          setView(view)
        }
      )
      .catch((err) => {
        console.error(err)
      })
  }, [])

  function addShapefileToMap(responseData: __esri.RequestResponse['data']) {
    if (!EsriFeatureLayer || !EsriGraphic || !EsriField || !map || !view) {
      return
    }

    let sourceGraphics: __esri.Graphic[] = []
    const layers = responseData.featureCollection.layers.map(
      (layer: { featureSet: __esri.FeatureSet }) => {
        const graphics = layer.featureSet.features
          .filter((f) => !!f.geometry)
          .map((feature) => EsriGraphic.fromJSON(feature))

        sourceGraphics = sourceGraphics.concat(graphics)

        return new EsriFeatureLayer({
          objectIdField: 'ObjectID',
          source: graphics,
          renderer: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'simple',
            symbol: {
              color: [0, 0, 0, 0],
              outline: {
                color: [255, 3, 7, 255],
                width: 0.75,
                type: 'simple-line',
                style: 'solid',
              },
              type: 'simple-fill',
              style: 'solid',
            },
          },
        })
      }
    )

    setFeatureLayers(layers)
    map.addMany(layers)
    view.goTo(sourceGraphics).catch((error) => {
      if (error.name !== 'AbortError') {
        console.error(error)
      }
    })
  }

  const _onSave = () => {
    if (!featureLayers?.length) return

    featureLayers[0]
      .queryFeatures()
      .then((results) => {
        const mapped = results.features.map((gr) => {
          const grJson = gr.toJSON()

          return {
            id: undefined,
            geometry: grJson.geometry,
            type:
              defineGeometryType(grJson.geometry) ??
              ResearchAreaGraphicType.AREA,
          }
        })

        onSave(mapped)
        close()
      })
      .catch(console.error)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadError(undefined)

    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const handleLoadFile = () => {
    if (!file || !EsriRequest) return
    setLoading(true)

    const formData = new FormData()

    formData.append('file', file)

    EsriRequest(ARCGIS_GENERATE_URL, {
      query: {
        filetype: 'shapefile',
        publishParameters: JSON.stringify({
          targetSR: {
            wkid: SPATIAL_REFERENCE,
          },
        }),
        f: 'json',
      },
      body: formData,
      responseType: 'json',
    })
      .then((response) => {
        addShapefileToMap(response.data)
        setShowMap(true)
      })
      .catch((e) => {
        setLoadError(e.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <ModalLayout
        title={t('projects:areas.importFromShapeFileModalTitle')}
        cardContentSx={{ maxHeight: '70vh', overflowY: 'auto' }}
        footerActions={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box>
              <Button onClick={() => close()}>
                {t('common:buttons.closeWindow')}
              </Button>
            </Box>
            <Box>
              {!showMap && (
                <LoadingButton
                  disabled={!file}
                  isLoading={loading}
                  onClick={handleLoadFile}
                >
                  {t('projects:areas.loadShapeFileBtn')}
                </LoadingButton>
              )}
              {showMap && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => _onSave()}
                >
                  {t('projects:areas.doImportBtn')}
                </Button>
              )}
            </Box>
          </Stack>
        }
      >
        {!showMap && (
          <Box style={{ paddingLeft: '4px', backgroundColor: '#fff' }}>
            <Typography>{t('projects:areas.selectShapeFileText')}</Typography>
            <form encType="multipart/form-data" method="post">
              <input type="file" name="file" onChange={handleFileChange} />
            </form>
            <Typography color="error.main">{loadError}</Typography>
          </Box>
        )}

        <Box
          width={{ md: '85vw', sm: 'auto' }}
          display={showMap ? 'block' : 'none'}
        >
          <div style={{ height: '70vh' }} ref={MapElement}>
            {' '}
          </div>
        </Box>
      </ModalLayout>
    </>
  )
}

import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse, ResearcherAvailability } from '@/types'
import { useApiClient } from '@/hooks'

type UsePutResearcherAvailabilityParams = {
  researcherId: number
}

export const usePutResearcherAvailability = ({
  researcherId,
}: UsePutResearcherAvailabilityParams): UseMutationResult<
  unknown,
  APIErrorResponse,
  ResearcherAvailability[]
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client.put(`plannings/researchers/${researcherId}/availability/`, params)
  )
}

import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseVisitLogsKey, getUseVisitLogKey } from '@/hooks'
import {
  VisitLog,
  PutVisitLogManagerProcessing,
  APIErrorResponse,
} from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'

export type UsePutVisitLogStatusProps = {
  id: number
}

export const usePutVisitLogStatus = ({
  id,
}: UsePutVisitLogStatusProps): UseMutationResult<
  VisitLog,
  APIErrorResponse,
  PutVisitLogManagerProcessing
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const visitLogsListKey = getUseVisitLogsKey()
  const visitLogItemKey = getUseVisitLogKey(id)

  return useMutation(
    (params) =>
      client
        .put(`plannings/visit_logs/${id}/manager_processing/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(visitLogsListKey)
        queryClient.invalidateQueries(visitLogItemKey)
      },
    }
  )
}

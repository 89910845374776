import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './locale-manager'
import LanguageDetector from 'i18next-browser-languagedetector'

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl'],
    interpolation: {
      escapeValue: false,
    },
  })

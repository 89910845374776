import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  SendPasswordResetConfirm,
  SendPasswordResetConfirmError,
  SendPasswordResetConfirmResponse,
} from '@/types/api/auth'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse } from '@/types'

export const usePostResetPasswordConfirmation = (): UseMutationResult<
  SendPasswordResetConfirmResponse,
  APIErrorResponse<SendPasswordResetConfirmError>,
  SendPasswordResetConfirm
> => {
  const client = useApiClient()

  return useMutation((params: SendPasswordResetConfirm) =>
    client
      .post<SendPasswordResetConfirmResponse>(
        '/auth/users/reset_password_confirm/',
        params
      )
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}

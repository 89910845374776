import { Shadows } from '@mui/material/styles/shadows'

function createShadow() {
  return `box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);`
}

export const shadows: Shadows = [
  'none',
  '0px 0px 35px rgba(0, 0, 0, 0.03)',
  'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
  '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
]

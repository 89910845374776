import axios, { AxiosInstance } from 'axios'
import qs from 'qs'
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '@/constants'

function getApiClient(): AxiosInstance {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT ?? '',
  })

  client.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'comma' })

  return client
}

export function setAuthHeaders(token?: string) {
  const accessToken =
    token ?? localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)

  if (accessToken) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }
}

export const apiClient = getApiClient()
setAuthHeaders()

import { useApiClient, getResearchAreasKey } from '@/hooks'
import { APIErrorResponse, ResearchArea } from '@/types'
import { unwrapErrorResponse } from '@/utils'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export const useDeleteProjectResearchArea = (
  projectId: number
): UseMutationResult<unknown, APIErrorResponse, { id: number }> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .delete(`/projects/${projectId}/research_areas/${params.id}/`)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (_, params) => {
        const queryKey = getResearchAreasKey(projectId)

        const queryData = queryClient.getQueryData(queryKey) as ResearchArea[]

        if (queryData) {
          queryClient.setQueryData(
            queryKey,
            queryData.filter((item) => item.id !== params.id)
          )
        }
      },
    }
  )
}

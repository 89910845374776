import { DataValidationEnvironmentalData } from '@/types'
import {
  faCloud,
  faTemperatureHigh,
  faTemperatureLow,
  faUmbrella,
  faWind,
  faStopwatch20,
} from '@fortawesome/free-solid-svg-icons'
import { Stack } from '@mui/material'
import { EnvironmentDataItem } from './EnvironmentDataItem'

export const EnvironmentData = ({
  start_temperature,
  end_temperature,
  cloud_cover,
  wind_force,
  precipitation,
  precipitation_duration,
}: DataValidationEnvironmentalData): JSX.Element => (
  <Stack direction="row" spacing={8}>
    <EnvironmentDataItem
      iconName={faTemperatureLow}
      field={start_temperature}
      fieldLocalization="temperature-start"
    />
    <EnvironmentDataItem
      iconName={faTemperatureHigh}
      field={end_temperature}
      fieldLocalization="temperature-end"
    />
    <EnvironmentDataItem
      iconName={faWind}
      field={wind_force}
      fieldLocalization="windSpeed"
    />
    <EnvironmentDataItem
      iconName={faCloud}
      field={cloud_cover}
      fieldLocalization="cloudCover"
    />
    <EnvironmentDataItem
      iconName={faUmbrella}
      field={precipitation}
      fieldLocalization="rain"
    />
    <EnvironmentDataItem
      iconName={faStopwatch20}
      field={precipitation_duration}
      fieldLocalization="rain-duration"
    />
  </Stack>
)

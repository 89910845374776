import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ControlledTextField } from '@/components/inputs'
import { FormComponentProps } from '@/types'
import { PasswordInputAdornment } from '@/components/ui'
import * as yup from 'yup'
import {
  passwordConfirmationValidation,
  passwordCreationValidation,
} from '@/utils/validationFields'

const resetPasswordConfirmationSchema = yup.object({
  password: passwordCreationValidation,
  verificationPassword: passwordConfirmationValidation,
})

export type ResetPasswordConfirmationFormData = {
  password: string
  verificationPassword: string
}

export const ResetPasswordConfirmationForm = ({
  onSubmit,
  isLoading,
  apiErrorData,
}: FormComponentProps<ResetPasswordConfirmationFormData>) => {
  const { t } = useTranslation('forms')
  const { control, handleSubmit } = useForm<ResetPasswordConfirmationFormData>({
    defaultValues: {
      password: '',
      verificationPassword: '',
    },
    mode: 'onChange',
    resolver: yupResolver(resetPasswordConfirmationSchema),
  })
  const [showPassword, setShowPassword] = useState(false)

  return (
    <form onSubmit={handleSubmit(async (data) => onSubmit(data))}>
      <Stack mt={6} spacing={6}>
        <ControlledTextField
          name="password"
          control={control}
          placeholder={t('placeholders.password')}
          showPassword={showPassword}
          apiFieldName="password"
          apiErrorData={apiErrorData}
          errorMessagePrefix=""
          endAdornment={
            <PasswordInputAdornment
              show={showPassword}
              setShow={setShowPassword}
            />
          }
        />
        <ControlledTextField
          name="verificationPassword"
          control={control}
          placeholder={t('placeholders.password-confirmation')}
          showPassword={showPassword}
          apiFieldName="verificationPassword"
          apiErrorData={apiErrorData}
          errorMessagePrefix=""
          endAdornment={
            <PasswordInputAdornment
              show={showPassword}
              setShow={setShowPassword}
            />
          }
        />
        <LoadingButton
          fullWidth={true}
          loading={isLoading}
          disabled={isLoading}
          data-testid="submit-reset-password-confrim-button"
          type="submit"
        >
          <span>{t('common:buttons.resetPassword')}</span>
        </LoadingButton>
      </Stack>
    </form>
  )
}

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Stack,
  Box,
  TextField,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { X } from 'react-feather'
import dayjs from 'dayjs'
import { useStepperContext } from '@/features/projectExtend/StepperContext'
import { StepComponentProps } from '@/features/projectExtend/StepperContainer'
import { SpeciesResearchTypes } from '@/features/projectDetail/SpeciesResearchTypes'
import { CardAttribute, CardLayout, Loader, ModalLayout } from '@/components'
import {
  ProtocolRoundForm,
  ProtocolRoundResearchersFormData,
} from '@/components/forms'
import { yupResolver } from '@hookform/resolvers/yup'
import { protocolRoundResearchersSchema } from '@/components/forms/ProtocolRoundForm/validationSchema'
import {
  useMutationWrapper,
  usePatchProjectProtocol,
  useProjectProtocols,
  useModal,
} from '@/hooks'
import { DATE_FORMAT_YMD } from '@/constants'
import { ProjectProtocol } from '@/types'
import { theme } from '@/theme'

export const ProtocolsStep = ({
  projectId,
}: StepComponentProps): JSX.Element => {
  const { t } = useTranslation()
  const { stepBack, stepForward } = useStepperContext()
  const { open, close } = useModal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { data: protocols, isLoading } = useProjectProtocols({ projectId })
  const { mutateAsync: updateProtocol } = useMutationWrapper(() =>
    usePatchProjectProtocol(projectId)
  )

  const defaultValues: ProtocolRoundResearchersFormData = {}
  const methods = useForm<ProtocolRoundResearchersFormData>({
    defaultValues: {},
    resolver: yupResolver(protocolRoundResearchersSchema),
  })

  useEffect(() => {
    if (protocols) {
      protocols.forEach((protocol) => {
        defaultValues[`${protocol.id}`] = {
          id: protocol.id,
          is_customized: protocol.is_customized,
          rounds: protocol.rounds.map((round) => ({
            id: round.id,
            number_of_visits: round.number_of_visits,
            required_number_of_researchers:
              round.required_number_of_researchers ?? undefined,
            from_date: dayjs(round.from_date) ?? null,
            to_date: dayjs(round.to_date) ?? null,
            visit_duration: round.visit_duration ?? undefined,
          })),
        }
      })
      methods.reset(defaultValues)
    }
  }, [protocols])

  const setConfirmationOpen = (
    title: string,
    message: string,
    callbackFunc: () => void
  ) => {
    open(
      <ModalLayout
        title={title}
        cardContentSx={{
          maxHeight: '70vh',
          overflowY: 'auto',
          minWidth: { xs: 'auto', md: '640px' },
        }}
        footerActions={
          <>
            <Button onClick={() => close()} variant="text">
              {t('projects:protocolsStep.backButton')}
            </Button>
            <Button
              onClick={() => {
                close()
                callbackFunc()
              }}
            >
              {t('projects:protocolsStep.continueButton')}
            </Button>
          </>
        }
      >
        {message}
      </ModalLayout>
    )
  }

  const handleProtocolCustomization = (protocolId: number) => {
    if (!methods.getValues(`${protocolId}.is_customized`)) {
      setConfirmationOpen(
        t('projects:protocolsStep.confirmation'),
        t('projects:protocolsStep.editRules'),
        () => {
          methods.setValue(`${protocolId}.is_customized`, true, {
            shouldDirty: true,
          })
        }
      )
    }
  }

  const deleteRound = (protocolId: number, roundId: number) => {
    setConfirmationOpen(
      t('projects:protocolsStep.confirmation'),
      t('projects:protocolsStep.deleteRound'),
      () => {
        methods.setValue(
          `${protocolId}.rounds`,
          methods
            .getValues(`${protocolId}.rounds`)
            .filter((item) => item.id !== roundId),
          { shouldDirty: true }
        )
        methods.setValue(`${protocolId}.is_customized`, true, {
          shouldDirty: true,
        })
      }
    )
  }

  const onSubmit = methods.handleSubmit((formData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const updatedProtocols: Promise<ProjectProtocol>[] = []

    Object.keys(formData).forEach((protocolId) => {
      updatedProtocols.push(
        updateProtocol({
          id: +protocolId,
          is_customized: formData[protocolId].is_customized,
          rounds_to_update: formData[protocolId].rounds.map((r) => ({
            id: r.id,
            required_number_of_researchers: +r.required_number_of_researchers,
            visit_duration: +r.visit_duration,
            number_of_visits: +r.number_of_visits,
            from_date: dayjs(r.from_date).format(DATE_FORMAT_YMD),
            to_date: dayjs(r.to_date).format(DATE_FORMAT_YMD),
          })),
        })
      )
    })

    Promise.all(updatedProtocols)
      .catch((error) => {
        enqueueSnackbar(`${t('errors:api.execute')}: ${error}`, {
          variant: 'error',
        })
      })
      .finally(() => {
        closeSnackbar()
        stepForward()
      })
  })

  const handleSubmit = async () => {
    await onSubmit()
  }

  return (
    <CardLayout
      title={t('projects:protocolsStep.title')}
      footerAction={
        <Stack direction="row" spacing={6}>
          <Button variant="text" onClick={() => stepBack()}>
            {t('projects:buttons.cancel')}
          </Button>
          <LoadingButton variant="contained" onClick={handleSubmit}>
            <span>{t('projects:buttons.confirmInformation')}</span>
          </LoadingButton>
        </Stack>
      }
    >
      <FormProvider {...methods}>
        <Stack spacing={5}>
          {!isLoading && protocols && (
            <>
              {protocols.map((protocol) => (
                <Accordion key={protocol.id} defaultExpanded={true}>
                  <AccordionSummary>
                    {protocol.original_protocols[0].specie.animal.name}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Stack direction="row" spacing={6}>
                      <Box
                        borderRadius={2.5}
                        p={3}
                        bgcolor="grey.50"
                        width={600}
                      >
                        <SpeciesResearchTypes
                          protocol={protocol}
                          speciesColumnWidth="200px"
                          researchTypesColumnWidth="400px"
                        />
                      </Box>
                      <CardAttribute
                        title={t('projects:protocolsStep.protocol')}
                        value={
                          <Stack direction="row" alignItems="center">
                            <Box
                              bgcolor={
                                methods.watch(`${protocol.id}.is_customized`)
                                  ? 'warning.main'
                                  : 'success.main'
                              }
                              borderRadius="50%"
                              width="9px"
                              height="9px"
                              mr={1}
                            />
                            {methods.watch(`${protocol.id}.is_customized`)
                              ? t('projects:protocolsStep.customProtocol')
                              : t('projects:protocolsStep.standardProtocol')}
                          </Stack>
                        }
                      />
                    </Stack>
                    <Stack spacing={3} mt={4}>
                      {methods
                        .watch(`${protocol.id}.rounds`)
                        ?.map((round, index) => {
                          const currentRound = protocol.rounds.find(
                            (item) => item.id === round.id
                          )

                          if (currentRound) {
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                key={round.id}
                              >
                                <ProtocolRoundForm
                                  protocol={protocol}
                                  round={currentRound}
                                  index={index}
                                  isReadOnly={
                                    !methods.watch(
                                      `${protocol.id}.is_customized`
                                    )
                                  }
                                  onChange={handleProtocolCustomization}
                                />
                                <IconButton
                                  onClick={() =>
                                    deleteRound(protocol.id, round.id)
                                  }
                                >
                                  <X
                                    size="16px"
                                    color={theme.palette.green['500']}
                                  />
                                </IconButton>
                              </Stack>
                            )
                          }
                        })}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Stack direction="row" justifyContent="flex-end">
                <Box width={100}>
                  <TextField
                    value={protocols
                      .map((protocol) =>
                        methods
                          .watch(`${protocol.id}.rounds`)
                          ?.map(
                            (round) =>
                              ((round.number_of_visits ?? 0) *
                                (round.required_number_of_researchers ?? 0) *
                                (round.visit_duration ?? 0)) /
                              60
                          )
                          .reduce((a, b) => a + b, 0)
                      )
                      .reduce((a, b) => a + b, 0)
                      .toFixed(1)}
                    label={t('projects:protocolsStep.totalHours')}
                    disabled={true}
                  />
                </Box>
              </Stack>
            </>
          )}

          {isLoading && <Loader height={300} />}
        </Stack>
      </FormProvider>
    </CardLayout>
  )
}

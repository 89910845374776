export const addUnique = <T extends string | number>(
  array: T[],
  value: T
): T[] => [...new Set([...array, value])]

export const mergeUnique = <T extends string | number>(
  array: T[],
  array2: T[]
): T[] => [...new Set([...array, ...array2])]

// To validate an object with dynamic keys by yupResolver
// type Protocol = {
//   [key: string]: {
//     id: number
//     name: string
//   }
// }

// export const mapRules = (map: any, rule: any) =>
//   Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})

export const mapRules = <T, U>(
  map: Record<string, T>,
  rule: U
): Record<string, U> =>
  Object.keys(map).reduce(
    (newMap, key) => ({ ...newMap, [key]: rule }),
    {} as Record<string, U>
  )

export const sortArrayOfObjectsByString = <T>(list: T[], key: keyof T) =>
  list.sort((a, b) => {
    const nameA = (a[key] as string).toUpperCase()
    const nameB = (b[key] as string).toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    return 0
  })

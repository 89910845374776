import { createContext, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactChildren } from '@/types'

export type StepperContextType = {
  activeStep: number
  stepBack: () => void
  stepForward: () => void
}

export const StepperContext = createContext<StepperContextType | null>(null)

export const StepperProvider = ({ children }: ReactChildren) => {
  const [activeStep, setActiveStep] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const stepParam = Number(searchParams.get('step'))

    if (stepParam && stepParam !== activeStep) {
      setActiveStep(stepParam)
    } else {
      setSearchParams({ step: activeStep.toString() })
    }
  }, [searchParams])

  const stepBack = () => {
    setSearchParams({ step: (activeStep - 1).toString() })
    setActiveStep((step) => step - 1)
  }

  const stepForward = () => {
    setSearchParams({ step: (activeStep + 1).toString() })
    setActiveStep((step) => step + 1)
  }

  return (
    <StepperContext.Provider value={{ activeStep, stepBack, stepForward }}>
      {children}
    </StepperContext.Provider>
  )
}

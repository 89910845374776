import { Helmet } from 'react-helmet-async'
import { LoginForm, LoginFormData } from '@/components/forms'
import { useAuth } from '@/hooks/useAuth'
import { useAuthJwtCreate } from '@/hooks'
import { useMutationWrapper } from '@/hooks/useMutationWrapper'

const SignIn = () => {
  const { signIn } = useAuth()

  const { mutateAsync: login, isLoading } = useMutationWrapper(useAuthJwtCreate)

  const handleSubmit = async ({
    email,
    password,
    isRemember,
  }: LoginFormData) => {
    await login(
      { email, password },
      {
        onSuccess: ({ access, refresh }) => {
          signIn({ access, refresh, isRemember })
        },
      }
    )
  }

  return (
    <>
      <Helmet title="Sign in" />

      <LoginForm onSubmit={handleSubmit} isLoading={isLoading} />
    </>
  )
}

export default SignIn

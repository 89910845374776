import { SelectOption } from '@/components'

export const OTHER_BAT_SPECIE_OPTION_VALUE = 'Onbekend'
const BAT_COMMON_DWARF_BAT = 'Gewone dwergvleermuis'
const BAT_ROUGH_EARED = 'Ruige dwergvleermuis'

export const OPTIONAL_RECORDING_NUMBER_SPECIES = [
  BAT_COMMON_DWARF_BAT,
  BAT_ROUGH_EARED,
]

export const BATS_SPECIE_OPTIONS: SelectOption[] = [
  {
    name: 'Baardvleermuis',
    value: 'Baardvleermuis',
  },
  {
    name: "Bechstein's vleermuis",
    value: "Bechstein's vleermuis",
  },
  {
    name: 'Bosvleermuis',
    value: 'Bosvleermuis',
  },
  {
    name: "Brandt's vleermuis",
    value: "Brandt's vleermuis",
  },
  {
    name: 'Franjestaart',
    value: 'Franjestaart',
  },
  {
    name: BAT_COMMON_DWARF_BAT,
    value: BAT_COMMON_DWARF_BAT,
  },
  {
    name: 'Gewone grootoorvleermuis',
    value: 'Gewone grootoorvleermuis',
  },
  {
    name: 'Grijze grootoorvleermuis',
    value: 'Grijze grootoorvleermuis',
  },
  {
    name: 'Ingekorven vleermuis',
    value: 'Ingekorven vleermuis',
  },
  {
    name: 'Kleine dwergvleermuis',
    value: 'Kleine dwergvleermuis',
  },
  {
    name: 'Laatvlieger',
    value: 'Laatvlieger',
  },
  {
    name: 'Meervleermuis',
    value: 'Meervleermuis',
  },
  {
    name: 'Rosse vleermuis',
    value: 'Rosse vleermuis',
  },
  {
    name: BAT_ROUGH_EARED,
    value: BAT_ROUGH_EARED,
  },
  {
    name: 'Tweekleurige vleermuis',
    value: 'Tweekleurige vleermuis',
  },
  {
    name: 'Vale vleermuis',
    value: 'Vale vleermuis',
  },
  {
    name: 'Watervleermuis',
    value: 'Watervleermuis',
  },
  {
    name: OTHER_BAT_SPECIE_OPTION_VALUE,
    value: OTHER_BAT_SPECIE_OPTION_VALUE,
  },
]

export const OTHER_SPECIE_OPTION_VALUE = 'Anders, uitleg andere soort'
export const BIRDS_BREEDING_SPECIE_OPTIONS: SelectOption[] = [
  {
    name: 'Boerenzwaluw',
    value: 'Boerenzwaluw',
  },
  {
    name: 'Boomklever',
    value: 'Boomklever',
  },
  {
    name: 'Boomkruiper',
    value: 'Boomkruiper',
  },
  {
    name: 'Bosuil',
    value: 'Bosuil',
  },
  {
    name: 'Ekster',
    value: 'Ekster',
  },
  {
    name: 'Fitis',
    value: 'Fitis',
  },
  {
    name: 'Gaai',
    value: 'Gaai',
  },
  {
    name: 'Gierzwaluw',
    value: 'Gierzwaluw',
  },
  {
    name: 'Groenling',
    value: 'Groenling',
  },
  {
    name: 'Houtduif',
    value: 'Houtduif',
  },
  {
    name: 'Huismus',
    value: 'Huismus',
  },
  {
    name: 'Huiszwaluw',
    value: 'Huiszwaluw',
  },
  {
    name: 'Kauw',
    value: 'Kauw',
  },
  {
    name: 'Kerkuil',
    value: 'Kerkuil',
  },
  {
    name: 'Koolmees',
    value: 'Koolmees',
  },
  {
    name: 'Merel',
    value: 'Merel',
  },
  {
    name: 'Ooievaar',
    value: 'Ooievaar',
  },
  {
    name: 'Pimpelmees',
    value: 'Pimpelmees',
  },
  {
    name: 'Roek',
    value: 'Roek',
  },
  {
    name: 'Roodborst',
    value: 'Roodborst',
  },
  {
    name: 'Spreeuw',
    value: 'Spreeuw',
  },
  {
    name: 'Stadsduif',
    value: 'Stadsduif',
  },
  {
    name: 'Steenuil',
    value: 'Steenuil',
  },
  {
    name: 'Tjiftjaf',
    value: 'Tjiftjaf',
  },
  {
    name: 'Tuinfluiter',
    value: 'Tuinfluiter',
  },
  {
    name: 'Zwarte roodstraat',
    value: 'Zwarte roodstraat',
  },
  {
    name: OTHER_SPECIE_OPTION_VALUE,
    value: OTHER_SPECIE_OPTION_VALUE,
  },
]

export const MAMMALS_SPECIE_OPTIONS: SelectOption[] = [
  {
    name: 'Boommarter',
    value: 'Boommarter',
  },
  {
    name: 'Bunzing',
    value: 'Bunzing',
  },
  {
    name: 'Das',
    value: 'Das',
  },
  {
    name: 'Eekhoorn',
    value: 'Eekhoorn',
  },
  {
    name: 'Egel',
    value: 'Egel',
  },
  {
    name: 'Haas',
    value: 'Haas',
  },
  {
    name: 'Hermelijn',
    value: 'Hermelijn',
  },
  {
    name: 'Steenmarter',
    value: 'Steenmarter',
  },
  {
    name: 'Vos',
    value: 'Vos',
  },
  {
    name: 'Wezel',
    value: 'Wezel',
  },
  {
    name: 'Wolf',
    value: 'Wolf',
  },
  {
    name: OTHER_SPECIE_OPTION_VALUE,
    value: OTHER_SPECIE_OPTION_VALUE,
  },
]

export const AMPHIBIANS_SPECIE_OPTIONS: SelectOption[] = [
  {
    name: 'Alpenwatersalamander',
    value: 'Alpenwatersalamander',
  },
  {
    name: 'Amerikaanse stierkikker',
    value: 'Amerikaanse stierkikker',
  },
  {
    name: 'Beekprik',
    value: 'Beekprik',
  },
  {
    name: 'Bittervoorn',
    value: 'Bittervoorn',
  },
  {
    name: 'Boomkikker',
    value: 'Boomkikker',
  },
  {
    name: 'Bruine kikker',
    value: 'Bruine kikker',
  },
  {
    name: 'Driedoornige stekelbaars',
    value: 'Driedoornige stekelbaars',
  },
  {
    name: 'Gewone pad',
    value: 'Gewone pad',
  },
  {
    name: 'Grote modderkruiper',
    value: 'Grote modderkruiper',
  },
  {
    name: 'Heikikker',
    value: 'Heikikker',
  },
  {
    name: 'Kamsalamander',
    value: 'Kamsalamander',
  },
  {
    name: 'Kleine modderkruiper',
    value: 'Kleine modderkruiper',
  },
  {
    name: 'Kleine watersalamander',
    value: 'Kleine watersalamander',
  },
  {
    name: 'Knoflookpad',
    value: 'Knoflookpad',
  },
  {
    name: 'Rivierprik',
    value: 'Rivierprik',
  },
  {
    name: 'Rugstreeppad',
    value: 'Rugstreeppad',
  },
  {
    name: 'Vinpootsalamander',
    value: 'Vinpootsalamander',
  },
  {
    name: 'Vroedmeesterpad',
    value: 'Vroedmeesterpad',
  },
  {
    name: 'Vuursalamander',
    value: 'Vuursalamander',
  },
  {
    name: OTHER_SPECIE_OPTION_VALUE,
    value: OTHER_SPECIE_OPTION_VALUE,
  },
]

export const WIND_DIRECTION_OPTIONS: SelectOption[] = [
  {
    name: 'N',
    value: 'N',
  },
  {
    name: 'NO',
    value: 'NO',
  },
  {
    name: 'O',
    value: 'O',
  },
  {
    name: 'ZO',
    value: 'ZO',
  },
  {
    name: 'Z',
    value: 'Z',
  },
  {
    name: 'ZW',
    value: 'ZW',
  },
  {
    name: 'W',
    value: 'W',
  },
  {
    name: 'NW',
    value: 'NW',
  },
]

export const BATS_BIRDS_COUNTING_METHOD_OPTIONS: SelectOption[] = [
  {
    name: 'Exact',
    value: 'Exact',
  },
  {
    name: 'Schatting',
    value: 'Schatting',
  },
]

export const AMPHIBIANS_EDNA = 'eDNA'
export const AMPHIBIANS_CREATE = 'Scheppen'

export const AMPHIBIANS_SKIP_BEHAVIOUR = [AMPHIBIANS_EDNA, AMPHIBIANS_CREATE]

export const AMPHIBIANS_COUNTING_METHOD_OPTIONS: SelectOption[] = [
  {
    name: 'Exact',
    value: 'Exact',
  },
  {
    name: 'Schatting',
    value: 'Schatting',
  },
  {
    name: 'Luisteren',
    value: 'Luisteren',
  },
  {
    name: AMPHIBIANS_CREATE,
    value: AMPHIBIANS_CREATE,
  },
  {
    name: AMPHIBIANS_EDNA,
    value: AMPHIBIANS_EDNA,
  },
]
export const INSECTS_COUNTING_METHOD_OPTIONS: SelectOption[] = [
  {
    name: 'Exact',
    value: 'Exact',
  },
  {
    name: 'Luisteren',
    value: 'Luisteren',
  },
  {
    name: 'Schatting',
    value: 'Schatting',
  },
]

const BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_TREE =
  'Invliegen uit verblijfplaats boom'
const BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_BUILDING =
  'Invliegen uit verblijfplaats gebouw'
const BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_TREE =
  'Uitvliegen uit verblijfplaats boom'
const BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_BUILDING =
  'Uitvliegen uit verblijfplaats gebouw'
const BATS_GEDRAG_TOUCHING_TREE = 'Aantikken boom'
const BATS_GEDRAG_TOUCHING_BUILDING = 'Aantikken gebouw'
const BATS_GEDRAG_SWARMS = 'Zwermen'

export const BATS_BEHAVIOUR_REQUIRE_PLACE_FOUND = [
  BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_TREE,
  BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_BUILDING,
  BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_TREE,
  BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_BUILDING,
  BATS_GEDRAG_TOUCHING_TREE,
  BATS_GEDRAG_TOUCHING_BUILDING,
  BATS_GEDRAG_SWARMS,
]

export const OTHER_GEDRAG_OPTION_VALUE = 'Anders, uitleg ander gedrag'
export const BATS_GEDRAG_OPTIONS: SelectOption[] = [
  {
    name: BATS_GEDRAG_TOUCHING_TREE,
    value: BATS_GEDRAG_TOUCHING_TREE,
  },
  {
    name: BATS_GEDRAG_TOUCHING_BUILDING,
    value: BATS_GEDRAG_TOUCHING_BUILDING,
  },
  {
    name: 'Baltsen',
    value: 'Baltsen',
  },
  {
    name: 'Baltsen vanuit vaste plaats',
    value: 'Baltsen vanuit vaste plaats',
  },
  {
    name: 'Foerageren',
    value: 'Foerageren',
  },
  {
    name: BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_TREE,
    value: BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_TREE,
  },
  {
    name: BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_BUILDING,
    value: BATS_GEDRAG_FLYING_IN_FROM_RESIDENCE_BUILDING,
  },
  {
    name: 'Overvliegen',
    value: 'Overvliegen',
  },
  {
    name: 'Sociale roep',
    value: 'Sociale roep',
  },
  {
    name: BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_TREE,
    value: BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_TREE,
  },
  {
    name: BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_BUILDING,
    value: BATS_GEDRAG_FLYING_OUT_FROM_RESIDENCE_BUILDING,
  },
  {
    name: BATS_GEDRAG_SWARMS,
    value: BATS_GEDRAG_SWARMS,
  },
  {
    name: OTHER_GEDRAG_OPTION_VALUE,
    value: OTHER_GEDRAG_OPTION_VALUE,
  },
]
export const DIRECTION_BIRD_GEDRAG_OPTION_VALUE = 'Overvliegend, zie richting'
export const BIRDS_BEHAVIOUR_ON_NEST = 'Op nest'
export const BIRDS_BEHAVIOUR_NEST_WITH_YOUNG = 'Nest met jongen'
export const BIRDS_BEHAVIOUR_NEST = 'Nest'

export const BIRDS_BEHAVIOUR_REQUIRE_PHOTOS = [
  BIRDS_BEHAVIOUR_ON_NEST,
  BIRDS_BEHAVIOUR_NEST_WITH_YOUNG,
  BIRDS_BEHAVIOUR_NEST,
]

export const BIRDS_GEDRAG_OPTIONS: SelectOption[] = [
  {
    name: 'Aantikken',
    value: 'Aantikken',
  },
  {
    name: 'Aanwezig',
    value: 'Aanwezig',
  },
  {
    name: 'Baltsend',
    value: 'Baltsend',
  },
  {
    name: 'Baltsend paar in broedbiotoop',
    value: 'Baltsend paar in broedbiotoop',
  },
  {
    name: 'Jagend',
    value: 'Jagend',
  },
  {
    name: 'Foerageren',
    value: 'Foerageren',
  },
  {
    name: BIRDS_BEHAVIOUR_NEST,
    value: BIRDS_BEHAVIOUR_NEST,
  },
  {
    name: BIRDS_BEHAVIOUR_NEST_WITH_YOUNG,
    value: BIRDS_BEHAVIOUR_NEST_WITH_YOUNG,
  },
  {
    name: 'Nestindicerend gedrag',
    value: 'Nestindicerend gedrag',
  },
  {
    name: BIRDS_BEHAVIOUR_ON_NEST,
    value: BIRDS_BEHAVIOUR_ON_NEST,
  },
  {
    name: DIRECTION_BIRD_GEDRAG_OPTION_VALUE,
    value: DIRECTION_BIRD_GEDRAG_OPTION_VALUE,
  },
  {
    name: 'Paar in broedbiotoop',
    value: 'Paar in broedbiotoop',
  },
  {
    name: 'Roepend',
    value: 'Roepend',
  },
  {
    name: 'Territorium',
    value: 'Territorium',
  },
  {
    name: 'Vernietigd nest',
    value: 'Vernietigd nest',
  },
  {
    name: 'Zingend',
    value: 'Zingend',
  },
  {
    name: OTHER_GEDRAG_OPTION_VALUE,
    value: OTHER_GEDRAG_OPTION_VALUE,
  },
]

export const MAMMALS_GEDRAG_OPTIONS: SelectOption[] = [
  {
    name: 'Aanwezig',
    value: 'Aanwezig',
  },
  {
    name: 'Dood',
    value: 'Dood',
  },
  {
    name: 'Foeragerend',
    value: 'Foeragerend',
  },
  {
    name: 'Nabij (nest, burcht)',
    value: 'Nabij (nest, burcht)',
  },
  {
    name: 'Nest/burcht',
    value: 'Nest/burcht',
  },
  {
    name: 'Nest/burcht met jongen',
    value: 'Nest/burcht met jongen',
  },
  {
    name: 'Sociaal gedrag',
    value: 'Sociaal gedrag',
  },
  {
    name: 'Sporen',
    value: 'Sporen',
  },
  {
    name: 'Territorium gedrag',
    value: 'Territorium gedrag',
  },
  {
    name: 'Zonnend',
    value: 'Zonnend',
  },
  {
    name: OTHER_GEDRAG_OPTION_VALUE,
    value: OTHER_GEDRAG_OPTION_VALUE,
  },
]

export const AMPHIBIANS_GEDRAG_OPTIONS: SelectOption[] = [
  {
    name: 'Aanwezig',
    value: 'Aanwezig',
  },
  {
    name: 'Foeragerend',
    value: 'Foeragerend',
  },
  {
    name: 'Geparasiteerd',
    value: 'Geparasiteerd',
  },
  {
    name: 'Overwinterend',
    value: 'Overwinterend',
  },
  {
    name: 'Parend',
    value: 'Parend',
  },
  {
    name: 'Roepend',
    value: 'Roepend',
  },
  {
    name: 'Zonnend',
    value: 'Zonnend',
  },
  {
    name: OTHER_GEDRAG_OPTION_VALUE,
    value: OTHER_GEDRAG_OPTION_VALUE,
  },
]
export const INSECTS_GEDRAG_OPTIONS: SelectOption[] = [
  {
    name: 'Foeragerend',
    value: 'Foeragerend',
  },
  {
    name: 'Geparasiteerd',
    value: 'Geparasiteerd',
  },
  {
    name: 'Op waardplant',
    value: 'Op waardplant',
  },
  {
    name: 'Overwinterend',
    value: 'Overwinterend',
  },
  {
    name: 'Parend',
    value: 'Parend',
  },
  {
    name: 'Chirpend',
    value: 'Chirpend',
  },
  {
    name: 'Uitsluipend',
    value: 'Uitsluipend',
  },
  {
    name: 'Zonnend',
    value: 'Zonnend',
  },
  {
    name: OTHER_GEDRAG_OPTION_VALUE,
    value: OTHER_GEDRAG_OPTION_VALUE,
  },
]

export const PLACE_TYPE_MATERNITY_HOME = 'Kraamverblijfplaats'

export const PLACE_TYPE_OPTIONS: SelectOption[] = [
  {
    name: 'Baltsverblijfplaats',
    value: 'Baltsverblijfplaats',
  },
  {
    name: PLACE_TYPE_MATERNITY_HOME,
    value: PLACE_TYPE_MATERNITY_HOME,
  },
  {
    name: 'Massawinter zwerm',
    value: 'Massawinter zwerm',
  },
  {
    name: 'Zomerverblijfplaats',
    value: 'Zomerverblijfplaats',
  },
  {
    name: 'Winterverblijf',
    value: 'Winterverblijf',
  },
]

export const PLACE_FOUND_OPTION_VALUE = 'Gevonden'
export const PLACE_NOT_FOUND_OPTION_VALUE = 'Geen verblijfplaats'
export const PLACE_FOUND_OPTIONS: SelectOption[] = [
  {
    name: PLACE_FOUND_OPTION_VALUE,
    value: PLACE_FOUND_OPTION_VALUE,
  },
  {
    name: PLACE_NOT_FOUND_OPTION_VALUE,
    value: PLACE_NOT_FOUND_OPTION_VALUE,
  },
]

export const REPTILES_COUNTING_METHOD_OPTIONS: SelectOption[] = [
  {
    name: 'Exact',
    value: 'Exact',
  },
  {
    name: 'Schatting',
    value: 'Schatting',
  },
]

import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import { Control, Controller, Path } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'

export type ControlledCheckboxFieldProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  label?: string
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>
  checkboxProps?: CheckboxProps
}

export const ControlledCheckboxField = <T extends FieldValues>({
  name,
  control,
  label,
  formControlLabelProps,
  checkboxProps,
}: ControlledCheckboxFieldProps<T>): JSX.Element => (
  <FormControlLabel
    control={
      <Controller
        name={name}
        control={control}
        render={({ field: props }) => (
          <Checkbox {...checkboxProps} {...props} checked={!!props.value} />
        )}
      />
    }
    label={label}
    {...formControlLabelProps}
  />
)

import {
  ProjectProtocol,
  ResearchType,
  ResearchVisitEventWithProjectInfo,
  Specie,
} from '@/types'
import { capitalizeFirstLetter } from '@/utils'

type SpeciesWithTypes = {
  [key: number]: {
    specie: Specie
    researchTypes: ResearchType[]
  }
}

const groupBySpecies = (
  protocols: ProjectProtocol[],
  event_original_protocols: number[] | null,
  visit_protocol_id?: number
) => {
  const speciesWithTypes = {} as SpeciesWithTypes

  protocols.forEach((protocol) => {
    const visit_protocol_original_protocols = protocol.rounds.find(
      (r) => r.id === visit_protocol_id
    )?.original_protocols

    protocol.original_protocols.forEach((originalProtocol) => {
      const { id, specie, research_type } = originalProtocol

      if (
        event_original_protocols &&
        event_original_protocols.length &&
        !event_original_protocols.includes(id) &&
        !event_original_protocols.includes(protocol.id)
      )
        return null

      if (
        visit_protocol_original_protocols &&
        visit_protocol_original_protocols.length &&
        !visit_protocol_original_protocols.includes(id) &&
        !visit_protocol_original_protocols.includes(protocol.id)
      )
        return null

      if (!speciesWithTypes[specie.id]) {
        speciesWithTypes[specie.id] = { specie, researchTypes: [] }
      }

      if (!speciesWithTypes[specie.id].researchTypes.includes(research_type)) {
        speciesWithTypes[specie.id].researchTypes.push(research_type)
      }
    })
  })

  return speciesWithTypes
}

export const getSpeciesWithResearchTypes = (
  protocols: ProjectProtocol[],
  event_original_protocols: number[] | null,
  visit_protocol_id?: number
) => {
  const species = groupBySpecies(
    protocols,
    event_original_protocols,
    visit_protocol_id
  )

  return Object.values(species).map(({ specie, researchTypes }) => {
    const researchTypesNames = researchTypes.map((researchType) =>
      capitalizeFirstLetter(researchType.name).trimEnd().trimStart()
    )

    return `${capitalizeFirstLetter(specie.name)} (${researchTypesNames.join(
      ', '
    )})`
  })
}

export const getSpeciesWithResearchTypesString = (
  event: ResearchVisitEventWithProjectInfo
) =>
  getSpeciesWithResearchTypes(
    event?.research_area.protocols.filter((p) => p.id === event.protocol_id) ??
      [],
    event?.original_protocols ?? null,
    event?.visit_protocol_id
  ).join(', ')

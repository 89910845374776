import {
  useDeleteProjectResearchArea,
  useMutationWrapper,
  getResearchAreasKey,
} from '@/hooks'
import { ResearchArea } from '@/types'
import { useQueryClient } from '@tanstack/react-query'
import { Dispatch, SetStateAction } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export type DeleteResearchAreaHandlerProps = {
  projectId: number
  setIsAddNewResearchAreaDisabled: Dispatch<SetStateAction<boolean>>
  setIsNextStepDisabled: Dispatch<SetStateAction<boolean>>
  setExpandedIds: Dispatch<SetStateAction<(number | undefined)[]>>
  onError?: () => void
}

export const useDeleteResearchArea = ({
  projectId,
  setIsAddNewResearchAreaDisabled,
  setIsNextStepDisabled,
  setExpandedIds,
  onError,
}: DeleteResearchAreaHandlerProps) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { mutateAsync: deleteResearchArea } = useMutationWrapper(() =>
    useDeleteProjectResearchArea(projectId)
  )

  return (id: number | undefined) => {
    if (!id) {
      const queryKey = getResearchAreasKey(projectId)

      const researchAreas = queryClient.getQueryData(queryKey) as ResearchArea[]

      researchAreas.pop()

      queryClient.setQueryData(queryKey, researchAreas)

      return setIsAddNewResearchAreaDisabled(false)
    }

    setIsNextStepDisabled(true)

    setExpandedIds((prev) => {
      const index = prev.findIndex((item) => item === id)

      const newExpandedIds = [...prev]

      newExpandedIds[index] = undefined

      return newExpandedIds
    })

    enqueueSnackbar(
      t('forms:projectArea.notification.areaIsDeleting', { id }),
      {
        persist: true,
        variant: 'warning',
      }
    )

    deleteResearchArea(
      { id },
      {
        successMessageKey: 'forms:projectArea.notification.areaDeleted',
        onError,
      }
    )
  }
}

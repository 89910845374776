import { SnackbarProvider as DefaultSnackbarProvider } from 'notistack'
import { ReactChildren } from '@/types'

export const SnackbarProvider = ({ children }: ReactChildren): JSX.Element => (
  <DefaultSnackbarProvider
    maxSnack={3}
    autoHideDuration={3000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableWindowBlurListener={true}
  >
    {children}
  </DefaultSnackbarProvider>
)

import 'react-app-polyfill/stable'

import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'

import './setupYup'
import './setupDayjs'

import reportWebVitals from './utils/reportWebVitals'
import App from './App'
import { ErrorFallback, SuspenseRouter } from '@/components'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

if (process.env.REACT_APP_ENABLE_MSW) {
  const { worker } = require('./mocks/browser')

  worker.start({
    onUnhandledRequest: 'bypass',
  })
}

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <SuspenseRouter>
      <App />
    </SuspenseRouter>
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { Stack } from '@mui/material'
import {
  faCloud,
  faTemperatureHigh,
  faTemperatureLow,
  faUmbrella,
  faWind,
  faStopwatch20,
} from '@fortawesome/free-solid-svg-icons'
import { EnvironmentDataField } from './EnvironmentDataField'
import { EnvironmentDataSelectField } from './EnvironmentDataSelectField'
import { SelectOption } from '@/components'

export const EnvironmentDataFieldsSet = (): JSX.Element => {
  const precipitationOptions: SelectOption[] = [
    {
      name: 'Droog',
      value: 'Droog',
    },
    {
      name: 'Motregen',
      value: 'Motregen',
    },
    {
      name: 'Miezer',
      value: 'Miezer',
    },
    {
      name: 'Regen',
      value: 'Regen',
    },
    {
      name: 'Onweer',
      value: 'Onweer',
    },
  ]

  return (
    <Stack direction="row" spacing={6}>
      <EnvironmentDataField
        iconName={faTemperatureLow}
        field="start_temperature"
        fieldLocalization="startTemperature"
      />
      <EnvironmentDataField
        iconName={faTemperatureHigh}
        field="end_temperature"
        fieldLocalization="endTemperature"
      />
      <EnvironmentDataField
        iconName={faWind}
        field="wind_force"
        fieldLocalization="windForce"
      />
      <EnvironmentDataField
        iconName={faCloud}
        field="cloud_cover"
        fieldLocalization="cloudCover"
      />
      <EnvironmentDataSelectField
        iconName={faUmbrella}
        field="precipitation"
        options={precipitationOptions}
      />
      <EnvironmentDataField
        iconName={faStopwatch20}
        field="precipitation_duration"
        fieldLocalization="precipitationDuration"
      />
    </Stack>
  )
}

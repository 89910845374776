import { useEffect, useState } from 'react'
import { Button, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { AutocompleteOption } from '@/components/inputs'
import { Dayjs } from 'dayjs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EventVisitLog, FormComponentProps, ProjectProtocol } from '@/types'
import { LoadingButton } from '@mui/lab'
import translation from 'i18next'
import { PlusCircle } from 'react-feather'
import { ResearchAreaSpecieEvent } from './ResearchAreaSpecieEvent'
import { SpeciesResearchTypes } from '@/features/projectDetail/SpeciesResearchTypes'

export const eventsSchema = yup.object({
  events: yup.array().of(
    yup.object({
      id: yup.number().required(),
      date: yup.date().typeError('invalid_date').required(),
      endDate: yup.date().typeError('invalid_date').required(),
      employees: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            title: yup.string(),
          })
        )
        .min(1, translation.t('errors:validation.array_min', { min: 1 })),
    })
  ),
})

export type ResearchAreaSpecieEventsFormData = {
  events: {
    id: number
    date: Dayjs | null | string
    endDate: Dayjs | null | string
    employees: AutocompleteOption[]
    isManuallyCreated?: boolean
    researchVisitLog: EventVisitLog | null
  }[]
}

export type ResearchAreaSpecieEventsFormProps = {
  projectId: number
  protocol: ProjectProtocol
  researchAreaId: number
  allowAdding?: boolean
} & FormComponentProps<ResearchAreaSpecieEventsFormData>

export const ResearchAreaSpecieEventsForm = ({
  onSubmit,
  defaultValues = {
    events: [
      {
        id: 0,
        date: null,
        endDate: null,
        employees: [],
        researchVisitLog: null,
      },
    ],
  },
  allowAdding = false,
  projectId,
  researchAreaId,
  protocol,
}: ResearchAreaSpecieEventsFormProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const [onResetTrigger, setOnResetTrigger] = useState(0)

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<ResearchAreaSpecieEventsFormData>({
    mode: 'onChange',
    resolver: yupResolver(eventsSchema),
    defaultValues,
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const [isDirtyAfterRemove, setIsDirtyAfterRemove] = useState(false)

  const eventsFormData = watch('events')

  const handleCancel = () => {
    reset(defaultValues)
    setIsDirtyAfterRemove(false)
    setOnResetTrigger((value) => value + 1)
  }

  const handleAddEvent = () => {
    setValue(
      'events',
      eventsFormData.concat({
        id: 0,
        date: null,
        endDate: null,
        employees: [],
        isManuallyCreated: true,
        researchVisitLog: null,
      })
    )
  }

  const handleRemoveEvent = (index: number) => {
    setValue(
      'events',
      eventsFormData.filter((_, i) => i !== index),
      { shouldDirty: true }
    )

    setIsDirtyAfterRemove(true)
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues, {
        keepErrors: true,
        keepValues: true,
      })

      setIsDirtyAfterRemove(false)
    }
  }, [isSubmitSuccessful, defaultValues])

  return (
    <Stack
      data-testid="research-type-events-form"
      p={5}
      bgcolor="grey.50"
      borderRadius={2.5}
      spacing={5}
      flex={1}
      width="100%"
    >
      <SpeciesResearchTypes
        protocol={protocol}
        speciesColumnWidth="240px"
        researchTypesColumnWidth="100%"
      />
      <Stack
        px={5}
        mb={1}
        borderRadius="12px"
        spacing={4}
        direction="row"
        flex={1}
        justifyContent="space-between"
      >
        {/*{researchAreaSpecie.required_number_of_researchers &&*/}
        {/*  !(*/}
        {/*    researchAreaSpecie.required_number_of_researchers_for_morning &&*/}
        {/*    researchAreaSpecie.required_number_of_researchers_for_evening*/}
        {/*  ) && (*/}
        {/*    <CardAttribute*/}
        {/*      title={t('placeholders.amountOfResearchers')}*/}
        {/*      value={researchAreaSpecie.required_number_of_researchers ?? '-'}*/}
        {/*    />*/}
        {/*  )}*/}

        {/*{researchAreaSpecie.required_number_of_researchers_for_morning && (*/}
        {/*  <CardAttribute*/}
        {/*    title={t('placeholders.morningAmountOfResearchers')}*/}
        {/*    value={*/}
        {/*      researchAreaSpecie.required_number_of_researchers_for_morning ??*/}
        {/*      '-'*/}
        {/*    }*/}
        {/*  />*/}
        {/*)}*/}

        {/*{researchAreaSpecie.required_number_of_researchers_for_evening && (*/}
        {/*  <CardAttribute*/}
        {/*    title={t('placeholders.eveningAmountOfResearchers')}*/}
        {/*    value={*/}
        {/*      researchAreaSpecie.required_number_of_researchers_for_evening ??*/}
        {/*      '-'*/}
        {/*    }*/}
        {/*  />*/}
        {/*)}*/}
      </Stack>

      <form onSubmit={handleSubmit(async (data) => onSubmit(data))}>
        <Stack spacing={5}>
          {!eventsFormData.length && (
            <Typography>{t('tips.noEvents')}</Typography>
          )}

          {eventsFormData.map((eventFormData, index) => (
            <ResearchAreaSpecieEvent
              key={`${eventFormData.id}${index}`}
              researchAreaId={researchAreaId}
              index={index}
              event={eventFormData}
              control={control}
              setValue={setValue}
              handleRemoveEvent={handleRemoveEvent}
              projectId={projectId}
              getValues={getValues}
              onReset={onResetTrigger}
            />
          ))}
        </Stack>

        {(isDirty || isDirtyAfterRemove) && (
          <Stack spacing={3} direction="row" alignItems="flex-start" mt={5}>
            <Button
              variant="text"
              color="secondary"
              data-testid="research-type-events-cancel"
              onClick={handleCancel}
            >
              {t('common:buttons.cancel')}
            </Button>

            <LoadingButton
              variant="contained"
              type="submit"
              color="secondary"
              data-testid="research-type-events-submit"
              loading={isSubmitting}
            >
              <span>{t('common:buttons.saveChanges')}</span>
            </LoadingButton>
          </Stack>
        )}
      </form>

      <div>
        {allowAdding && (
          <Button
            onClick={handleAddEvent}
            component={Link}
            variant="text"
            startIcon={<PlusCircle size="16px" />}
            data-testid="add-new-event-button"
            sx={{ pl: 1 }}
          >
            {t('common:buttons.addExtraRound')}
          </Button>
        )}
      </div>
    </Stack>
  )
}

import styled from '@emotion/styled'
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  Stack,
  Typography,
} from '@mui/material'

const BorderLinearProgress = styled(LinearProgress)(({ ...props }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: props.theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: props.theme.palette.green[500],
  },
}))

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => (
  <Stack alignItems="center" spacing={1.5}>
    <Box>
      <Typography sx={{ fontSize: 13 }} color="text.secondary">{`${Math.round(
        props.value
      )}%`}</Typography>
    </Box>
    <Box sx={{ width: '100%' }}>
      <BorderLinearProgress {...props} />
    </Box>
  </Stack>
)

export type ProgressBarProps = {
  progress?: number
} & LinearProgressProps

export const ProgressBar = ({
  progress,
  ...rest
}: ProgressBarProps): JSX.Element => (
  <Box sx={{ width: '100%' }}>
    <LinearProgressWithLabel
      value={progress ?? 0}
      variant={rest.variant ?? 'determinate'}
      {...rest}
    />
  </Box>
)

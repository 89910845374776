import { AutocompleteOption, ControlledAutocomplete } from '@/components/inputs'
import { useTranslation } from 'react-i18next'
import { ControlledFieldProps, EventResearcher } from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { useAvailableResearchers } from '@/hooks/api/useAvailableResearchers'
import { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
const getExperienceLabel = (isSenior?: boolean) => (isSenior ? 'S' : 'J')

export const getResearcherOptionLabel = ({
  id,
  first_name: name,
  last_name: surname,
  is_senior: isSenior,
  distance,
  allowed_number_of_research_areas,
}: EventResearcher): AutocompleteOption => ({
  value: id.toString(),
  title: `${name} ${surname}${
    allowed_number_of_research_areas !== null &&
    allowed_number_of_research_areas !== undefined
      ? ` - ${allowed_number_of_research_areas} areas`
      : ''
  }${distance ? ` - ${distance} km` : ''}`,
  isSenior,
})

export type ResearchersControlledAutocompleteProps<T extends FieldValues> = {
  eventDate?: Dayjs | null | string
  eventEndDate?: Dayjs | null | string
  projectId?: number
  researchAreaId?: number
  eventId?: number
  latitude?: string
  longitude?: string
  onChange?: (item: AutocompleteOption[]) => void
} & Pick<ControlledFieldProps<T>, 'name' | 'control'>

export const ResearchersControlledAutocomplete = <T extends FieldValues>({
  name,
  control,
  eventDate,
  eventEndDate,
  projectId,
  researchAreaId,
  eventId,
  latitude,
  longitude,
  onChange,
}: ResearchersControlledAutocompleteProps<T>): JSX.Element => {
  const { t } = useTranslation('forms')

  const notAvailableEmployees = useRef<string[]>([])
  const [options, setOptions] = useState<AutocompleteOption[]>([])

  const { data: employees, isLoading } = useAvailableResearchers({
    date: eventDate,
    endDate: eventEndDate,
    projectId,
    researchAreaId,
    eventId,
    latitude,
    longitude,
  })

  useEffect(() => {
    if (!employees?.length) {
      return
    }

    notAvailableEmployees.current = []

    const sorted = employees.sort((a, b) => a.distance - b.distance)

    const mapped = sorted.map((researcher) => {
      const { id, is_available: isAvailable } = researcher

      if (!isAvailable) {
        notAvailableEmployees.current.push(id.toString())
      }

      return getResearcherOptionLabel(researcher)
    })

    setOptions(mapped)
  }, [employees])

  return (
    <ControlledAutocomplete
      name={name}
      control={control}
      options={options}
      multiple={true}
      filterSelectedOptions={true}
      textFieldProps={{
        placeholder: t('placeholders.pickResearchers'),
        label: t('labels.researchers'),
        fullWidth: true,
      }}
      getOptionDisabled={(option) =>
        notAvailableEmployees.current.includes(option.value)
      }
      loading={isLoading}
      renderOption={(props, option) => (
        <li {...props} key={option.value + option.title}>
          <Box
            component="span"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            fontSize={12}
            width={22}
            height={22}
            p={2}
            mr={2}
            bgcolor={option?.isSenior ? 'green.500' : 'green.100'}
            borderRadius="50%"
            fontWeight={700}
          >
            {getExperienceLabel(option?.isSenior as boolean)}
          </Box>
          {option.title}
        </li>
      )}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : `(${getExperienceLabel(option?.isSenior as boolean)}) ${
              option.title
            }`
      }
      onChangeCustom={(value) => {
        onChange && onChange(value as AutocompleteOption[])
      }}
      data-testid="researchers-autocomplete"
    />
  )
}

import { Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { navigationItems, NavigationItemType } from './navigationItems'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import { theme } from '@/theme'
import { forwardRef } from 'react'
import { UserGroup } from '@/types'

const getAvailablePages = (
  userGroup: UserGroup[],
  navigationItems: NavigationItemType[]
) =>
  navigationItems.filter(({ group }) => {
    if (!group) {
      return true
    }

    return group.some((group) => userGroup.includes(group))
  })

const RefNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink
    {...props}
    style={({ isActive }) => ({
      color: isActive ? theme.palette.text.primary : theme.palette.primary.main,
    })}
    ref={ref}
    to={props.to}
  />
))

export const NavbarMenuItems = (): JSX.Element => {
  const { t } = useTranslation('common')
  const { user } = useAuth()
  const availablePages = user?.groups
    ? getAvailablePages(user.groups, navigationItems)
    : []

  return (
    <Stack direction="row" alignItems="center" spacing={6}>
      {availablePages.map(({ title, href }) => (
        <Link key={title} component={RefNavLink} to={href}>
          <Typography>{t(title)}</Typography>
        </Link>
      ))}
    </Stack>
  )
}

export const getPlacePredictions = async (text: string) => {
  const service = new window.google.maps.places.AutocompleteService()

  return service.getPlacePredictions({ input: text, types: ['address'] })
}

export const getGeoLocation = async (address: string) => {
  const geocoder = new google.maps.Geocoder()

  const { results } = await geocoder.geocode({ address })

  return {
    lat: results[0].geometry.location.lat(),
    lng: results[0].geometry.location.lng(),
  }
}

export const getGeoName = async (coords: { lat: number; lng: number }) => {
  const geocoder = new google.maps.Geocoder()

  const { results } = await geocoder.geocode({ location: coords })

  return {
    addressVariants: results,
    street_address: results.find((av) => av.types.includes('street_address')),
  }
}

import { ProjectStatus as ProjectStatusType } from '@/types'

// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
}

// Storage keys
export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token'
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh_token'

// Routes
export const ROUTES_PATH_PARTS = {
  AUTH: 'auth',
  PLANNING: 'planning',
  DATA_VALIDATION: 'data-validation',
  MONITORING: 'monitoring',
  OBSERVATION_LOGS: 'observation-logs',
  SIGN_IN: 'sign-in',
  RESET_PASSWORD: 'reset-password',
  RESET_PASSWORD_CONFIRM: 'reset-password-confirmation',
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
}

export const ROUTES_PATH = {
  ROOT: '/',
  LOGIN: '/auth/sign-in',
  PROJECTS_OVERVIEW: '/',
  PROJECT_CREATE: 'create-project',
  PROJECT_EXTEND: 'extend-project/:id',
  PROJECT_DETAIL: '/projects/:id',
  PROJECT_DETAIL_PLANNING: `/projects/:id/${ROUTES_PATH_PARTS.PLANNING}`,
  PROJECT_DETAIL_DATA_VALIDATION: `projects/:id/${ROUTES_PATH_PARTS.DATA_VALIDATION}`,
  PLANNING: '/planning',
  MONITORING: '/monitoring',
  MONITORING_DETAIL: '/monitoring/:id',
}

export const API_MAX_LIMIT = 1000

export const DEFAULT_PROJECT_LIMIT = 10
export const DEFAULT_PROJECT_OFFSET = 0
export const DEFAULT_PROJECT_SEARCH = ''

export const DEFAULT_LIST_LIMIT = 10
export const DEFAULT_LIST_OFFSET = 0

export const QUERY_KEYS = {
  PROJECT: ['project', ':id'],
  PROJECT_PROTOCOLS: ['project', ':projectId', 'protocols'],
  PROJECT_PROTOCOL: ['project', ':projectId', 'protocols', ':id'],
  PROJECTS: ['projects'],
  PROJECTS_NAMES: ['projects', 'names'],
  RESEARCH_SPECIES: ['projects', ':id', 'species', null],
  RESEARCH_AREAS: ['projects', ':id', 'research_areas', null],
  RESEARCH_AREA_SPECIES: [
    'projects',
    ':id',
    'research_areas',
    ':researchAreaId',
    'species',
    null,
  ],
  RESEARCH_AREA_SPECIE: [
    'projects',
    ':id',
    'research_areas',
    ':researchAreaId',
    'species',
    ':specieId',
    null,
  ],
  DATA_VALIDATION: [
    'projects',
    ':id',
    'research_areas',
    ':researchAreaId',
    'data-validation',
  ],
  PROJECT_PLANNING_EVENTS: ['plannings', ':projectId', 'events'],
  PLANNING_EVENTS: ['plannings', 'events'],
  PLANNING_EVENTS_FILTERED: ['plannings', 'events', 'filtered'],

  PLANNING_EVENTS_MANAGER: ['plannings', 'events', 'manager'],
  EVENT: ['event', ':id'],

  VISIT_LOGS: [
    'plannings',
    'visit_logs',
    { limit: DEFAULT_PROJECT_LIMIT, offset: DEFAULT_LIST_OFFSET },
  ],
  VISIT_LOG: ['visit_log', ':id'],

  OBSERVATION_LOGS: [
    'plannings',
    'observation_logs',
    { limit: DEFAULT_LIST_LIMIT, offset: DEFAULT_LIST_OFFSET },
  ],
}

export const DATE_FORMAT_DMY = 'DD-MM-YYYY'
export const DATE_FORMAT_DMY_TIME = 'DD-MM-YYYY HH:mm'
export const DATE_FORMAT_YMD = 'YYYY-MM-DD'
export const DATE_FORMAT_WITH_TIME = 'dd-MM-yyyy HH:mm'
export const DATE_FORMAT = 'dd-MM-yyyy'
export const DATE_API_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ssz'
export const DATE_TEXT_SHORT_FORMAT = 'ddd, DD MMM YYYY'
export const DATE_TEXT_FULL_FORMAT = 'dddd DD MMMM YYYY'
export const DATE_HOUR_MINUTE_FORMAT = 'HH:mm'
export const DATE_HOUR_MINUTE_SECOND_FORMAT = 'HH:mm:ss'

export const EMPLOYEE_PLANNING_DAYS_COUNT = 14

export const NEW_PROJECT_STATUSES = ['new', 'plan'] as ProjectStatusType[]

export const MORNING_ROUND_TIME = '01:00 - 07:00'
export const DAY_ROUND_TIME = '07:01 - 19:00'
export const EVENING_ROUND_TIME = '19:01 - 00:59'

export const AMOUNT_OF_DAYS_WEEK = 7

export enum PageReferrers {
  PROJECT = 'Project',
  MONITORING = 'Monitoring',
  ROUND = 'Round',
  OBSERVATION = 'Observation',
  DATA_VALIDATION = 'Data validation',
}

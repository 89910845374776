import { BoxProps } from '@mui/system'
import { Box, Container } from '@mui/material'
import { ReactChildren } from '@/types'

type PageBodyLayoutProps = Partial<BoxProps> & ReactChildren

export const PageBodyLayout = ({
  children,
  ...rest
}: PageBodyLayoutProps): JSX.Element => (
  <Box
    py={{ xs: 5, md: 10 }}
    borderRadius={5}
    bgcolor="white"
    flex={1}
    {...rest}
  >
    <Container maxWidth="xl">{children}</Container>
  </Box>
)

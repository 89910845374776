import { Button, Stack, Typography } from '@mui/material'
import { StepComponentProps } from '@/features'
import { CardLayout } from '@/components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useStepperContext } from '../StepperContext'
import { useMutationWrapper } from '@/hooks'
import { usePostProjectEventsGenerate } from '@/hooks/api/usePostProjectEventsGenerate'
import { useQueryClient } from '@tanstack/react-query'
import { ResearchAreaFormContainer } from '@/components/forms'
import { getPath } from '@/utils'
import { ROUTES_PATH } from '@/constants'
import { useNavigate } from 'react-router-dom'

export const ResearchAreaStep = ({
  projectId,
}: StepComponentProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [isNextStepDisabled, setIsNextStepDisabled] = useState(true)
  const [isDataSaved, setIsDataSaved] = useState(false)
  const [generatePlanningError, setGeneratePlanningError] = useState('')

  const { stepBack } = useStepperContext()

  const { mutateAsync: generateProject, isLoading: isGenerateLoading } =
    useMutationWrapper(usePostProjectEventsGenerate.bind(null, { projectId }))

  const handleGeneratePlanning = async () => {
    await generateProject(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries(['plannings'])
        navigate(getPath(ROUTES_PATH.PROJECT_DETAIL, { id: projectId }))
      },
      onError: ({ data }) => {
        const errorText = Array.isArray(data.detail)
          ? data.detail[0]
          : data.detail

        setGeneratePlanningError(errorText)
      },
    })
  }

  return (
    <CardLayout
      title={t('titles.researchArea')}
      dataTestId="research-area-container"
      footerAction={
        isDataSaved ? (
          <Stack direction="row" spacing={6}>
            <Button variant="text" onClick={() => setIsDataSaved(false)}>
              {t('buttons.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              loading={isGenerateLoading}
              onClick={handleGeneratePlanning}
              disabled={!!generatePlanningError}
            >
              <span>{t('buttons.addPlanning')}</span>
            </LoadingButton>
          </Stack>
        ) : (
          <Stack direction="row" spacing={6}>
            <Button variant="text" onClick={() => stepBack()}>
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setGeneratePlanningError('')
                setIsDataSaved(true)
              }}
              disabled={isNextStepDisabled}
            >
              {t('buttons.confirmInformation')}
            </Button>
          </Stack>
        )
      }
    >
      <Stack spacing={5}>
        {isDataSaved ? (
          <>
            <Typography>{t('projects:extendResearchAreaSaved')}</Typography>
            <Typography mb={6}>{t('projects:addPlanning')}</Typography>
            {generatePlanningError && (
              <Typography mb={6} color="error.main">
                <b>{t('projects:addPlanningError')}</b>
                <br />
                {generatePlanningError}
              </Typography>
            )}
          </>
        ) : (
          <ResearchAreaFormContainer
            projectId={projectId}
            setIsNextStepDisabled={setIsNextStepDisabled}
          />
        )}
      </Stack>
    </CardLayout>
  )
}

import { DataValidationError } from '@/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type EnvironmentDataItemProps<T> = {
  iconName: IconDefinition
  field: DataValidationError<T>
  fieldLocalization: string
}

export const EnvironmentDataItem = ({
  iconName,
  field,
  fieldLocalization,
}: EnvironmentDataItemProps<string | number | null>) => {
  const { t } = useTranslation('dataValidation')

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={field.has_error ? { color: 'error.main' } : {}}
    >
      <FontAwesomeIcon
        icon={iconName}
        size="sm"
        style={{ color: field.has_error ? 'error.main' : '#668938' }}
      />
      <Typography>
        {field.value
          ? t(`visit-log.environmentalData.${fieldLocalization}`, {
              value: field.value,
            })
          : '-'}
      </Typography>
    </Stack>
  )
}

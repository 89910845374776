import { QUERY_KEYS } from '@/constants'
import { useApiClient } from '@/hooks'
import { APIErrorResponse, ProjectSpecieBasic } from '@/types'
import { unwrapErrorResponse } from '@/utils'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export const useDeleteProjectSpecieType = (
  projectId: number
): UseMutationResult<unknown, APIErrorResponse, { id: number }> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .delete<ProjectSpecieBasic>(
          `/projects/${projectId}/species/${params.id}/`
        )
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        const queryKey = QUERY_KEYS.RESEARCH_SPECIES.map((key) =>
          key === ':id' ? projectId.toString() : key
        )

        queryClient.removeQueries(queryKey)
      },
    }
  )
}

import { useAuth } from '@/hooks/useAuth'
import { ReactNode } from 'react'
import { UserGroups } from '@/types'
import { Navigate } from 'react-router-dom'

interface AuthorizationGuardProps {
  children: ReactNode
  allowedGroups: UserGroups[]
  redirect?: boolean
}

/**
 * Wrapper for routes that only available for authorized users (with particular permissions)
 */
export const AuthorizationGuard = ({
  children,
  allowedGroups,
  redirect = true,
}: AuthorizationGuardProps) => {
  const { user } = useAuth()

  if (!user) {
    return null
  }

  const userHasGroup = allowedGroups?.some((group) =>
    user.groups.includes(group)
  )

  if (userHasGroup) {
    return <>{children}</>
  }

  if (redirect) return <Navigate to="/404" />
  else return <></>
}

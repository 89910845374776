import { useState } from 'react'
import {
  ResetPasswordConfirmationForm,
  ResetPasswordConfirmationFormData,
} from '@/components/forms'
import { useMutationWrapper, usePostResetPasswordConfirmation } from '@/hooks'
import { Button, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const ResetPasswordConfirmation = () => {
  const { t } = useTranslation('forms')
  const navigate = useNavigate()
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const { mutateAsync: resetPassword, isLoading } = useMutationWrapper(
    usePostResetPasswordConfirmation
  )

  const onSubmit = async (data: ResetPasswordConfirmationFormData) => {
    const dataToSend = {
      uid: searchParams.get('uid') ?? '',
      token: searchParams.get('token') ?? '',
      new_password: data.password,
    }

    await resetPassword(dataToSend, {
      onSuccess: async (response) => {
        const access = response?.access

        if (access) {
          setIsPasswordChanged(true)
        }
      },
    })
  }

  return (
    <>
      <Helmet title="Reset password" />

      <Stack minWidth={{ md: 400 }}>
        <Typography component="h1" variant="h4" gutterBottom={true}>
          {t('titles.reset-password.new-password')}
        </Typography>

        {isPasswordChanged ? (
          <>
            <Typography component="h2" mb={4}>
              {t('descriptions.reset-password.success')}
            </Typography>
            <Button onClick={() => navigate('/')}>Login to portal</Button>
          </>
        ) : (
          <>
            <Typography component="h2">
              {t('descriptions.reset-password.new-password')}
            </Typography>

            <ResetPasswordConfirmationForm
              onSubmit={onSubmit}
              isLoading={isLoading}
            />
          </>
        )}
      </Stack>
    </>
  )
}

export default ResetPasswordConfirmation

import { UserGroups } from '@/types'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { useAuth, useModal } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { AvailabilityModal } from '@/features'

export const OpenAvailabilityModalButton = (): JSX.Element | null => {
  const { t } = useTranslation('planning')
  const { user } = useAuth()
  const { open } = useModal()

  const handleOpenEditModal = () => {
    open(<AvailabilityModal />)
  }

  if (!user?.groups.includes(UserGroups.EMPLOYEE)) {
    return null
  }

  return (
    <Button
      onClick={handleOpenEditModal}
      data-testid="open-availability-button"
    >
      {t('planning:buttons.availability')}
      <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={faPenToSquare} />
    </Button>
  )
}

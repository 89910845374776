import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { useClients } from '@/hooks/api'

type ClientSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
  placeholder?: string
}

export const ClientsMultiSelect = ({
  value,
  onChange,
  placeholder,
}: ClientSelectProps): JSX.Element => {
  const { data: clients } = useClients({
    limit: 1000,
    offset: 0,
  })

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={clients?.results?.map(
        ({ id, name }) =>
          ({
            label: name,
            value: id.toString(),
          } as SearchAutocompleteOption)
      )}
      placeholder={placeholder}
      sort={true}
      fieldSx={{ width: 284 }}
    />
  )
}

import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseObservationLogsKey } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  ObservationLog,
  PutObservationLogManagerProcessing,
  APIErrorResponse,
} from '@/types'

export type UsePutObservationLogStatusProps = {
  id: number
}

export const usePutObservationLogStatus = ({
  id,
}: UsePutObservationLogStatusProps): UseMutationResult<
  ObservationLog,
  APIErrorResponse,
  PutObservationLogManagerProcessing
> => {
  const client = useApiClient()

  const queryClient = useQueryClient()

  const queryKey = getUseObservationLogsKey()

  return useMutation(
    (params) =>
      client
        .put(`plannings/observation_logs/${id}/manager_processing/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )
}

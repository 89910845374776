import { useState, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { CardAttribute } from '@/components/ui'
import {
  ControlledCheckboxField,
  ControlledDateField,
  ControlledSelect,
  ControlledTextField,
} from '@/components/inputs'
import { useForm } from 'react-hook-form'
import { Client, FormComponentProps, Project, ProjectStatus } from '@/types'
import { Dayjs } from 'dayjs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useClients } from '@/hooks'
import { SelectOption } from '@/components'
import { formatDateInformation } from '@/utils'

export type ProjectBasicInfo = {
  clientId: number | string
  projectNumber: string
  name: string
  description?: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  isSmp: boolean
  isOnlyPlanning: boolean
  isFieldReportRequired: boolean
  sharepointUrl?: string
  address?: string
  hasGisStatus?: boolean
  hasDeliveryStatus?: boolean
  status?: ProjectStatus
}

export type ProjectBasicInfoFormProps = {
  project?: Project
  isReadOnlyDate?: boolean
  hasStatusFields?: boolean
  showControlsOnDirty?: boolean
  controls: JSX.Element
} & FormComponentProps<ProjectBasicInfo>

const projectCreateScheme = yup.object({
  clientId: yup.number().typeError('required').required(),
  projectNumber: yup.string().required(),
  name: yup.string().required().nullable(),
  startDate: yup.date().typeError('invalid_date').required().nullable(),
  endDate: yup.date().typeError('invalid_date').required().nullable(),
})

const projectBasicInfoScheme = yup.object({
  startDate: yup.date().typeError('invalid_date').required().nullable(),
  endDate: yup.date().typeError('invalid_date').required().nullable(),
})

export const ProjectBasicInfoForm = ({
  project,
  controls,
  onSubmit,
  apiErrorData,
  hasStatusFields = false,
  isReadOnlyDate = false,
  showControlsOnDirty = false,
}: ProjectBasicInfoFormProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const { data: clients } = useClients(
    {
      limit: 1000,
      offset: 0,
    },
    !project
  )

  const [clientOptions, setClientOptions] = useState<SelectOption[]>([])

  const resolver = project ? projectBasicInfoScheme : projectCreateScheme

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm<ProjectBasicInfo>({
    mode: 'onChange',
    defaultValues: {
      clientId: '',
      projectNumber: '',
      name: '',
      description: (project && project.start_date) ?? '',
      startDate: (project && project.start_date) ?? null,
      endDate: (project && project.end_date) ?? null,
      isSmp: (project && project.is_smp) ?? false,
      isOnlyPlanning: (project && project.is_only_planning) ?? false,
      isFieldReportRequired:
        (project && project.is_field_report_required) ?? true,
      address: (project && project.address) ?? '',
      hasGisStatus: (project && project.is_in_gis) ?? undefined,
      sharepointUrl: (project && project.sharepoint_url) ?? '',
      status: (project && project.status) ?? 'new',
    },
    resolver: yupResolver(resolver),
  })

  const getClientOptions = (clients: Client[]) =>
    clients
      .map((client) => ({
        name: client.name,
        value: client.id.toString(),
      }))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()

        if (nameA < nameB) {
          return -1
        }

        return 1
      })

  useEffect(() => {
    if (clients && clients.count) {
      const options = getClientOptions(clients.results)

      setClientOptions(options)
    }
  }, [clients])

  return (
    <Box data-testid="project-basic-info-form">
      {project && (
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={2}
          maxWidth={isReadOnlyDate ? '800px' : '600px'}
          flexWrap="wrap"
        >
          <CardAttribute
            title={t('titles.projectNumber')}
            value={project.project_number}
          />
          <CardAttribute title={t('titles.projectName')} value={project.name} />
          <CardAttribute
            title={t('titles.clientName')}
            value={project.client_name}
          />

          {isReadOnlyDate && (
            <CardAttribute
              title={t('titles.projectDate')}
              value={formatDateInformation(
                project.start_date,
                project.end_date
              )}
            />
          )}
        </Stack>
      )}
      <form
        onSubmit={handleSubmit(async (data) => {
          onSubmit(data)
        })}
      >
        <Stack spacing={4} my={6}>
          {!project && (
            <>
              <Box maxWidth="520px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.client')}
                </Typography>
                <ControlledSelect
                  placeholder={t('placeholders.client')}
                  name="clientId"
                  options={clientOptions}
                  control={control}
                  apiErrorData={apiErrorData}
                />
              </Box>
              <Stack
                maxWidth="520px"
                direction="row"
                justifyContent="space-between"
              >
                <Box width="160px">
                  <Typography fontWeight="700" mb={3}>
                    {t('placeholders.projectNumber')}
                  </Typography>
                  <ControlledTextField
                    placeholder={t('placeholders.projectNumber')}
                    name="projectNumber"
                    type="text"
                    control={control}
                    apiErrorData={apiErrorData}
                  />
                </Box>
                <Box width="340px">
                  <Typography fontWeight="700" mb={3}>
                    {t('placeholders.name')}
                  </Typography>
                  <ControlledTextField
                    placeholder={t('placeholders.name')}
                    name="name"
                    type="text"
                    control={control}
                    apiErrorData={apiErrorData}
                  />
                </Box>
              </Stack>
              <Box maxWidth="520px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.description')}
                </Typography>
                <ControlledTextField
                  placeholder={t('placeholders.description')}
                  name="description"
                  type="text"
                  control={control}
                  apiErrorData={apiErrorData}
                />
              </Box>
            </>
          )}
          {!isReadOnlyDate && (
            <Box>
              <Typography fontWeight="700" mb={3}>
                {t('projects:titles.projectDate')}
              </Typography>
              <Stack direction="row">
                <ControlledDateField
                  name="startDate"
                  placeholder={t('forms:placeholders.start-date')}
                  control={control}
                  apiFieldName="startDate"
                  apiErrorData={apiErrorData}
                />
                <Box sx={{ mx: 2, mt: 3 }}>
                  <Typography>{t('titles.to')}</Typography>
                </Box>
                <ControlledDateField
                  name="endDate"
                  placeholder={t('forms:placeholders.end-date')}
                  control={control}
                  apiFieldName="endDate"
                  apiErrorData={apiErrorData}
                  minDate={watch('startDate') ?? undefined}
                />
              </Stack>
            </Box>
          )}
          {hasStatusFields && (
            <>
              <Box data-testid="project-gisStatus">
                <Typography fontWeight="700">
                  {t('detail.gisStatus')}
                </Typography>
                <ControlledCheckboxField
                  name="hasGisStatus"
                  control={control}
                  label={t('labels.gisStatus')}
                />
              </Box>
              <Box data-testid="project-deliveryStatus">
                <Typography fontWeight="700">
                  {t('detail.deliveryStatus')}
                </Typography>
                <ControlledCheckboxField
                  name="hasDeliveryStatus"
                  control={control}
                  label={t('labels.deliveryStatus')}
                />
              </Box>
            </>
          )}
          <Box>
            <Typography fontWeight="700">{t('detail.isSmp')}</Typography>
            <ControlledCheckboxField
              name="isSmp"
              control={control}
              label={t('labels.isSmp')}
            />
          </Box>
          <Box>
            <Typography fontWeight="700">
              {t('detail.isOnlyPlanning')}
            </Typography>
            <ControlledCheckboxField
              name="isOnlyPlanning"
              control={control}
              label={t('labels.isOnlyPlanning')}
            />
          </Box>
          <Box>
            <Typography fontWeight="700">
              {t('detail.isFieldReportRequired')}
            </Typography>
            <ControlledCheckboxField
              name="isFieldReportRequired"
              control={control}
              label={t('labels.isFieldReportRequired')}
            />
          </Box>
          <Box maxWidth="520px">
            <Typography fontWeight="700" mb={3}>
              {t('placeholders.sharepointUrl')}
            </Typography>
            <ControlledTextField
              placeholder={t('placeholders.sharepointUrl')}
              name="sharepointUrl"
              type="text"
              control={control}
              apiErrorData={apiErrorData}
            />
          </Box>
          <Box data-testid="project-address" maxWidth="520px">
            <Typography fontWeight="700" mb={3}>
              {t('placeholders.address')}
            </Typography>
            <ControlledTextField
              placeholder={t('placeholders.address')}
              name="address"
              type="text"
              control={control}
              apiErrorData={apiErrorData}
            />
          </Box>
        </Stack>
        {(showControlsOnDirty && isDirty) || !showControlsOnDirty
          ? controls
          : null}
      </form>
    </Box>
  )
}

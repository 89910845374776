import { Box, Grid, Link, SvgIcon, Typography } from '@mui/material'
import { CardAttribute } from '@/components'
import { useTranslation } from 'react-i18next'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export type ProjectInfoProps = {
  name: string | null
  clientName: string | null
  sharepointUrl?: string | null
  projectNumber: string | null
}

export const ProjectInfo = ({
  name,
  clientName,
  sharepointUrl,
  projectNumber,
}: ProjectInfoProps): JSX.Element => {
  const { t } = useTranslation('planning')

  return (
    <Grid container={true} rowSpacing={5} columnSpacing={4} mb={3}>
      <Grid item={true} xs={6} sm={3}>
        <CardAttribute title={t('projects:detail.id')} value={projectNumber} />
      </Grid>

      <Grid item={true} xs={6} sm={3}>
        <CardAttribute title={t('projects:detail.projectName')} value={name} />
      </Grid>

      <Grid item={true} xs={6} sm={3}>
        <CardAttribute
          title={t('projects:detail.business')}
          value={clientName}
        />
      </Grid>

      <Grid item={true} xs={6} sm={3}>
        <Typography variant="caption" color="grey.600">
          {t('projects:detail.sharepointUrl')}
        </Typography>

        <Box>
          {sharepointUrl ? (
            <Link
              href={sharepointUrl}
              color="primary.light"
              underline="none"
              variant="body1"
              sx={{
                ':hover': {
                  color: 'primary.main',
                },
              }}
              target="_blank"
            >
              <Typography display="flex" alignItems="center">
                {t('projects:detail.sharepointLink')}
                <SvgIcon
                  component={OpenInNewIcon}
                  sx={{ ml: 1, height: '16px', width: '16px' }}
                />
              </Typography>
            </Link>
          ) : (
            '-'
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import { Planning } from '@/types'
import { useTranslation } from 'react-i18next'
import { ProjectInfo } from '../ProjectInfo'
import { ResearchAreaEvents } from '@/features/projectDetail/ResearchAreas/components/ResearchAreaEvents'

export type ResearchAreaSpecieEventsProps = {
  planning: Planning
  index: number
  canGoToRoundDetail?: boolean
}

export const ResearchAreaSpecieEvents = ({
  planning,
  index,
  canGoToRoundDetail,
}: ResearchAreaSpecieEventsProps): JSX.Element => {
  const { t } = useTranslation('planning')

  return (
    <>
      {planning.research_areas.map((research_area, i) => (
        <Accordion
          defaultExpanded={index === 0 && i === 0}
          key={research_area.id}
          data-testid="research-area-accordion"
        >
          <AccordionSummary>
            {`${t('areas.researchAreaNumber')}${research_area.id} - ${
              research_area.location_name
            }`}
          </AccordionSummary>

          <AccordionDetails>
            <ProjectInfo
              projectNumber={planning.project_number}
              name={planning.name}
              clientName={planning.client_name}
              sharepointUrl={planning.sharepoint_url}
            />
            {!!research_area.research_visits_events.length && (
              <Typography variant="h6" pb={4}>
                {t('projects:areas.events')}
              </Typography>
            )}

            <ResearchAreaEvents
              researchArea={research_area}
              events={research_area.research_visits_events}
              isLoading={false}
              canGoToDetail={canGoToRoundDetail}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

import { useSnackbar } from 'notistack'
import { getErrorMessage } from '@/utils'
import { ApiErrorData, APIErrorResponse, Override } from '@/types'
import { UseMutationResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

type MutateOptions<TData> = {
  onSuccess?: (data: TData) => void
  onError?: (error: APIErrorResponse) => void
  setApiErrorData?: Dispatch<SetStateAction<ApiErrorData | undefined>>
  successMessageKey?: string
  errorMessageKey?: string
  showErrorSnackbar?: boolean
}

type MutateAsyncWrapperFunction<TData, TVariables> = (
  data: TVariables,
  options?: MutateOptions<TData>
) => Promise<TData>

type UseMutationWrapper<TData, TVariables> = Override<
  UseMutationResult<TData, APIErrorResponse, TVariables>,
  {
    mutateAsync: MutateAsyncWrapperFunction<TData, TVariables>
  }
>

export const useMutationWrapper = <TData, TVariables = Partial<TData>>(
  mutation: () => UseMutationResult<TData, APIErrorResponse, TVariables>
): UseMutationWrapper<TData, TVariables> => {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { mutateAsync, ...rest } = mutation()

  const mutateAsyncWrapper: MutateAsyncWrapperFunction<
    TData,
    TVariables
  > = async (data, options = {}) => {
    const {
      successMessageKey,
      onSuccess,
      setApiErrorData,
      onError,
      showErrorSnackbar = true,
      errorMessageKey = 'errors:api.execute',
    } = options

    return await mutateAsync(data, {
      onSuccess: (response) => {
        onSuccess?.(response)

        closeSnackbar()

        successMessageKey &&
          enqueueSnackbar(t(successMessageKey), {
            variant: 'success',
          })
      },
      onError: (error: APIErrorResponse) => {
        onError?.(error)

        closeSnackbar()

        if (setApiErrorData && error.status === 400) {
          setApiErrorData(error.data)
        }

        if (showErrorSnackbar) {
          const message = getErrorMessage(error, t(errorMessageKey))

          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
      },
    })
  }

  return {
    mutateAsync: mutateAsyncWrapper,
    ...rest,
  }
}

import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type EmptyListDataProps = {
  textKey?: string
}

export const EmptyListData = ({
  textKey = 'common:fallback.noDataFound',
}: EmptyListDataProps): JSX.Element => {
  const { t } = useTranslation('')

  return (
    <Stack
      my={10}
      justifyContent="center"
      alignItems="center"
      sx={{ img: { width: '50px' } }}
      spacing={3}
    >
      <img src="/static/img/search-icon.png" alt="Search icon" />

      <Typography
        color="green.600"
        alignSelf="center"
        mt={5}
        variant="h5"
        fontWeight={500}
      >
        {t(textKey)}
      </Typography>
    </Stack>
  )
}
